import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_SALE_GROUP_DETAIL         = 'REQUEST_SALE_GROUP_DETAIL';
export const REQUEST_SALE_GROUP_DETAIL_SUCCESS = 'REQUEST_SALE_GROUP_DETAIL_SUCCESS';
export const REQUEST_SALE_GROUP_DETAIL_FAILURE = 'REQUEST_SALE_GROUP_DETAIL_FAILURE';

export const REQUEST_EXPORT_SALE_GROUP_MEMBERS         = 'REQUEST_EXPORT_SALE_GROUP_MEMBERS';
export const REQUEST_EXPORT_SALE_GROUP_MEMBERS_SUCCESS = 'REQUEST_EXPORT_SALE_GROUP_MEMBERS_SUCCESS';
export const REQUEST_EXPORT_SALE_GROUP_MEMBERS_FAILURE = 'REQUEST_EXPORT_SALE_GROUP_MEMBERS_FAILURE';

export const REQUEST_SALE_GROUP_MEMBERS         = 'REQUEST_SALE_GROUP_MEMBERS';
export const REQUEST_SALE_GROUP_MEMBERS_SUCCESS = 'REQUEST_SALE_GROUP_MEMBERS_SUCCESS';
export const REQUEST_SALE_GROUP_MEMBERS_FAILURE = 'REQUEST_SALE_GROUP_MEMBERS_FAILURE';

export const REQUEST_DELETE_MEMBER         = 'REQUEST_DELETE_MEMBER';
export const REQUEST_DELETE_MEMBER_SUCCESS = 'REQUEST_DELETE_MEMBER_SUCCESS';
export const REQUEST_DELETE_MEMBER_FAILURE = 'REQUEST_DELETE_MEMBER_FAILURE';

export const ACTION_CHANGE_SALE_GROUP_DETAIL_FILTER   = 'ACTION_CHANGE_SALE_GROUP_DETAIL_FILTER';


const defaultState = {
    fetchingSaleGroupDetail: false,
    failedFetchingSaleGroupDetail: false,
    saleGroupDetail: {},
    dashboard: {},
    members: [],
    filter: {
        search_key: ""
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_SALE_GROUP_DETAIL]: (state, action) => ({
        ...state,
        fetchingSaleGroupDetail: true,
        failedFetchingSaleGroupDetail: false,
    }),
    [REQUEST_SALE_GROUP_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        fetchingSaleGroupDetail: false,
        failedFetchingSaleGroupDetail: false,
        saleGroupDetail: get(action, 'payload.data.doc'),
    }),
    [REQUEST_SALE_GROUP_MEMBERS_SUCCESS]: (state, action) => ({
        ...state,
        members: get(action, 'payload.data'),
        dashboard: {
            total_revenue: get(action, 'payload.data.total_revenue'),
            count_order: get(action, 'payload.data.count_order'),
            product_quantity: get(action, 'payload.data.product_quantity'),
            total_average_single_value: get(action, 'payload.data.total_average_single_value'),
        }
    }),
    [REQUEST_SALE_GROUP_DETAIL_FAILURE]: (state, action) => ({
        ...state,
        fetchingSaleGroupDetail: false,
        failedFetchingSaleGroupDetail: true,
    }),
    [ACTION_CHANGE_SALE_GROUP_DETAIL_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_SALE_GROUP_DETAIL_FILTER,
    payload
});

export default reducer;
export const namespace = 'saleGroupDetail';

export const getSaleGroupDetail = (id, params = { page: 1 }) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
    return await callAPI({
        method: 'get',
        apiPath: '/admin/sale-groups/'+id,
        actionTypes: [REQUEST_SALE_GROUP_DETAIL, REQUEST_SALE_GROUP_DETAIL_SUCCESS, REQUEST_SALE_GROUP_DETAIL_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getMemberOfGroup = (id, params = { page: 1 }) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
    return await callAPI({
        method: 'get',
        apiPath: '/admin/sale-groups/members/'+id,
        actionTypes: [REQUEST_SALE_GROUP_MEMBERS, REQUEST_SALE_GROUP_MEMBERS_SUCCESS, REQUEST_SALE_GROUP_MEMBERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const deleteMember = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/sale-groups/member/'+id,
        actionTypes: [REQUEST_DELETE_MEMBER, REQUEST_DELETE_MEMBER_SUCCESS, REQUEST_DELETE_MEMBER_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const exportSaleGroupMembers = (sale_group_id, params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/sale-groups/'+sale_group_id+'/export-members',
        actionTypes: [REQUEST_EXPORT_SALE_GROUP_MEMBERS, REQUEST_EXPORT_SALE_GROUP_MEMBERS_SUCCESS, REQUEST_EXPORT_SALE_GROUP_MEMBERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};