import {
    all,
    put,
    fork, takeLatest, select,
} from 'redux-saga/effects';

import {
    ACTION_CHANGE_QUESTION_ANSWER_FILTER,
    getQuestionAnswers, REQUEST_DELETE_QUESTION_ANSWER_FAILURE, REQUEST_DELETE_QUESTION_ANSWER_SUCCESS,
} from './index';
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getQuestionAnswers())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_QUESTION_ANSWER_FILTER,
    ], function* (action) {
        const {questionAnswers} = yield select();
        const {
            filter
        } = questionAnswers;
        yield put(getQuestionAnswers(filter))
    });
}

function* handleDeleteNotificationSuccess() {
    yield takeLatest(REQUEST_DELETE_QUESTION_ANSWER_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa Q & A thành công'));
        yield put(getQuestionAnswers())
    });
}

function* handleDeleteVoucherFailure() {
    yield takeLatest(REQUEST_DELETE_QUESTION_ANSWER_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa Q & A thất bại'));
        yield put(getQuestionAnswers())
    });
}

export function* loadQuestionAnswerPage() {
    yield all([
        fork(loadRouteData),
        fork(handleDeleteNotificationSuccess),
        fork(handleDeleteVoucherFailure),
        fork(handleListenActions),
    ]);
}
