import {
  select,
  all,
  fork,
  takeLatest,
  put,
} from 'redux-saga/effects';

import {
  ROUTE_HOME,
  selectRoutesMap,
  goToPage,
  isForbidden
} from '../routing';
import { selectUser } from '../auth';

export function* watchRouteSagas() {
  const routesMap = yield select(selectRoutesMap);

  yield takeLatest(Object.keys(routesMap), function*(currentRoute) {
    const authUser = yield select(selectUser);

    if (isForbidden(currentRoute.type, authUser.role)) {
      yield put(goToPage(ROUTE_HOME))
    }
  });
}

export default function* routes() {
  yield all([
    fork(watchRouteSagas),
  ]);
}
