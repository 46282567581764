import {
    all,
    put,
    fork,
    select,
    takeLatest
  } from 'redux-saga/effects';
  
  import {
    getPosts,
    ACTION_CHANGE_POST_FILTER,
    REQUEST_DELETE_POST_SUCCESS,
    REQUEST_DELETE_POST_FAILURE,
  } from './index';
  import { showToastAlert } from "../app";
  // import _ from "lodash";
  
  function* loadRouteData() {
      yield put(getPosts())
    }
  
  function* handleListenActions() {
      yield takeLatest([
        ACTION_CHANGE_POST_FILTER
      ], function* (action) {
        const {posts} = yield select();
        const {
            filter
        } = posts;
      yield put(getPosts(filter))
      });
  }
  
  function* handleDeletePostSuccess() {
      yield takeLatest(REQUEST_DELETE_POST_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa trang nội dung thành công'))
        yield put(getPosts())
      });
  }
  
  function* handleDeletePostFailure() {
      yield takeLatest(REQUEST_DELETE_POST_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa bài đăng thất bại', 'error'))
        yield put(getPosts())
      });
  }
  
  export function* loadPostsPage() {
    yield all([
      fork(handleListenActions),
      fork(loadRouteData),
      fork(handleDeletePostSuccess),
      fork(handleDeletePostFailure)
    ]);
  }
    