import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_DELETE_MODERATOR         = 'REQUEST_DELETE_MODERATOR';
export const REQUEST_DELETE_MODERATOR_SUCCESS = 'REQUEST_DELETE_MODERATOR_SUCCESS';
export const REQUEST_DELETE_MODERATOR_FAILURE = 'REQUEST_DELETE_MODERATOR_FAILURE';

export const REQUEST_MODERATOR = 'REQUEST_MODERATOR';
export const MODERATOR_SUCCESS = 'MODERATOR_SUCCESS';
export const MODERATOR_FAILURE = 'MODERATOR_FAILURE';

export const ACTION_CHANGE_MODERATOR_FILTER = 'ACTION_CHANGE_MODERATOR_FILTER';

const defaultState = {
    fetchingModerators: false,
    failedFetchingModerators: false,
    moderators: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    }
};

const reducer = createReducer(defaultState, {
    [MODERATOR_SUCCESS]: (state, action) => ({
        ...state,
        moderators: get(action, 'payload.data')
    }),

    [ACTION_CHANGE_MODERATOR_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_MODERATOR_FILTER,
    payload
});

export default reducer;
export const namespace = 'moderators';

export const getModerators = (params = { page: 1 }) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/moderators',
        actionTypes: [REQUEST_MODERATOR, MODERATOR_SUCCESS, MODERATOR_FAILURE],
        variables: { ...params },
        dispatch,
        getState
    });
};

export const deleteModerator = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/moderators/' + id,
        actionTypes: [
            REQUEST_DELETE_MODERATOR,
            REQUEST_DELETE_MODERATOR_SUCCESS,
            REQUEST_DELETE_MODERATOR_FAILURE
        ],
        variables: {},
        dispatch,
        getState
    });
};