import {
    all,
    put,
    fork,
    select,
    takeLatest
  } from 'redux-saga/effects';
  
  import {
    getContents,
    ACTION_CHANGE_CONTENT_FILTER,
    REQUEST_DELETE_CONTENT_SUCCESS,
    REQUEST_DELETE_CONTENT_FAILURE,
    REQUEST_UPDATE_CONTENT_SUCCESS,
    REQUEST_UPDATE_CONTENT_FAILURE,
    REQUEST_CREATE_CONTENT_SUCCESS,
    REQUEST_CREATE_CONTENT_FAILURE
  } from './index';
  import { getContentCategories } from '../contentCategory'
  import { showToastAlert } from "../app";
  // import _ from "lodash";
  
  function* loadRouteData() {
      yield put(getContents())
      yield put(getContentCategories())
    }
  
  function* handleListenActions() {
      yield takeLatest([
        ACTION_CHANGE_CONTENT_FILTER
      ], function* (action) {
        const {contents} = yield select();
        const {
            filter
        } = contents;
      yield put(getContents(filter))
      });
  }
  
  function* handleDeleteContentSuccess() {
      yield takeLatest(REQUEST_DELETE_CONTENT_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa trang nội dung thành công'))
        yield put(getContents())
      });
  }
  
  function* handleDeleteContentFailure() {
      yield takeLatest(REQUEST_DELETE_CONTENT_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa trang nội dung thất bại', 'error'))
        yield put(getContents())
      });
  }
  
  function* handleUpdateContentSuccess() {
      yield takeLatest(REQUEST_UPDATE_CONTENT_SUCCESS, function* (action) {
        yield put(showToastAlert('cập nhật trang nội dung thành công'))
        yield put(getContents())
      });
  }
  
  function* handleUpdateContentFailure() {
      yield takeLatest(REQUEST_UPDATE_CONTENT_FAILURE, function* (action) {
        yield put(showToastAlert('cập nhật trang nội dung thất bại', 'error'))
        yield put(getContents())
      });
  }

  function* handleCreateContentSuccess() {
    yield takeLatest(REQUEST_CREATE_CONTENT_SUCCESS, function* (action) {
      yield put(showToastAlert('tạo mới trang nội dung thành công'))
      yield put(getContents())
    });
}

  function* handleCreateContentFailure() {
      yield takeLatest(REQUEST_CREATE_CONTENT_FAILURE, function* (action) {
        yield put(showToastAlert('tạo mới trang nội thất bại', 'error'))
        yield put(getContents())
      });
  }
  
  export function* loadContentPage() {
    yield all([
      fork(handleListenActions),
      fork(loadRouteData),
      fork(handleDeleteContentSuccess),
      fork(handleDeleteContentFailure),
      fork(handleUpdateContentSuccess),
      fork(handleUpdateContentFailure),
      fork(handleCreateContentSuccess),
      fork(handleCreateContentFailure)
    ]);
  }
    