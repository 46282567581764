import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_UPDATE_MODERATOR         = 'REQUEST_UPDATE_MODERATOR';
export const REQUEST_UPDATE_MODERATOR_SUCCESS = 'REQUEST_UPDATE_MODERATOR_SUCCESS';
export const REQUEST_UPDATE_MODERATOR_FAILURE = 'REQUEST_UPDATE_MODERATOR_FAILURE';

export const REQUEST_CREATE_MODERATOR         = 'REQUEST_CREATE_MODERATOR';
export const REQUEST_CREATE_MODERATOR_SUCCESS = 'REQUEST_CREATE_MODERATOR_SUCCESS';
export const REQUEST_CREATE_MODERATOR_FAILURE = 'REQUEST_CREATE_MODERATOR_FAILURE';

export const REQUEST_MODERATOR         = 'REQUEST_MODERATOR';
export const REQUEST_MODERATOR_SUCCESS = 'REQUEST_MODERATOR_SUCCESS';
export const REQUEST_MODERATOR_FAILURE = 'REQUEST_MODERATOR_FAILURE';

export const REQUEST_UPDATE_MODERATOR_SELLER         = 'REQUEST_UPDATE_MODERATOR_SELLER';
export const REQUEST_UPDATE_MODERATOR_SELLER_SUCCESS = 'REQUEST_UPDATE_MODERATOR_SELLER_SUCCESS';
export const REQUEST_UPDATE_MODERATOR_SELLER_FAILURE = 'REQUEST_UPDATE_MODERATOR_SELLER_FAILURE';

const defaultState = {
    fetchingModerator: false,
    failedFetchingModerator: false,
    moderator: {}
};

const reducer = createReducer(defaultState, {
    [REQUEST_MODERATOR]: (state, action) => ({
        ...state,
        fetchingModerator: true,
        failedFetchingModerator: false,
    }),

    [REQUEST_MODERATOR_SUCCESS]: (state, action) => ({
        ...state,
        fetchingModerator: false,
        failedFetchingModerator: false,
        moderator: get(action, 'payload.data')
    }),

    [REQUEST_MODERATOR_FAILURE]: (state, action) => ({
        ...state,
        fetchingModerator: false,
        failedFetchingModerator: true,
    }),
});

export default reducer;
export const namespace = 'moderatorDetail';

export const createModerator = (params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/moderators',
        actionTypes: [
            REQUEST_CREATE_MODERATOR,
            REQUEST_CREATE_MODERATOR_SUCCESS,
            REQUEST_CREATE_MODERATOR_FAILURE
        ],
        variables: {...params},
        dispatch,
        getState
    });
};

export const updateModerator = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/moderators/'+ data.id,
        actionTypes: [
            REQUEST_UPDATE_MODERATOR,
            REQUEST_UPDATE_MODERATOR_SUCCESS,
            REQUEST_UPDATE_MODERATOR_FAILURE
        ],
        variables: {...data},
        dispatch,
        getState
    });
}

export const getModeratorById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/moderators/'+ id,
        actionTypes: [
            REQUEST_MODERATOR,
            REQUEST_MODERATOR_SUCCESS,
            REQUEST_MODERATOR_FAILURE
        ],
        variables: {},
        dispatch,
        getState
    });
}

export const updateModeratorSeller = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/moderators/'+ data.administrator + '/seller',
        actionTypes: [
            REQUEST_UPDATE_MODERATOR_SELLER,
            REQUEST_UPDATE_MODERATOR_SELLER_SUCCESS,
            REQUEST_UPDATE_MODERATOR_SELLER_FAILURE
        ],
        variables: { seller_id: data.seller_id, action: data.action },
        dispatch,
        getState
    });
}