import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION';
export const REQUEST_NOTIFICATION_SUCCESS = 'REQUEST_NOTIFICATION_SUCCESS';
export const REQUEST_NOTIFICATION_FAILURE = 'REQUEST_NOTIFICATION_FAILURE';

export const REQUEST_DELETE_NOTIFICATION = 'REQUEST_DELETE_NOTIFICATION';
export const REQUEST_DELETE_NOTIFICATION_SUCCESS = 'REQUEST_DELETE_NOTIFICATION_SUCCESS';
export const REQUEST_DELETE_NOTIFICATION_FAILURE = 'REQUEST_DELETE_NOTIFICATION_FAILURE';

export const ACTION_CHANGE_NOTIFICATION_FILTER = 'ACTION_CHANGE_NOTIFICATION_FILTER';

const defaultState = {
    fetchingNotification: false,
    failedFetchingNotification: false,
    notifications: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_NOTIFICATION]: (state, action) => ({
        ...state,
        fetchingNotification: true,
        failedFetchingNotification: false,
    }),
    [ACTION_CHANGE_NOTIFICATION_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_NOTIFICATION_SUCCESS]: (state, action) => ({
        ...state,
        fetchingNotification: false,
        failedFetchingNotification: false,
        notifications: get(action, 'payload.data')
    }),
    [REQUEST_NOTIFICATION_FAILURE]: (state, action) => ({
        ...state,
        fetchingNotification: false,
        failedFetchingNotification: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_NOTIFICATION_FILTER,
    payload
});

export default reducer;
export const namespace = 'notifications';

export const getNotification = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/notification',
        actionTypes: [REQUEST_NOTIFICATION, REQUEST_NOTIFICATION_SUCCESS, REQUEST_NOTIFICATION_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const deleteNotification = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/notification/'+id,
        actionTypes: [
            REQUEST_DELETE_NOTIFICATION,
            REQUEST_DELETE_NOTIFICATION_SUCCESS,
            REQUEST_DELETE_NOTIFICATION_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};
