import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION';
export const REQUEST_NOTIFICATION_SUCCESS = 'REQUEST_NOTIFICATION_SUCCESS';
export const REQUEST_NOTIFICATION_FAILURE = 'REQUEST_NOTIFICATION_FAILURE';

export const REQUEST_CREATE_NOTIFICATION = 'REQUEST_CREATE_NOTIFICATION';
export const REQUEST_CREATE_NOTIFICATION_SUCCESS = 'REQUEST_CREATE_NOTIFICATION_SUCCESS';
export const REQUEST_CREATE_NOTIFICATION_FAILURE = 'REQUEST_CREATE_NOTIFICATION_FAILURE';

export const REQUEST_UPDATE_NOTIFICATION = 'REQUEST_UPDATE_NOTIFICATION';
export const REQUEST_UPDATE_NOTIFICATION_SUCCESS = 'REQUEST_UPDATE_NOTIFICATION_SUCCESS';
export const REQUEST_UPDATE_NOTIFICATION_FAILURE = 'REQUEST_UPDATE_NOTIFICATION_FAILURE';

const defaultState = {
    fetchingNotification: false,
    failedFetchingNotification: false,
    notification: {
        results: [],
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_NOTIFICATION]: (state, action) => ({
        ...state,
        fetchingNotification: true,
        failedFetchingNotification: false,
    }),

    [REQUEST_NOTIFICATION_SUCCESS]: (state, action) => ({
        ...state,
        fetchingNotification: false,
        failedFetchingNotification: false,
        notification: get(action, 'payload.data')
    }),

    [REQUEST_NOTIFICATION_FAILURE]: (state, action) => ({
        ...state,
        fetchingNotification: false,
        failedFetchingNotification: true,
    }),
});

export default reducer;
export const namespace = 'notification';

export const getNotificationById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/notification/'+id,
        actionTypes: [REQUEST_NOTIFICATION, REQUEST_NOTIFICATION_SUCCESS, REQUEST_NOTIFICATION_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const createNotification = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['title', 'content', 'path', 'seller', 'type', 'screen', 'care_history']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'post',
        apiPath: '/admin/notification',
        actionTypes: [REQUEST_CREATE_NOTIFICATION, REQUEST_CREATE_NOTIFICATION_SUCCESS, REQUEST_CREATE_NOTIFICATION_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const updateNotification = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['title', 'content', 'path', 'seller', 'type', 'screen', 'care_history']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'put',
        apiPath: '/admin/notification/'+data.id,
        actionTypes: [REQUEST_UPDATE_NOTIFICATION, REQUEST_UPDATE_NOTIFICATION_SUCCESS, REQUEST_UPDATE_NOTIFICATION_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};
