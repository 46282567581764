import {
    all,
    fork,
    put,
    takeLatest,
    race,
    take,
    select
} from 'redux-saga/effects';
import { BOOT, bootFinished } from './';
import { redirect } from 'redux-first-router';
// import {
//     setAuthToken
// } from '../../../utils/localStorage';
import {
    ROUTE_LOGIN,
    // goToPage,
    // selectCurrentRoutePayload,
    selectRoutesMap,
    selectRouteType
  } from '../routing';
import {
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURED,
    fetchUserInfor
} from '../auth';


function* watchAppBoot() {
    yield takeLatest(BOOT, function*() {
        const routesMap = yield select(selectRoutesMap);
        const routeType = yield select(selectRouteType);
        const { location } = yield select();
        const currentRoute = routesMap[routeType];
        if (currentRoute.requiresAuth) {
            // get user infor
            yield put(fetchUserInfor());
            const [successAction] = yield race([
                take(FETCH_USER_SUCCESS),
                take(FETCH_USER_FAILURED)
            ]);

            if (successAction) {
                yield put(bootFinished());
                yield put(redirect({
                    type: routeType,
                    payload: location.payload,
                }));
            } else {
                yield put(bootFinished());
                yield put(redirect({
                    type: ROUTE_LOGIN,
                    payload: location.payload,
                    query: location.type !== ROUTE_LOGIN ? { redirect: location.type } : {}
                }));
            }
        } else {
            yield put(bootFinished());
            yield put(redirect({
                type: routeType,
                payload: location.payload
            }));
        }
    });
}

function* loadBaseData() {
    yield takeLatest(FETCH_USER_SUCCESS, function*(action) {
        const { app } = yield select();
        console.log(app);
    });
}

// function* sagaSocketHandle() {
//     yield takeLatest(REQUEST_USER_CHANNELS_SUCCESS, function*() {
//         yield put(connectSocket());
//     });
// }



// function* redirectAndAwaitOAuthGrant() {
//     const routeType = yield select(selectRouteType);
//     const routePayload = yield select(selectCurrentRoutePayload);

//     if (routeType !== ROUTE_AUTH) {
//       yield put({
//         type: ROUTE_AUTH,
//         payload: {
//           query: {
//             nextType: routeType,
//             nextPayload: !isEmpty(routePayload)
//               ? JSON.stringify(routePayload)
//               : undefined
//           }
//         }
//       });
//     }

//     yield put(bootFinished());

//     // retry boot after logging in
//     yield take(OAUTH_GRANT_FLOW_SUCCESS);
//     yield put(boot());
//   }

export default function* auth() {
    yield all([
        fork(watchAppBoot),
        fork(loadBaseData),
        // fork(sagaSocketHandle),
    ]);
}
