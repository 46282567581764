import {
    all,
    put,
    fork,
    select,
    takeLatest
} from 'redux-saga/effects';

import {
    ACTION_CHANGE_SALE_GROUP_FILTER,
    getSaleGroups, REQUEST_DELETE_SALE_GROUP_FAILURE,
    REQUEST_DELETE_SALE_GROUP_SUCCESS, REQUEST_EXPORT_SALE_GROUPS_SUCCESS,
    REQUEST_UPDATE_SALE_GROUP_FAILURE,
    REQUEST_UPDATE_SALE_GROUP_SUCCESS
} from './index';
import {ACTION_CHANGE_MODERATOR_FILTER, getModerators} from "../moderator";
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getSaleGroups());
    yield put(getModerators());
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_MODERATOR_FILTER,
    ], function* (action) {
        const {moderators} = yield select();
        const {
            filter
        } = moderators;
        yield put(getModerators(filter))
    });
    yield takeLatest([
        ACTION_CHANGE_SALE_GROUP_FILTER,
    ], function* (action) {
        const {saleGroups} = yield select();
        const {
            filter
        } = saleGroups;
        yield put(getSaleGroups(filter))
    });
}

function* handleExportSaleGroupsSuccess() {
    yield takeLatest(REQUEST_EXPORT_SALE_GROUPS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thành công'))
        const link = document.createElement('a');
        link.href = action.payload.data.url;
        link.setAttribute(
            'download',
            action.payload.data.file_name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}

function* handleDeleteSaleGroupSuccess() {
    yield takeLatest(REQUEST_DELETE_SALE_GROUP_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa hội nhóm thành công'))
        yield put(getSaleGroups())
    });
}

function* handleDeleteSaleGroupFailure() {
    yield takeLatest(REQUEST_DELETE_SALE_GROUP_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa hội nhóm thất bại', 'error'))
        yield put(getSaleGroups())
    });
}

function* handleUpdateSaleGroupSuccess() {
    yield takeLatest(REQUEST_UPDATE_SALE_GROUP_SUCCESS, function* (action) {
        yield put(showToastAlert('cập nhật hội nhóm thành công'))
        yield put(getSaleGroups())
    });
}

function* handleUpdateSaleGroupFailure() {
    yield takeLatest(REQUEST_UPDATE_SALE_GROUP_FAILURE, function* (action) {
        yield put(showToastAlert('cập nhật hội nhóm thất bại', 'error'))
        yield put(getSaleGroups())
    });
}

export function* loadSaleGroupsPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleDeleteSaleGroupSuccess),
        fork(handleDeleteSaleGroupFailure),
        fork(handleUpdateSaleGroupSuccess),
        fork(handleUpdateSaleGroupFailure),
        fork(handleExportSaleGroupsSuccess),
    ]);
}
