import {
    all,
    takeLatest,
    select,
    put
} from 'redux-saga/effects';

import {showToastAlert} from "../app";
import {
    REQUEST_CREATE_MODERATOR_FAILURE,
    REQUEST_CREATE_MODERATOR_SUCCESS,
    getModeratorById, REQUEST_UPDATE_MODERATOR_SUCCESS
} from "./index";
import {redirect} from "redux-first-router";
import {ROUTE_MODERATOR} from "../routing";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id && location.payload.id !== "create") {
        yield put(getModeratorById(location.payload.id));
    }
}

function* handleCreateModeratorsSuccess() {
    yield takeLatest(REQUEST_CREATE_MODERATOR_SUCCESS, function* (action) {
        yield put(showToastAlert('tạo mới thành công'))
        yield put(redirect({
            type: ROUTE_MODERATOR,
            query: ROUTE_MODERATOR
        }));
    });
}

function* handleUpdateModeratorsSuccess() {
    yield takeLatest(REQUEST_UPDATE_MODERATOR_SUCCESS, function* (action) {
        yield put(showToastAlert('Sửa thành công'))
        yield put(redirect({
            type: ROUTE_MODERATOR,
            query: ROUTE_MODERATOR
        }));
    });
}

function* handleCreateModeratorsFailure() {
    yield takeLatest(REQUEST_CREATE_MODERATOR_FAILURE, function* (action) {
        yield put(showToastAlert('Tên đăng nhập hoặc Email đã tồn tại','error'))
    });
}

export function* loadModeratorDetailPage() {
    yield all([
        loadRouteData(),
        handleCreateModeratorsSuccess(),
        handleCreateModeratorsFailure(),
        handleUpdateModeratorsSuccess()
    ]);
}
