import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_CREATE_TRAINING_SCHEDULE = 'REQUEST_CREATE_TRAINING_SCHEDULE';
export const REQUEST_CREATE_TRAINING_SCHEDULE_SUCCESS = 'REQUEST_CREATE_TRAINING_SCHEDULE_SUCCESS';
export const REQUEST_CREATE_TRAINING_SCHEDULE_FAILURE = 'REQUEST_CREATE_TRAINING_SCHEDULE_FAILURE';

export const REQUEST_DELETE_TRAINING_SCHEDULE         = 'REQUEST_DELETE_TRAINING_SCHEDULE';
export const REQUEST_DELETE_TRAINING_SCHEDULE_SUCCESS = 'REQUEST_DELETE_TRAINING_SCHEDULE_SUCCESS';
export const REQUEST_DELETE_TRAINING_SCHEDULE_FAILURE = 'REQUEST_DELETE_TRAINING_SCHEDULE_FAILURE';

export const REQUEST_UPDATE_TRAINING_SCHEDULE         = 'REQUEST_UPDATE_TRAINING_SCHEDULE';
export const REQUEST_UPDATE_TRAINING_SCHEDULE_SUCCESS = 'REQUEST_UPDATE_TRAINING_SCHEDULE_SUCCESS';
export const REQUEST_UPDATE_TRAINING_SCHEDULE_FAILURE = 'REQUEST_UPDATE_TRAINING_SCHEDULE_FAILURE';

export const REQUEST_TRAINING_SCHEDULE = 'REQUEST_TRAINING_SCHEDULE';
export const REQUEST_TRAINING_SCHEDULE_SUCCESS = 'REQUEST_TRAINING_SCHEDULE_SUCCESS';
export const REQUEST_TRAINING_SCHEDULE_FAILURE = 'REQUEST_TRAINING_SCHEDULE_FAILURE';

const defaultState = {
    fetchingTrainingSchedule: false,
    failedFetchingTrainingSchedule: false,
    trainingSchedule: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    }
};

const reducer = createReducer(defaultState, {
    [REQUEST_TRAINING_SCHEDULE_SUCCESS]: (state, action) => ({
        ...state,
        trainingSchedule: get(action, 'payload.data')
    }),
});

export default reducer;
export const namespace = 'trainingSchedule';


export const getTrainingSchedule = (params = { page: 1 }) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/training-schedule',
        actionTypes: [REQUEST_TRAINING_SCHEDULE, REQUEST_TRAINING_SCHEDULE_SUCCESS, REQUEST_TRAINING_SCHEDULE_FAILURE],
        variables: { ...params },
        dispatch,
        getState
    });
};

//createTrainingSchedule
export const createTrainingSchedule = (params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/training-schedule',
        actionTypes: [
            REQUEST_CREATE_TRAINING_SCHEDULE,
            REQUEST_CREATE_TRAINING_SCHEDULE_SUCCESS,
            REQUEST_CREATE_TRAINING_SCHEDULE_FAILURE
        ],
        variables: {...params},
        dispatch,
        getState
    });
};

//updateTrainingSchedule
export const updateTrainingSchedule = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/training-schedule/'+ data.id,
        actionTypes: [
            REQUEST_UPDATE_TRAINING_SCHEDULE,
            REQUEST_UPDATE_TRAINING_SCHEDULE_SUCCESS,
            REQUEST_UPDATE_TRAINING_SCHEDULE_FAILURE
        ],
        variables: {...data},
        dispatch,
        getState
    });
}

//deleteTrainingSchedule
export const deleteTrainingSchedule = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/training-schedule/' + id,
        actionTypes: [
            REQUEST_DELETE_TRAINING_SCHEDULE,
            REQUEST_DELETE_TRAINING_SCHEDULE_SUCCESS,
            REQUEST_DELETE_TRAINING_SCHEDULE_FAILURE
        ],
        variables: {},
        dispatch,
        getState
    });
};