import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_PRODUCT_DETAIL = 'REQUEST_PRODUCT_DETAIL';
export const REQUEST_PRODUCT_DETAIL_SUCCESS = 'REQUEST_PRODUCT_DETAIL_SUCCESS';
export const REQUEST_PRODUCT_DETAIL_FAILURE = 'REQUEST_PRODUCT_DETAIL_FAILURE';
export const CLEAR_PRODUCT_DETAIL = 'CLEAR_PRODUCT_DETAIL';

export const REQUEST_UPDATE_PRODUCT = 'REQUEST_UPDATE_PRODUCT';
export const REQUEST_UPDATE_PRODUCT_SUCCESS = 'REQUEST_UPDATE_PRODUCT_SUCCESS';
export const REQUEST_UPDATE_PRODUCT_FAILURE = 'REQUEST_UPDATE_PRODUCT_FAILURE';

export const REQUEST_CREATE_PRODUCT = 'REQUEST_CREATE_PRODUCT';
export const REQUEST_CREATE_PRODUCT_SUCCESS = 'REQUEST_CREATE_PRODUCT_SUCCESS';
export const REQUEST_CREATE_PRODUCT_FAILURE = 'REQUEST_CREATE_PRODUCT_FAILURE';

const defaultState = {
    fetchingProductDetail: false,
    data: {},
};

const reducer = createReducer(defaultState, {
    [REQUEST_PRODUCT_DETAIL]: (state, action) => ({
        ...state,
        fetchingProductDetail: true
    }),
    [REQUEST_PRODUCT_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        data: get(action, 'payload.data'),
        fetchingProductDetail: false
    }),
    [CLEAR_PRODUCT_DETAIL]: (state, action) => ({
        ...state,
        data: {}
    }),
});

export const clearProductDetail = (payload) => ({
    type: CLEAR_PRODUCT_DETAIL,
    payload
});

export default reducer;
export const namespace = 'productDetail';

export const createProduct = (data)=> async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/products',
        actionTypes: [REQUEST_CREATE_PRODUCT, REQUEST_CREATE_PRODUCT_SUCCESS, REQUEST_CREATE_PRODUCT_FAILURE],
        variables: {
            name: data.name,
            description: data.description,
            content: data.content,
            sku: data.sku,
            sku_origin: data.sku_origin,
            market_price: data.market_price,
            supplier_price: data.supplier_price,
            supplier_price_old: data.supplier_price_old,
            suggested_price: data.suggested_price,
            weight: data.weight,
            status: data.status,
            with_sales: data.with_sales,
            stores: data.stores,
            categories: data.categories,
            images: data.images,
            image: (data.images && data.images.length) ? data.images[0] : '' ,
            product_to_store: data.product_to_store,
            quantity_sold: data.quantity_sold,
            brand: data.brand,
            used_for_ranking: data.used_for_ranking,
            is_almost_expired: data.is_almost_expired
        },
        dispatch,
        getState
    });
};

export const getProductById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/products/'+id,
        actionTypes: [REQUEST_PRODUCT_DETAIL, REQUEST_PRODUCT_DETAIL_SUCCESS, REQUEST_PRODUCT_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const updateProduct = (data)=> async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/products/'+data.id,
        actionTypes: [REQUEST_UPDATE_PRODUCT, REQUEST_UPDATE_PRODUCT_SUCCESS, REQUEST_UPDATE_PRODUCT_FAILURE],
        variables: {
            name: data.name,
            description: data.description,
            content: data.content,
            sku: data.sku,
            sku_origin: data.sku_origin,
            market_price: data.market_price,
            supplier_price: data.supplier_price,
            supplier_price_old: data.supplier_price_old,
            suggested_price: data.suggested_price,
            weight: data.weight,
            status: data.status,
            with_sales: data.with_sales,
            stores: data.stores,
            categories: data.categories,
            images: data.images,
            image: (data.images && data.images.length) ? data.images[0] : '' ,
            product_to_store: data.product_to_store,
            quantity_sold: data.quantity_sold,
            brand: data.brand,
            used_for_ranking: data.used_for_ranking,
            is_almost_expired: data.is_almost_expired
        },
        dispatch,
        getState
    });
};

