import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';

// import validateAppConfig from '~helpers/validateAppConfig';
import configureStore from './state/configureStore';
import 'antd/dist/antd.css';
import './index.css';

import App from './pages/App';

// validateAppConfig();

function bootstrap() {
  const store = configureStore();
  render(
      <Provider store={store}>
        <App />
      </Provider>,
    document.getElementById('root')
  );
}

bootstrap();
