import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_SLIDE_BANNERS = 'REQUEST_SLIDE_BANNERS';
export const REQUEST_SLIDE_BANNERS_SUCCESS = 'REQUEST_SLIDE_BANNERS_SUCCESS';
export const REQUEST_SLIDE_BANNERS_FAILURE = 'REQUEST_SLIDE_BANNERS_FAILURE';

export const REQUEST_CREATE_SLIDE_BANNER = 'REQUEST_CREATE_SLIDE_BANNER';
export const REQUEST_CREATE_SLIDE_BANNER_SUCCESS = 'REQUEST_CREATE_SLIDE_BANNER_SUCCESS';
export const REQUEST_CREATE_SLIDE_BANNER_FAILURE = 'REQUEST_CREATE_SLIDE_BANNER_FAILURE';

export const REQUEST_UPDATE_SLIDE_BANNER = 'REQUEST_UPDATE_SLIDE_BANNER';
export const REQUEST_UPDATE_SLIDE_BANNER_SUCCESS = 'REQUEST_UPDATE_SLIDE_BANNER_SUCCESS';
export const REQUEST_UPDATE_SLIDE_BANNER_FAILURE = 'REQUEST_UPDATE_SLIDE_BANNER_FAILURE';

export const REQUEST_DELETE_SLIDE_BANNER = 'REQUEST_DELETE_SLIDE_BANNER';
export const REQUEST_DELETE_SLIDE_BANNER_SUCCESS = 'REQUEST_DELETE_SLIDE_BANNER_SUCCESS';
export const REQUEST_DELETE_SLIDE_BANNER_FAILURE = 'REQUEST_DELETE_SLIDE_BANNER_FAILURE';
export const ACTION_CHANGE_SLIDE_BANNER_FILTER = 'ACTION_CHANGE_SLIDE_BANNER_FILTER';


const defaultState = {
    fetchingVouchers: false,
    failedFetchingVouchers: false,
    slideBanners: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_SLIDE_BANNERS]: (state, action) => ({
        ...state,
        fetchingVouchers: true,
        failedFetchingVouchers: false,
    }),
    [REQUEST_SLIDE_BANNERS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingVouchers: false,
        failedFetchingVouchers: false,
        slideBanners: get(action, 'payload.data')
    }),
    [REQUEST_SLIDE_BANNERS_FAILURE]: (state, action) => ({
        ...state,
        fetchingVouchers: false,
        failedFetchingVouchers: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_SLIDE_BANNER_FILTER,
    payload
});

export default reducer;
export const namespace = 'slideBanner';

export const getSlideBanners = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/slide',
        actionTypes: [REQUEST_SLIDE_BANNERS, REQUEST_SLIDE_BANNERS_SUCCESS, REQUEST_SLIDE_BANNERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const deleteSlideBanner = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/slide/'+id,
        actionTypes: [
            REQUEST_DELETE_SLIDE_BANNER,
            REQUEST_DELETE_SLIDE_BANNER_SUCCESS,
            REQUEST_DELETE_SLIDE_BANNER_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};

export const createSlideBanner = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['imageDesktop', 'imageMobile', 'title', 'link', 'type', 'index', 'expiration_date']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'post',
        apiPath: '/admin/slide',
        actionTypes: [REQUEST_CREATE_SLIDE_BANNER, REQUEST_CREATE_SLIDE_BANNER_SUCCESS, REQUEST_CREATE_SLIDE_BANNER_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const updateSlideBanner = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['imageDesktop', 'imageMobile','title', 'link', 'type', 'index', 'expiration_date', 'in_app']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'put',
        apiPath: '/admin/slide/'+ data.id,
        actionTypes: [REQUEST_UPDATE_SLIDE_BANNER, REQUEST_UPDATE_SLIDE_BANNER_SUCCESS, REQUEST_UPDATE_SLIDE_BANNER_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

