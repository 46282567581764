import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const REQUEST_PRODUCTS_SUCCESS = 'REQUEST_PRODUCTS_SUCCESS';
export const REQUEST_PRODUCTS_FAILURE = 'REQUEST_PRODUCTS_FAILURE';
export const ACTION_CHANGE_PRODUCT_FILTER = 'ACTION_CHANGE_PRODUCT_FILTER';

export const REQUEST_DELETE_PRODUCT = 'REQUEST_DELETE_PRODUCT';
export const REQUEST_DELETE_PRODUCT_SUCCESS = 'REQUEST_DELETE_PRODUCT_SUCCESS';
export const REQUEST_DELETE_PRODUCT_FAILURE = 'REQUEST_DELETE_PRODUCT_FAILURE';

export const REQUEST_EXPORT_PRODUCTS = 'REQUEST_EXPORT_PRODUCTS';
export const REQUEST_EXPORT_PRODUCTS_SUCCESS = 'REQUEST_EXPORT_PRODUCTS_SUCCESS';
export const REQUEST_EXPORT_PRODUCTS_FAILURE = 'REQUEST_EXPORT_PRODUCTS_FAILURE';

export const REQUEST_UPDATE_PERCENTAGE = 'REQUEST_UPDATE_PERCENTAGE';
export const REQUEST_UPDATE_PERCENTAGE_SUCCESS = 'REQUEST_UPDATE_PERCENTAGE_SUCCESS';
export const REQUEST_UPDATE_PERCENTAGE_FAILURE = 'REQUEST_UPDATE_PERCENTAGE_FAILURE';

const defaultState = {
    fetchingProducts: false,
    failedFetchingProducts: false,
    products: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_PRODUCTS]: (state, action) => ({
        ...state,
        fetchingProducts: true,
        failedFetchingProducts: false,
    }),
    [ACTION_CHANGE_PRODUCT_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_PRODUCTS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingProducts: false,
        failedFetchingProducts: false,
        products: get(action, 'payload.data')
    }),
    [REQUEST_PRODUCTS_FAILURE]: (state, action) => ({
        ...state,
        fetchingProducts: false,
        failedFetchingProducts: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_PRODUCT_FILTER,
    payload
});

export default reducer;
export const namespace = 'products';


export const getProducts = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/products',
        actionTypes: [REQUEST_PRODUCTS, REQUEST_PRODUCTS_SUCCESS, REQUEST_PRODUCTS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const deleteProduct = (productId) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/products/'+productId,
        actionTypes: [
            REQUEST_DELETE_PRODUCT,
            REQUEST_DELETE_PRODUCT_SUCCESS,
            REQUEST_DELETE_PRODUCT_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};

export const exportProducts = () => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: `/admin/products/export-excel`,
        actionTypes: [REQUEST_EXPORT_PRODUCTS, REQUEST_EXPORT_PRODUCTS_SUCCESS, REQUEST_EXPORT_PRODUCTS_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const updatePromotionPercentage = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/products/supplier-prices',
        actionTypes: [REQUEST_UPDATE_PERCENTAGE, REQUEST_UPDATE_PERCENTAGE_SUCCESS, REQUEST_UPDATE_PERCENTAGE_FAILURE],
        variables: data,
        dispatch,
        getState
    });
}
