import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_SALE_GROUP_INFORMATION         = 'REQUEST_SALE_GROUP_INFORMATION';
export const REQUEST_SALE_GROUP_INFORMATION_SUCCESS = 'REQUEST_SALE_GROUP_INFORMATION_SUCCESS';
export const REQUEST_SALE_GROUP_INFORMATION_FAILURE = 'REQUEST_SALE_GROUP_INFORMATION_FAILURE';

export const REQUEST_CREATE_SALE_GROUP_INFORMATION         = 'REQUEST_CREATE_SALE_GROUP_INFORMATION';
export const REQUEST_CREATE_SALE_GROUP_INFORMATION_SUCCESS = 'REQUEST_CREATE_SALE_GROUP_INFORMATION_SUCCESS';
export const REQUEST_CREATE_SALE_GROUP_INFORMATION_FAILURE = 'REQUEST_CREATE_SALE_GROUP_INFORMATION_FAILURE';

export const REQUEST_UPDATE_SALE_GROUP_INFORMATION         = 'REQUEST_UPDATE_SALE_GROUP_INFORMATION';
export const REQUEST_UPDATE_SALE_GROUP_INFORMATION_SUCCESS = 'REQUEST_UPDATE_SALE_GROUP_INFORMATION_SUCCESS';
export const REQUEST_UPDATE_SALE_GROUP_INFORMATION_FAILURE = 'REQUEST_UPDATE_SALE_GROUP_INFORMATION_FAILURE';

export const REQUEST_DELETE_SALE_GROUP_INFORMATION         = 'REQUEST_DELETE_SALE_GROUP_INFORMATION';
export const REQUEST_DELETE_SALE_GROUP_INFORMATION_SUCCESS = 'REQUEST_DELETE_SALE_GROUP_INFORMATION_SUCCESS';
export const REQUEST_DELETE_SALE_GROUP_INFORMATION_FAILURE = 'REQUEST_DELETE_SALE_GROUP_INFORMATION_FAILURE';
export const ACTION_CHANGE_SALE_GROUP_INFORMATION_FILTER   = 'ACTION_CHANGE_SALE_GROUP_INFORMATION_FILTER';


const defaultState = {
    fetchingSaleGroupInformation: false,
    failedFetchingSaleGroupInformation: false,
    saleGroupInformation: {},
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_SALE_GROUP_INFORMATION]: (state, action) => ({
        ...state,
        fetchingSaleGroupInformation: true,
        failedFetchingSaleGroupInformation: false,
    }),
    [REQUEST_SALE_GROUP_INFORMATION_SUCCESS]: (state, action) => ({
        ...state,
        fetchingSaleGroupInformation: false,
        failedFetchingSaleGroupInformation: false,
        saleGroupInformation: get(action, 'payload.data')
    }),
    [REQUEST_SALE_GROUP_INFORMATION_FAILURE]: (state, action) => ({
        ...state,
        fetchingSaleGroupInformation: false,
        failedFetchingSaleGroupInformation: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_SALE_GROUP_INFORMATION_FILTER,
    payload
});

export default reducer;
export const namespace = 'saleGroupInformation';

export const getProgramByCode = (code = 'sale_group') => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/program-information/code/'+code,
        actionTypes: [REQUEST_SALE_GROUP_INFORMATION, REQUEST_SALE_GROUP_INFORMATION_SUCCESS, REQUEST_SALE_GROUP_INFORMATION_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const updateSaleGroupInformation = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['banner_desktop_url', 'banner_mobile_url', 'title', 'rule']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'put',
        apiPath: '/admin/program-information/'+ data.id,
        actionTypes: [REQUEST_UPDATE_SALE_GROUP_INFORMATION, REQUEST_UPDATE_SALE_GROUP_INFORMATION_SUCCESS, REQUEST_UPDATE_SALE_GROUP_INFORMATION_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

