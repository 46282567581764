import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';

import { get } from 'lodash';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const REQUEST_CATEGORIES_SUCCESS = 'REQUEST_CATEGORIES_SUCCESS';
export const REQUEST_CATEGORIES_FAILURE = 'REQUEST_CATEGORIES_FAILURE';
export const ACTION_CHANGE_CATEGORY_FILTER = 'ACTION_CHANGE_CATEGORY_FILTER';

export const REQUEST_ALL_CATEGORIES_SUCCESS = 'REQUEST_ALL_CATEGORIES_SUCCESS';

export const REQUEST_DELETE_CATEGORY = 'REQUEST_DELETE_CATEGORY';
export const REQUEST_DELETE_CATEGORY_SUCCESS = 'REQUEST_DELETE_CATEGORY_SUCCESS';
export const REQUEST_DELETE_CATEGORY_FAILURE = 'REQUEST_DELETE_CATEGORY_FAILURE';

export const REQUEST_UPDATE_CATEGORY = 'REQUEST_UPDATE_CATEGORY';
export const REQUEST_UPDATE_CATEGORY_SUCCESS = 'REQUEST_UPDATE_CATEGORY_SUCCESS';
export const REQUEST_UPDATE_CATEGORY_FAILURE = 'REQUEST_UPDATE_CATEGORY_FAILURE';

export const REQUEST_CREATE_CATEGORY = 'REQUEST_CREATE_CATEGORY';
export const REQUEST_CREATE_CATEGORY_SUCCESS = 'REQUEST_CREATE_CATEGORY_SUCCESS';
export const REQUEST_CREATE_CATEGORY_FAILURE = 'REQUEST_CREATE_CATEGORY_FAILURE';

const defaultState = {
  fetchingCategories: false,
  failedFetchingCategories: false,
  categories: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  allCategories: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
  [ACTION_CHANGE_CATEGORY_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
  }),
  [REQUEST_CATEGORIES_SUCCESS]: (state, action) => ({
    ...state,
    categories: get(action, 'payload.data')
  }),
  [REQUEST_ALL_CATEGORIES_SUCCESS]: (state, action) => ({
    ...state,
    allCategories: get(action, 'payload.data')
  }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_CATEGORY_FILTER,
  payload
});

export default reducer;
export const namespace = 'category';

export const getCategories = (params = { page: 1 }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/categories',
    actionTypes: [REQUEST_CATEGORIES, REQUEST_CATEGORIES_SUCCESS, REQUEST_CATEGORIES_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const getAllCategories = (params = { page: 1, limit: 100 }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/categories',
    actionTypes: [REQUEST_CATEGORIES, REQUEST_ALL_CATEGORIES_SUCCESS, REQUEST_CATEGORIES_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const updateCategory = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/admin/categories/' + data.id,
    actionTypes: [REQUEST_UPDATE_CATEGORY, REQUEST_UPDATE_CATEGORY_SUCCESS, REQUEST_UPDATE_CATEGORY_FAILURE],
    variables: {
      name: data.name,
      status: data.status,
      description: data.description,
      image: data.image,
      icon: data.icon,
      children: data.children,
      display_order: data.display_order
    },
    dispatch,
    getState
  });
};

export const createCategory = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/categories',
    actionTypes: [REQUEST_CREATE_CATEGORY, REQUEST_CREATE_CATEGORY_SUCCESS, REQUEST_CREATE_CATEGORY_FAILURE],
    variables: { name: data.name, description: data.description, children: data.children, image: data.image, icon: data.icon,  display_order: data.display_order },
    dispatch,
    getState
  });
};

export const deleteCategory = (contentId) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: '/admin/categories/' + contentId,
    actionTypes: [
      REQUEST_DELETE_CATEGORY,
      REQUEST_DELETE_CATEGORY_SUCCESS,
      REQUEST_DELETE_CATEGORY_FAILURE
    ],
    variables: {},
    dispatch,
    getState
  });
};