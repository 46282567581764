import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_ORDER_DETAIL = 'REQUEST_ORDER_DETAIL';
export const REQUEST_ORDER_DETAIL_SUCCESS = 'REQUEST_ORDER_DETAIL_SUCCESS';
export const REQUEST_ORDER_DETAIL_FAILURE = 'REQUEST_ORDER_DETAIL_FAILURE';

export const REQUEST_CHANGE_PAYMENT_STATUS = 'REQUEST_CHANGE_PAYMENT_STATUS';
export const REQUEST_CHANGE_PAYMENT_STATUS_SUCCESS = 'REQUEST_CHANGE_PAYMENT_STATUS_SUCCESS';
export const REQUEST_CHANGE_PAYMENT_STATUS_FAILURE = 'REQUEST_CHANGE_PAYMENT_STATUS_FAILURE';

const defaultState = {
    data: null,
};

const reducer = createReducer(defaultState, {
    [REQUEST_ORDER_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        data: get(action, 'payload.data')
    }),
    [REQUEST_CHANGE_PAYMENT_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        data: get(action, 'payload.data')
    }),
});

export default reducer;
export const namespace = 'orderDetail';

export const getOrderDetailById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/orders/'+id,
        actionTypes: [REQUEST_ORDER_DETAIL, REQUEST_ORDER_DETAIL_SUCCESS, REQUEST_ORDER_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const updateStatusPaymentMethod = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'patch',
        apiPath: `/admin/orders/${data.id}/status-payment`,
        actionTypes: [
            REQUEST_CHANGE_PAYMENT_STATUS,
            REQUEST_CHANGE_PAYMENT_STATUS_SUCCESS,
            REQUEST_CHANGE_PAYMENT_STATUS_FAILURE
        ],
        variables: { status: data.status },
        dispatch,
        getState
    });
};
