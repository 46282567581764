import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getStores,
  getAllStores,
  ACTION_CHANGE_STORE_FILTER,
  REQUEST_DELETE_STORE_SUCCESS,
  REQUEST_DELETE_STORE_FAILURE,
  REQUEST_UPDATE_STORE_SUCCESS,
  REQUEST_UPDATE_STORE_FAILURE,
  REQUEST_CREATE_STORE_SUCCESS,
  REQUEST_CREATE_STORE_FAILURE
} from './index'
import { showToastAlert } from "../app";

function* loadRouteData() {
  yield put(getStores())
  yield put(getAllStores())
}

function* handleListenActions() {
  yield takeLatest([
    ACTION_CHANGE_STORE_FILTER
  ], function* (action) {
    const { store } = yield select();
    const {
      filter
    } = store;
    yield put(getStores(filter))
  });
}

function* handleDeleteStoreSuccess() {
  yield takeLatest(REQUEST_DELETE_STORE_SUCCESS, function* (action) {
    yield put(showToastAlert('Xóa kho hàng thành công'))
    yield put(getStores())
  });
}

function* handleDeleteStoreFailure() {
  yield takeLatest(REQUEST_DELETE_STORE_FAILURE, function* (action) {
    yield put(showToastAlert('Xóa kho hàng thất bại', 'error'))
    yield put(getStores())
  });
}

function* handleUpdateStoreSuccess() {
  yield takeLatest(REQUEST_UPDATE_STORE_SUCCESS, function* (action) {
    yield put(showToastAlert('cập nhật kho hàng thành công'))
    yield put(getStores())
  });
}

function* handleUpdateStoreFailure() {
  yield takeLatest(REQUEST_UPDATE_STORE_FAILURE, function* (action) {
    yield put(showToastAlert('cập nhật kho hàng thất bại', 'error'))
    yield put(getStores())
  });
}

function* handleCreateStoreSuccess() {
  yield takeLatest(REQUEST_CREATE_STORE_SUCCESS, function* (action) {
    yield put(showToastAlert('tạo mới kho hàng thành công'))
    yield put(getStores())
  });
}

function* handleCreateContentFailure() {
  yield takeLatest(REQUEST_CREATE_STORE_FAILURE, function* (action) {
    yield put(showToastAlert('tạo mới kho hàng thất bại', 'error'))
    yield put(getStores())
  });
}

export function* loadStorePage() {
  yield all([
    fork(handleListenActions),
    fork(loadRouteData),
    fork(handleDeleteStoreSuccess),
    fork(handleDeleteStoreFailure),
    fork(handleUpdateStoreSuccess),
    fork(handleUpdateStoreFailure),
    fork(handleCreateStoreSuccess),
    fork(handleCreateContentFailure)
  ]);
}
