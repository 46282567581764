import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_CONTENTS = 'REQUEST_CONTENTS';
export const REQUEST_CONTENTS_SUCCESS = 'REQUEST_CONTENTS_SUCCESS';
export const REQUEST_CONTENTS_FAILURE = 'REQUEST_CONTENTS_FAILURE';
export const ACTION_CHANGE_CONTENT_FILTER = 'ACTION_CHANGE_CONTENT_FILTER';

export const REQUEST_DELETE_CONTENT = 'REQUEST_DELETE_CONTENT';
export const REQUEST_DELETE_CONTENT_SUCCESS = 'REQUEST_DELETE_CONTENT_SUCCESS';
export const REQUEST_DELETE_CONTENT_FAILURE = 'REQUEST_DELETE_CONTENT_FAILURE';

export const REQUEST_UPDATE_CONTENT = 'REQUEST_UPDATE_CONTENT';
export const REQUEST_UPDATE_CONTENT_SUCCESS = 'REQUEST_UPDATE_CONTENT_SUCCESS';
export const REQUEST_UPDATE_CONTENT_FAILURE = 'REQUEST_UPDATE_CONTENT_FAILURE';

export const REQUEST_CREATE_CONTENT = 'REQUEST_CREATE_CONTENT';
export const REQUEST_CREATE_CONTENT_SUCCESS = 'REQUEST_CREATE_CONTENT_SUCCESS';
export const REQUEST_CREATE_CONTENT_FAILURE = 'REQUEST_CREATE_CONTENT_FAILURE';

const defaultState = {
  // fetchingUsers: false,
  // failedFetchingUsers: false,
  contents: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CONTENT_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),
    [REQUEST_CONTENTS_SUCCESS]: (state, action) => ({
        ...state,
        contents: get(action, 'payload.data')
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_CONTENT_FILTER,
  payload
});

export default reducer;
export const namespace = 'contents';


export const getContents = (params = {page: 1}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/page_contents',
    actionTypes: [REQUEST_CONTENTS, REQUEST_CONTENTS_SUCCESS, REQUEST_CONTENTS_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const updateContent = (record)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/admin/page_contents/'+record.id,
    actionTypes: [REQUEST_UPDATE_CONTENT, REQUEST_UPDATE_CONTENT_SUCCESS, REQUEST_UPDATE_CONTENT_FAILURE],
    variables: record,
    dispatch,
    getState
  });
};

export const createContent = (data)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/page_contents',
    actionTypes: [REQUEST_CREATE_CONTENT, REQUEST_CREATE_CONTENT_SUCCESS, REQUEST_CREATE_CONTENT_FAILURE],
    variables: {
        title: data.title,
        content: data.content,
        short_content: data.short_content,
        image: data.image,
        category: data.category,
    },
    dispatch,
    getState
  });
};

export const deleteContent = (contentId) => async (dispatch, getState) => {
	return await callAPI({
		method: 'delete',
		apiPath: '/admin/page_contents/'+contentId,
		actionTypes: [
			REQUEST_DELETE_CONTENT,
			REQUEST_DELETE_CONTENT_SUCCESS,
			REQUEST_DELETE_CONTENT_FAILURE
		],
		variables: {
		},
		dispatch,
		getState
	});
};