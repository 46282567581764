import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_SALE_GROUPS         = 'REQUEST_SALE_GROUPS';
export const REQUEST_SALE_GROUPS_SUCCESS = 'REQUEST_SALE_GROUPS_SUCCESS';
export const REQUEST_SALE_GROUPS_FAILURE = 'REQUEST_SALE_GROUPS_FAILURE';
export const ACTION_CHANGE_SALE_GROUP_FILTER   = 'ACTION_CHANGE_SALE_GROUP_FILTER';

export const REQUEST_UPDATE_SALE_GROUP         = 'REQUEST_UPDATE_SALE_GROUP';
export const REQUEST_UPDATE_SALE_GROUP_SUCCESS = 'REQUEST_UPDATE_SALE_GROUP_SUCCESS';
export const REQUEST_UPDATE_SALE_GROUP_FAILURE = 'REQUEST_UPDATE_SALE_GROUP_FAILURE';

export const REQUEST_DELETE_SALE_GROUP         = 'REQUEST_DELETE_SALE_GROUP';
export const REQUEST_DELETE_SALE_GROUP_SUCCESS = 'REQUEST_DELETE_SALE_GROUP_SUCCESS';
export const REQUEST_DELETE_SALE_GROUP_FAILURE = 'REQUEST_DELETE_SALE_GROUP_FAILURE';

export const REQUEST_EXPORT_SALE_GROUPS         = 'REQUEST_EXPORT_SALE_GROUPS';
export const REQUEST_EXPORT_SALE_GROUPS_SUCCESS = 'REQUEST_EXPORT_SALE_GROUPS_SUCCESS';
export const REQUEST_EXPORT_SALE_GROUPS_FAILURE = 'REQUEST_EXPORT_SALE_GROUPS_FAILURE';

const defaultState = {
    fetchingSaleGroups: false,
    failedFetchingSaleGroups: false,
    saleGroups: {},
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_SALE_GROUPS]: (state, action) => ({
        ...state,
        fetchingSaleGroups: true,
        failedFetchingSaleGroups: false,
    }),

    [REQUEST_SALE_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingSaleGroups: false,
        failedFetchingSaleGroups: false,
        saleGroups: get(action, 'payload.data')
    }),

    [REQUEST_SALE_GROUPS_FAILURE]: (state, action) => ({
        ...state,
        fetchingSaleGroups: false,
        failedFetchingSaleGroups: true,
    }),

    [ACTION_CHANGE_SALE_GROUP_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
});

export const changeFilterSaleGroups = (payload) => ({
    type: ACTION_CHANGE_SALE_GROUP_FILTER,
    payload
});

export default reducer;
export const namespace = 'saleGroups';

export const getSaleGroups = (params = { page: 1 }) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
    return await callAPI({
        method: 'get',
        apiPath: '/admin/sale-groups',
        actionTypes: [REQUEST_SALE_GROUPS, REQUEST_SALE_GROUPS_SUCCESS, REQUEST_SALE_GROUPS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const updateSaleGroup = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/sale-groups/'+data.id,
        actionTypes: [REQUEST_UPDATE_SALE_GROUP, REQUEST_UPDATE_SALE_GROUP_SUCCESS, REQUEST_UPDATE_SALE_GROUP_FAILURE],
        variables: data,
        dispatch,
        getState
    });
};

export const deleteSaleGroup = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/sale-groups/'+id,
        actionTypes: [REQUEST_DELETE_SALE_GROUP, REQUEST_DELETE_SALE_GROUP_SUCCESS, REQUEST_DELETE_SALE_GROUP_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const exportSaleGroups = (params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/sale-groups/export',
        actionTypes: [REQUEST_EXPORT_SALE_GROUPS, REQUEST_EXPORT_SALE_GROUPS_SUCCESS, REQUEST_EXPORT_SALE_GROUPS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};