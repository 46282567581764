import {
    all,
    put,
    fork, takeLatest,
} from 'redux-saga/effects';

import {
    getSlideBanners,
    REQUEST_DELETE_SLIDE_BANNER_FAILURE,
    REQUEST_DELETE_SLIDE_BANNER_SUCCESS,
    REQUEST_CREATE_SLIDE_BANNER_SUCCESS,
    REQUEST_UPDATE_SLIDE_BANNER_SUCCESS,
    REQUEST_CREATE_SLIDE_BANNER_FAILURE
} from './index';
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_SLIDE_BANNER} from "../routing";

function* loadRouteData() {
    yield put(getSlideBanners())
}

function* handleListenActions() {

}

function* handleDeleteSlideSuccess() {
    yield takeLatest(REQUEST_DELETE_SLIDE_BANNER_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa banner thành công'));
        yield put(getSlideBanners())
    });
}

function* handleDeleteSlideFailure() {
    yield takeLatest(REQUEST_DELETE_SLIDE_BANNER_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa banner thất bại'));
        yield put(getSlideBanners())
    });
}

function* handleCreateSlideFailure() {
    yield takeLatest(REQUEST_CREATE_SLIDE_BANNER_FAILURE, function* (action) {
        yield put(showToastAlert('vị trí đã tồn tại', "error"));
        yield put(getSlideBanners())
    });
}

function* handleCreateSlideSuccess() {
    yield takeLatest(REQUEST_CREATE_SLIDE_BANNER_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo mới thành công'));
        yield put(redirect({
            type: ROUTE_SLIDE_BANNER,
            query: ROUTE_SLIDE_BANNER
        }));
    });
}

function* handleUpdateSlideSuccess() {
    yield takeLatest(REQUEST_UPDATE_SLIDE_BANNER_SUCCESS, function* (action) {
        yield put(showToastAlert('Đã chỉnh sửa'));
        yield put(getSlideBanners());
    });
}

export function* loadSlideBannerPage() {
    yield all([
        fork(loadRouteData),
        fork(handleDeleteSlideSuccess),
        fork(handleDeleteSlideFailure),
        fork(handleListenActions),
        fork(handleCreateSlideSuccess),
        fork(handleCreateSlideFailure),
        fork(handleUpdateSlideSuccess),
    ]);
}
