import {
    all,
    takeLatest,
    put,
    select,
    fork
} from 'redux-saga/effects';
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_QUESTION_ANSWER} from "../routing";
import {
    getQuestionAnswerById,
    REQUEST_CREATE_QUESTION_ANSWER_FAILURE,
    REQUEST_CREATE_QUESTION_ANSWER_SUCCESS,
    REQUEST_UPDATE_QUESTION_ANSWER_FAILURE,
    REQUEST_UPDATE_QUESTION_ANSWER_SUCCESS
} from "./index";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id && location.payload.id !== "create") {
        yield put(getQuestionAnswerById(location.payload.id));
    }
}

function* handleListenActions() {

}

function* handleCreateQuestionAnswerSuccess() {
    yield takeLatest(REQUEST_CREATE_QUESTION_ANSWER_SUCCESS, function* (action) {
        yield put(showToastAlert('tạo mới thành công'));
        yield put(redirect({
            type: ROUTE_QUESTION_ANSWER,
        }));
    });
}

function* handleCreateQuestionAnswerFailure() {
    yield takeLatest(REQUEST_CREATE_QUESTION_ANSWER_FAILURE, function* (action) {
        yield put(showToastAlert('tạo mới thất bại, kiểm tra code đã tồn tại', 'error'));
    });
}

function* handleUpdateQuestionAnswerSuccess() {
    yield takeLatest(REQUEST_UPDATE_QUESTION_ANSWER_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật thành công'));
        yield put(redirect({
            type: ROUTE_QUESTION_ANSWER,
        }));
    });
}

function* handleUpdateQuestionAnswerFailure() {
    yield takeLatest(REQUEST_UPDATE_QUESTION_ANSWER_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật thất bại, kiểm tra code đã tồn tại', 'error'));
    });
}

export function* loadQuestionAnswerDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleCreateQuestionAnswerSuccess),
        fork(handleCreateQuestionAnswerFailure),
        fork(handleUpdateQuestionAnswerSuccess),
        fork(handleUpdateQuestionAnswerFailure),
    ]);
}
