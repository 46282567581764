import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_WITHDRAWAL_ORDERS = 'REQUEST_WITHDRAWAL_ORDERS';
export const REQUEST_WITHDRAWAL_ORDERS_SUCCESS = 'REQUEST_WITHDRAWAL_ORDERS_SUCCESS';
export const REQUEST_WITHDRAWAL_ORDERS_FAILURE = 'REQUEST_WITHDRAWAL_ORDERS_FAILURE';
export const ACTION_CHANGE_WITHDRAWAL_ORDER_FILTER = 'ACTION_CHANGE_WITHDRAWAL_ORDER_FILTER';

export const REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS = 'REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS';
export const REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_SUCCESS = 'REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_SUCCESS';
export const REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_FAILURE = 'REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_FAILURE';

const defaultState = {
  fetchingWithdrawalOrders: false,
  failedFetchingWithdrawalOrders: false,
  withdrawalOrders: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0
  },
  filter: {
    search_key: "",
    page: 1
  },
  errors: []
};

const reducer = createReducer(defaultState, {
  [REQUEST_WITHDRAWAL_ORDERS]: (state, action) => ({
    ...state,
    fetchingWithdrawalOrders: true,
    failedFetchingWithdrawalOrders: false,
  }),
  [ACTION_CHANGE_WITHDRAWAL_ORDER_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
  }),
  [REQUEST_WITHDRAWAL_ORDERS_SUCCESS]: (state, action) => ({
    ...state,
    fetchingWithdrawalOrders: false,
    failedFetchingWithdrawalOrders: false,
    withdrawalOrders: get(action, 'payload.data')
  }),
  [REQUEST_WITHDRAWAL_ORDERS_FAILURE]: (state, action) => ({
    ...state,
    fetchingWithdrawalOrders: false,
    failedFetchingWithdrawalOrders: true,
  }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_WITHDRAWAL_ORDER_FILTER,
  payload
});

export default reducer;
export const namespace = 'withdrawalOrders';

export const getWithdrawalOrders = (params = { page: 1 }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/withdrawal-orders',
    actionTypes: [REQUEST_WITHDRAWAL_ORDERS, REQUEST_WITHDRAWAL_ORDERS_SUCCESS, REQUEST_WITHDRAWAL_ORDERS_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const changeWithdrawalOrderStatus = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'patch',
    apiPath: `/admin/withdrawal-orders/${data.id}/status`,
    actionTypes: [
      REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS,
      REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_SUCCESS,
      REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_FAILURE
    ],
    variables: { status: data.status },
    dispatch,
    getState
  });
};
