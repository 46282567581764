import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';
export const REQUEST_COLLABORATORS = 'REQUEST_COLLABORATORS';
export const REQUEST_COLLABORATORS_SUCCESS = 'REQUEST_COLLABORATORS_SUCCESS';
export const REQUEST_COLLABORATORS_FAILURE = 'REQUEST_COLLABORATORS_FAILURE';
export const ACTION_CHANGE_COLLABORATORS_FILTER = 'ACTION_CHANGE_COLLABORATORS_FILTER';

export const REQUEST_DELETE_COLLABORATOR = 'REQUEST_DELETE_COLLABORATOR';
export const REQUEST_DELETE_COLLABORATOR_SUCCESS = 'REQUEST_DELETE_COLLABORATOR_SUCCESS';
export const REQUEST_DELETE_COLLABORATOR_FAILURE = 'REQUEST_DELETE_COLLABORATOR_FAILURE';

export const REQUEST_UPDATE_COLLABORATOR = 'REQUEST_UPDATE_COLLABORATOR';
export const REQUEST_UPDATE_COLLABORATOR_SUCCESS = 'REQUEST_UPDATE_COLLABORATOR_SUCCESS';
export const REQUEST_UPDATE_COLLABORATOR_FAILURE = 'REQUEST_UPDATE_COLLABORATOR_FAILURE';

export const REQUEST_CHANGE_ADMINISTRATOR = 'REQUEST_CHANGE_ADMINISTRATOR';
export const REQUEST_CHANGE_ADMINISTRATOR_SUCCESS = 'REQUEST_CHANGE_ADMINISTRATOR_SUCCESS';
export const REQUEST_CHANGE_ADMINISTRATOR_FAILURE = 'REQUEST_CHANGE_ADMINISTRATOR_FAILURE';

const defaultState = {
  fetchingUsers: false,
  failedFetchingUsers: false,
  collaborators: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_COLLABORATORS_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),

    [REQUEST_COLLABORATORS]: (state, action) => ({
        ...state,
        fetchingUsers: true,
        failedFetchingUsers: false,
    }),

    [REQUEST_COLLABORATORS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingUsers: false,
        failedFetchingUsers: false,
        collaborators: get(action, 'payload.data')
    }),

    [REQUEST_COLLABORATORS_FAILURE]: (state, action) => ({
        ...state,
        fetchingUsers: false,
        failedFetchingUsers: true,
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_COLLABORATORS_FILTER,
  payload
});

export default reducer;
export const namespace = 'collaborators';


export const getCollaborators = (params = {page: 1}) => async (dispatch, getState) => {
    if (params.date_range_joined_at) {
        params.date_range_joined_at = await params.date_range_joined_at.map(date => date.toISOString());
    }

    if (params.filter_date_range) {
        params.filter_date_range = await params.filter_date_range.map(date => date.toISOString());
    }

  return await callAPI({
    method: 'get',
    apiPath: '/admin/users',
    actionTypes: [REQUEST_COLLABORATORS, REQUEST_COLLABORATORS_SUCCESS, REQUEST_COLLABORATORS_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const updateCollaborator = (data) => async (dispatch, getState) => {
  const variables = Object.fromEntries(
    ['status', 'note', 'training_activity_id', 'remove_training_activity']
    .filter(key => key in data)
    .map(key => [key, data[key]])
  );
  return await callAPI({
    method: 'put',
    apiPath: `/admin/users/${data.id}`,
    actionTypes: [REQUEST_UPDATE_COLLABORATOR, REQUEST_UPDATE_COLLABORATOR_SUCCESS, REQUEST_UPDATE_COLLABORATOR_FAILURE],
    variables: variables,
    dispatch,
    getState
  });
};

export const modifyVoucherForUser = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: `/admin/users/${data.id}/update-voucher`,
        actionTypes: [REQUEST_UPDATE_COLLABORATOR, REQUEST_UPDATE_COLLABORATOR_SUCCESS, REQUEST_UPDATE_COLLABORATOR_FAILURE],
        variables: {
            userId: data.id,
            vouchers: data.vouchers
        },
        dispatch,
        getState
    });
};

export const deleteCollaborator = (collaboratorId) => async (dispatch, getState) => {
	return await callAPI({
		method: 'post',
		apiPath: '/collaborators/delete',
		actionTypes: [
			REQUEST_DELETE_COLLABORATOR,
			REQUEST_DELETE_COLLABORATOR_SUCCESS,
			REQUEST_DELETE_COLLABORATOR_FAILURE
		],
		variables: {
			_id: collaboratorId
		},
		dispatch,
		getState
	});
};

export const changeAdministrator = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: `/admin/users/${data.id}/administrator/${data.administrator_id}`,
    actionTypes: [REQUEST_CHANGE_ADMINISTRATOR, REQUEST_CHANGE_ADMINISTRATOR_SUCCESS, REQUEST_CHANGE_ADMINISTRATOR_FAILURE],
    variables: {
      userId: data.id,
      vouchers: data.vouchers
    },
    dispatch,
    getState
  });
};