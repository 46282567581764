import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';
export const REQUEST_AFFILIATE_STATS = 'REQUEST_AFFILIATE_STATS';
export const REQUEST_AFFILIATE_STATS_SUCCESS = 'REQUEST_AFFILIATE_STATS_SUCCESS';
export const REQUEST_AFFILIATE_STATS_FAILURE = 'REQUEST_AFFILIATE_STATS_FAILURE';

export const REQUEST_EXPORT_AFFILIATE_STATS         = 'REQUEST_EXPORT_AFFILIATE_STATS';
export const REQUEST_EXPORT_AFFILIATE_STATS_SUCCESS = 'REQUEST_EXPORT_AFFILIATE_STATS_SUCCESS';
export const REQUEST_EXPORT_AFFILIATE_STATS_FAILURE = 'REQUEST_EXPORT_AFFILIATE_STATS_FAILURE';

export const ACTION_CHANGE_AFFILIATE_STATS_FILTER = 'ACTION_CHANGE_AFFILIATE_STATS_FILTER';

const defaultState = {
  fetchingAffiliateStats: false,
  failedFetchingAffiliateStats: false,
  affiliateStats: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_AFFILIATE_STATS_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),
    [REQUEST_AFFILIATE_STATS]: (state, action) => ({
        ...state,
        fetchingAffiliateStats: true,
        failedFetchingAffiliateStats: false,
    }),
    [REQUEST_AFFILIATE_STATS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingAffiliateStats: false,
        failedFetchingAffiliateStats: false,
        affiliateStats: get(action, 'payload.data'),
    }),
    [REQUEST_AFFILIATE_STATS_FAILURE]: (state, action) => ({
        ...state,
        fetchingAffiliateStats: false,
        failedFetchingAffiliateStats: true,
    })
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_AFFILIATE_STATS_FILTER,
  payload
});

export default reducer;
export const namespace = 'affiliateStats';


export const getAffiliateStats = (id, params) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
  return await callAPI({
    method: 'get',
    apiPath: '/admin/affiliate-programs/'+id+'/stats',
    actionTypes: [REQUEST_AFFILIATE_STATS, REQUEST_AFFILIATE_STATS_SUCCESS, REQUEST_AFFILIATE_STATS_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const exportAffiliateStats = (id, params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/affiliate-programs/'+id+'/export-stats',
        actionTypes: [REQUEST_EXPORT_AFFILIATE_STATS, REQUEST_EXPORT_AFFILIATE_STATS_SUCCESS, REQUEST_EXPORT_AFFILIATE_STATS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};
