import {
    all,
    fork,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_AFFILIATE_FILTER,
    REQUEST_COPY_AFFILIATE_SUCCESS,
    REQUEST_DELETE_AFFILIATE_SUCCESS,
    getAffiliates} from "./index";
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getAffiliates())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_AFFILIATE_FILTER
    ], function* (action) {
        const {affiliates} = yield select();
        const {
            filter
        } = affiliates;
        yield put(getAffiliates(filter))
    });
}

function* handleDeleteAffiliateSuccess() {
    yield takeLatest(REQUEST_DELETE_AFFILIATE_SUCCESS, function* (action) {
        yield put(showToastAlert('đã xóa thành công'));
        yield put(getAffiliates())
    });
}

function* handleCopyAffiliateSuccess() {
    yield takeLatest(REQUEST_COPY_AFFILIATE_SUCCESS, function* (action) {
        yield put(showToastAlert('đã sao chép thành công'));
        yield put(getAffiliates())
    });
}


export function* loadAffiliatePage() {
    yield all([
        fork(handleListenActions),
        fork(handleDeleteAffiliateSuccess),
        fork(handleCopyAffiliateSuccess),
        fork(loadRouteData)
    ]);
}
    