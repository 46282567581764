import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getCollaborators,
  ACTION_CHANGE_COLLABORATORS_FILTER,
  REQUEST_DELETE_COLLABORATOR_SUCCESS,
  REQUEST_DELETE_COLLABORATOR_FAILURE,
  REQUEST_UPDATE_COLLABORATOR_SUCCESS,
  REQUEST_UPDATE_COLLABORATOR_FAILURE,
  REQUEST_CHANGE_ADMINISTRATOR_SUCCESS,
  REQUEST_CHANGE_ADMINISTRATOR_FAILURE
} from './index'
import { showToastAlert } from "../app";
import { ACTION_CHANGE_VOUCHER_FILTER, getVouchers } from "../voucher";
import { getModerators } from "../moderator";

function* loadRouteData() {
    yield put(getCollaborators());
    yield put(getVouchers({page: 1, isGetListOfAdmin: false}));
    yield put(getModerators({ page: 1, limit: 100 }));
  }

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_COLLABORATORS_FILTER
    ], function* (action) {
      const {collaborators} = yield select();
      const {
          filter
      } = collaborators;
    yield put(getCollaborators(filter))
    });

    yield takeLatest([
        ACTION_CHANGE_VOUCHER_FILTER,
    ], function* (action) {
        const {vouchers} = yield select();
        const {
            filter
        } = vouchers;
        yield put(getVouchers(filter))
    });
}

function* handleDeleteCollaboratorSuccess() {
    yield takeLatest(REQUEST_DELETE_COLLABORATOR_SUCCESS, function* (action) {
      yield put(showToastAlert('Xóa công tác viên thành công'))
      yield put(getCollaborators())
    });
}

function* handleDeleteCollaboratorFailure() {
    yield takeLatest(REQUEST_DELETE_COLLABORATOR_FAILURE, function* (action) {
      yield put(showToastAlert('Xóa công tác viên thất bại', 'error'))
      yield put(getCollaborators())
    });
}

function* handleUpdateCollaboratorSuccess() {
    yield takeLatest(REQUEST_UPDATE_COLLABORATOR_SUCCESS, function* (action) {
      yield put(showToastAlert('cập nhật công tác viên thành công'))
      yield put(getCollaborators())
    });
}

function* handleUpdateCollaboratorFailure() {
    yield takeLatest(REQUEST_UPDATE_COLLABORATOR_FAILURE, function* (action) {
      yield put(showToastAlert('cập nhật công tác viên thất bại', 'error'))
      yield put(getCollaborators())
    });
}

function* handleChangeAdministratorSuccess() {
  yield takeLatest(REQUEST_CHANGE_ADMINISTRATOR_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))
  });
}

function* handleChangeAdministratorFailure() {
  yield takeLatest(REQUEST_CHANGE_ADMINISTRATOR_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.message, 'error'))
  });
}

export function* loadCollaboratorsPage() {
  yield all([
    fork(handleListenActions),
    fork(loadRouteData),
    fork(handleDeleteCollaboratorSuccess),
    fork(handleDeleteCollaboratorFailure),
    fork(handleUpdateCollaboratorSuccess),
    fork(handleUpdateCollaboratorFailure),
    fork(handleChangeAdministratorSuccess),
    fork(handleChangeAdministratorFailure)
  ]);
}
  