import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';

import { get } from 'lodash';

export const REQUEST_STORES = 'REQUEST_STORES';
export const REQUEST_STORES_SUCCESS = 'REQUEST_STORES_SUCCESS';
export const REQUEST_STORES_FAILURE = 'REQUEST_STORES_FAILURE';
export const ACTION_CHANGE_STORE_FILTER = 'ACTION_CHANGE_STORE_FILTER';

export const REQUEST_ALL_STORES_SUCCESS = 'REQUEST_ALL_STORES_SUCCESS';

export const REQUEST_DELETE_STORE = 'REQUEST_DELETE_STORE';
export const REQUEST_DELETE_STORE_SUCCESS = 'REQUEST_DELETE_STORE_SUCCESS';
export const REQUEST_DELETE_STORE_FAILURE = 'REQUEST_DELETE_STORE_FAILURE';

export const REQUEST_UPDATE_STORE = 'REQUEST_UPDATE_STORE';
export const REQUEST_UPDATE_STORE_SUCCESS = 'REQUEST_UPDATE_STORE_SUCCESS';
export const REQUEST_UPDATE_STORE_FAILURE = 'REQUEST_UPDATE_STORE_FAILURE';

export const REQUEST_CREATE_STORE = 'REQUEST_CREATE_STORE';
export const REQUEST_CREATE_STORE_SUCCESS = 'REQUEST_CREATE_STORE_SUCCESS';
export const REQUEST_CREATE_STORE_FAILURE = 'REQUEST_CREATE_STORE_FAILURE';

const defaultState = {
  fetchingStores: false,
  failedFetchingStores: false,
  stores: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  allStores: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
  [ACTION_CHANGE_STORE_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
  }),
  [REQUEST_STORES_SUCCESS]: (state, action) => ({
    ...state,
    stores: get(action, 'payload.data')
  }),
  [REQUEST_ALL_STORES_SUCCESS]: (state, action) => ({
    ...state,
    allStores: get(action, 'payload.data')
  }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_STORE_FILTER,
  payload
});

export default reducer;
export const namespace = 'store';

export const getStores = (params = { page: 1 }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/stores',
    actionTypes: [REQUEST_STORES, REQUEST_STORES_SUCCESS, REQUEST_STORES_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const getAllStores = (params = { page: 1, limit: 100 }) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/stores',
    actionTypes: [REQUEST_STORES, REQUEST_ALL_STORES_SUCCESS, REQUEST_STORES_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const updateStore = (data) => async (dispatch, getState) => {
  const variables = Object.fromEntries(
    ['name', 'status']
    .filter(key => key in data)
    .map(key => [key, data[key]])
  );
  return await callAPI({
    method: 'put',
    apiPath: '/admin/stores/' + data.id,
    actionTypes: [REQUEST_UPDATE_STORE, REQUEST_UPDATE_STORE_SUCCESS, REQUEST_UPDATE_STORE_FAILURE],
    variables: variables,
    dispatch,
    getState
  });
};

export const createStore = (data) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/stores',
    actionTypes: [REQUEST_CREATE_STORE, REQUEST_CREATE_STORE_SUCCESS, REQUEST_CREATE_STORE_FAILURE],
    variables: { name: data.name, description: data.description, children: data.children },
    dispatch,
    getState
  });
};

export const deleteStore = (contentId) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: '/admin/stores/' + contentId,
    actionTypes: [
      REQUEST_DELETE_STORE,
      REQUEST_DELETE_STORE_SUCCESS,
      REQUEST_DELETE_STORE_FAILURE
    ],
    variables: {},
    dispatch,
    getState
  });
};
