import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';
import callAPI from "../../../utils/callAPI";

export const REQUEST_DISCOUNTS = 'REQUEST_DISCOUNTS';
export const REQUEST_DISCOUNTS_SUCCESS = 'REQUEST_DISCOUNTS_SUCCESS';
export const REQUEST_DISCOUNTS_FAILURE = 'REQUEST_DISCOUNTS_FAILURE';

export const REQUEST_DELETE_DISCOUNT = 'REQUEST_DELETE_DISCOUNT';
export const REQUEST_DELETE_DISCOUNT_SUCCESS = 'REQUEST_DELETE_DISCOUNT_SUCCESS';
export const REQUEST_DELETE_DISCOUNT_FAILURE = 'REQUEST_DELETE_DISCOUNT_FAILURE';

export const ACTION_CHANGE_DISCOUNTS_FILTER = 'ACTION_CHANGE_DISCOUNTS_FILTER';

const defaultState = {
    fetchingDiscounts: false,
    failedFetchingDiscounts: false,
    discounts: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_DISCOUNTS]: (state, action) => ({
        ...state,
        fetchingDiscounts: true,
        failedFetchingDiscounts: false,
    }),
    [ACTION_CHANGE_DISCOUNTS_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_DISCOUNTS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingDiscounts: false,
        failedFetchingDiscounts: false,
        discounts: get(action, 'payload.data')
    }),
    [REQUEST_DISCOUNTS_FAILURE]: (state, action) => ({
        ...state,
        fetchingDiscounts: false,
        failedFetchingDiscounts: true,
    })
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_DISCOUNTS_FILTER,
    payload
});

export default reducer;
export const namespace = 'discount';

export const getDiscounts = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/discounts',
        actionTypes: [REQUEST_DISCOUNTS, REQUEST_DISCOUNTS_SUCCESS, REQUEST_DISCOUNTS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
}

export const deleteDiscount = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/discounts/'+id,
        actionTypes: [REQUEST_DELETE_DISCOUNT, REQUEST_DELETE_DISCOUNT_SUCCESS, REQUEST_DELETE_DISCOUNT_FAILURE],
        variables: {},
        dispatch,
        getState
    });
}
