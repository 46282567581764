import { ROLE_OWNER } from "../../../utils/constants";

export const ROUTE_FORBIDDEN = 'route/ROUTE_FORBIDDEN';
export const ROUTE_AUTH = 'route/ROUTE_AUTH';
export const ROUTE_HOME = 'route/ROUTE_HOME';
export const ROUTE_LOGIN = 'route/ROUTE_LOGIN';
export const ROUTE_PERMISSION = 'route/ROUTE_PERMISSION';
export const ROUTE_ORDER = 'route/ROUTE_ORDER';
export const ROUTE_ORDER_DETAIL = 'route/ROUTE_ORDER_DETAIL';
export const ROUTE_COLLABORATOR = 'route/ROUTE_COLLABORATOR';
export const ROUTE_COLLABORATOR_DETAIL = 'route/ROUTE_COLLABORATOR_DETAIL';
export const ROUTE_CATEGORY = 'route/ROUTE_CATEGORY';
export const ROUTE_PRODUCT = 'route/ROUTE_PRODUCT';
export const ROUTE_CREATE_PRODUCT = 'route/ROUTE_CREATE_PRODUCT';
export const ROUTE_EDIT_PRODUCT = 'route/ROUTE_EDIT_PRODUCT';
export const ROUTE_CONTENT = 'route/ROUTE_CONTENT';
export const ROUTE_CONTENT_CATEGORY = 'route/ROUTE_CONTENT_CATEGORY';
export const ROUTE_CUSTOMER = 'route/ROUTE_CUSTOMER';
export const ROUTE_STORE = 'route/ROUTE_STORE';
export const ROUTE_WITHDRAWAL_ORDER = 'route/ROUTE_WITHDRAWAL_ORDER';
export const ROUTE_VOUCHER = 'route/ROUTE_VOUCHER';
export const ROUTE_VOUCHER_DETAIL = 'route/ROUTE_VOUCHER_DETAIL';
export const ROUTE_NOTIFICATION = 'route/ROUTE_NOTIFICATION';
export const ROUTE_NOTIFICATION_DETAIL = 'route/ROUTE_NOTIFICATION_DETAIL';
export const ROUTE_SLIDE_BANNER = 'route/ROUTE_SLIDE_BANNER';
export const ROUTE_CREATE_SLIDE_BANNER = 'route/ROUTE_CREATE_SLIDE_BANNER';
export const ROUTE_DISCOUNT = 'route/ROUTE_DISCOUNT';
export const ROUTE_CREATE_DISCOUNT = 'route/ROUTE_CREATE_DISCOUNT';
export const ROUTE_EDIT_DISCOUNT = 'route/ROUTE_EDIT_DISCOUNT';
export const ROUTE_TRACKING_UTM = 'route/ROUTE_TRACKING_UTM';
export const ROUTE_TRAINING_SCHEDULE = 'route/ROUTE_TRAINING_SCHEDULE';
export const ROUTE_MODERATOR = 'route/ROUTE_MODERATOR';
export const ROUTE_MODERATOR_DETAIL = 'route/ROUTE_MODERATOR_DETAIL';
export const ROUTE_AFFILIATE = 'route/ROUTE_AFFILIATE';
export const ROUTE_AFFILIATE_CREATE = 'route/ROUTE_AFFILIATE_CREATE';
export const ROUTE_AFFILIATE_DETAIL = 'route/ROUTE_AFFILIATE_DETAIL';
export const ROUTE_AFFILIATE_STATISTICAL = 'route/ROUTE_AFFILIATE_STATISTICAL';
export const ROUTE_USER_BONUS_DISCOUNTS = 'route/ROUTE_USER_BONUS_DISCOUNTS';
export const ROUTE_SALE_GROUPS = 'route/ROUTE_SALE_GROUPS';
export const ROUTE_PROGRAM_INFORMATION = 'route/ROUTE_PROGRAM_INFORMATION';
export const ROUTE_SALE_GROUP_INFORMATION = 'route/ROUTE_SALE_GROUP_INFORMATION';
export const ROUTE_SALE_GROUP_CONFIGURATION = 'route/ROUTE_SALE_GROUP_CONFIGURATION';
export const ROUTE_SALE_GROUP_DETAIL = 'route/ROUTE_SALE_GROUP_DETAIL';
export const ROUTE_QUESTION_ANSWER = 'route/ROUTE_QUESTION_ANSWER';
export const ROUTE_QUESTION_ANSWER_DETAIL = 'route/ROUTE_QUESTION_ANSWER_DETAIL';
export const ROUTE_POSTS = 'route/ROUTE_POSTS';
export const ROUTE_POST_DETAIL = 'route/ROUTE_POST_DETAIL';
export const ROUTE_CONFIGURATION = 'route/ROUTE_CONFIGURATION';
export const ROUTE_TRAINING_ACTIVITY = 'route/ROUTE_TRAINING_ACTIVITY';
export const ROUTE_TRAINING_ACTIVITY_FORM = 'route/ROUTE_TRAINING_ACTIVITY_FORM';
export const selectCurrentRoutePayload = state => state.location.payload;
export const selectCurrentPage = state => state.location.pathname;
export const selectRouteType = state => state.location.type;
export const selectRoutesMap = state => state.location.routesMap;
export const selectPreviousRoute = state => state.location.prev;

export const goToPage = (routeType, payload) => ({
    type: routeType,
    payload: payload
});

export const isForbidden = (currentRouteType, role) => {
  let result = false

  if (currentRouteType === ROUTE_PERMISSION && role !== ROLE_OWNER) {
      result = true
  }

  return result
}