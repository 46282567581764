import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_VOUCHER = 'REQUEST_VOUCHER';
export const REQUEST_VOUCHER_SUCCESS = 'REQUEST_VOUCHER_SUCCESS';
export const REQUEST_VOUCHER_FAILURE = 'REQUEST_VOUCHER_FAILURE';

export const REQUEST_CREATE_VOUCHER = 'REQUEST_CREATE_VOUCHER';
export const REQUEST_CREATE_VOUCHER_SUCCESS = 'REQUEST_CREATE_VOUCHER_SUCCESS';
export const REQUEST_CREATE_VOUCHER_FAILURE = 'REQUEST_CREATE_VOUCHER_FAILURE';

export const REQUEST_UPDATE_VOUCHER = 'REQUEST_UPDATE_VOUCHER';
export const REQUEST_UPDATE_VOUCHER_SUCCESS = 'REQUEST_UPDATE_VOUCHER_SUCCESS';
export const REQUEST_UPDATE_VOUCHER_FAILURE = 'REQUEST_UPDATE_VOUCHER_FAILURE';

const defaultState = {
    fetchingVouchers: false,
    failedFetchingVouchers: false,
    voucher: {
        results: [],
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_VOUCHER]: (state, action) => ({
        ...state,
        fetchingVoucher: true,
        failedFetchingVoucher: false,
    }),

    [REQUEST_VOUCHER_SUCCESS]: (state, action) => ({
        ...state,
        fetchingVoucher: false,
        failedFetchingVoucher: false,
        voucher: get(action, 'payload.data')
    }),

    [REQUEST_VOUCHER_FAILURE]: (state, action) => ({
        ...state,
        fetchingVoucher: false,
        failedFetchingVoucher: true,
    }),
});

export default reducer;
export const namespace = 'voucherDetail';

export const getVoucherById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/voucher/'+id,
        actionTypes: [REQUEST_VOUCHER, REQUEST_VOUCHER_SUCCESS, REQUEST_VOUCHER_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const createVoucher = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['action_type_for', 'name', 'code', 'type', 'type_for', 'save_type', 'value', 'min_required_value', 'time_used', 'period', 'expiration_date']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'post',
        apiPath: '/admin/voucher',
        actionTypes: [REQUEST_CREATE_VOUCHER, REQUEST_CREATE_VOUCHER_SUCCESS, REQUEST_CREATE_VOUCHER_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const updateVoucher = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['action_type_for', 'name', 'code', 'type', 'type_for', 'save_type', 'value', 'min_required_value', 'time_used', 'period', 'expiration_date']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'put',
        apiPath: '/admin/voucher/'+data.id,
        actionTypes: [REQUEST_UPDATE_VOUCHER, REQUEST_UPDATE_VOUCHER_SUCCESS, REQUEST_UPDATE_VOUCHER_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};
