import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_POSTS = 'REQUEST_POSTS';
export const REQUEST_POSTS_SUCCESS = 'REQUEST_POSTS_SUCCESS';
export const REQUEST_POSTS_FAILURE = 'REQUEST_POSTS_FAILURE';
export const ACTION_CHANGE_POST_FILTER = 'ACTION_CHANGE_POST_FILTER';

export const REQUEST_DELETE_POST = 'REQUEST_DELETE_POST';
export const REQUEST_DELETE_POST_SUCCESS = 'REQUEST_DELETE_POST_SUCCESS';
export const REQUEST_DELETE_POST_FAILURE = 'REQUEST_DELETE_POST_FAILURE';

const defaultState = {
  // fetchingUsers: false,
  // failedFetchingUsers: false,
  posts: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_POST_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),
    [REQUEST_POSTS_SUCCESS]: (state, action) => ({
        ...state,
        posts: get(action, 'payload.data')
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_POST_FILTER,
  payload
});

export default reducer;
export const namespace = 'posts';


export const getPosts = (params = {page: 1}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/posts',
    actionTypes: [REQUEST_POSTS, REQUEST_POSTS_SUCCESS, REQUEST_POSTS_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const deletePost = (postId) => async (dispatch, getState) => {
	return await callAPI({
		method: 'delete',
		apiPath: '/admin/posts/'+postId,
		actionTypes: [
			REQUEST_DELETE_POST,
			REQUEST_DELETE_POST_SUCCESS,
			REQUEST_DELETE_POST_FAILURE
		],
		variables: {
		},
		dispatch,
		getState
	});
};