import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_AFFILIATES_SUCCESS = 'REQUEST_AFFILIATES_SUCCESS';
export const ACTION_CHANGE_AFFILIATE_FILTER = 'ACTION_CHANGE_AFFILIATE_FILTER';

export const REQUEST_UPDATE_AFFILIATE = 'REQUEST_UPDATE_AFFILIATE';
export const REQUEST_UPDATE_AFFILIATE_SUCCESS = 'REQUEST_UPDATE_AFFILIATE_SUCCESS';
export const REQUEST_UPDATE_AFFILIATE_FAILURE = 'REQUEST_UPDATE_AFFILIATE_FAILURE';

export const REQUEST_CREATE_AFFILIATE = 'REQUEST_CREATE_AFFILIATE';
export const REQUEST_CREATE_AFFILIATE_SUCCESS = 'REQUEST_CREATE_AFFILIATE_SUCCESS';
export const REQUEST_CREATE_AFFILIATE_FAILURE = 'REQUEST_CREATE_AFFILIATE_FAILURE';

export const REQUEST_AFFILIATE_DETAIL         = 'REQUEST_AFFILIATE_DETAIL';
export const REQUEST_AFFILIATE_DETAIL_SUCCESS = 'REQUEST_AFFILIATE_DETAIL_SUCCESS';
export const REQUEST_AFFILIATE_DETAIL_FAILURE = 'REQUEST_AFFILIATE_DETAIL_FAILURE';

const defaultState = {
  // fetchingUsers: false,
  // failedFetchingUsers: false,
  affiliates: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  affiliate: {},
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_AFFILIATE_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),
    [REQUEST_AFFILIATES_SUCCESS]: (state, action) => ({
        ...state,
        affiliates: get(action, 'payload.data')
    }),
    [REQUEST_AFFILIATE_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        affiliate: get(action, 'payload.data')
    })
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_AFFILIATE_FILTER,
  payload
});

export default reducer;
export const namespace = 'affiliateForm';

export const updateAffiliate = (id, data)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/admin/affiliate-programs/'+id,
    actionTypes: [REQUEST_UPDATE_AFFILIATE, REQUEST_UPDATE_AFFILIATE_SUCCESS, REQUEST_UPDATE_AFFILIATE_FAILURE],
    variables: data,
    dispatch,
    getState
  });
};

export const createAffiliate = (data)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/affiliate-programs',
    actionTypes: [REQUEST_CREATE_AFFILIATE, REQUEST_CREATE_AFFILIATE_SUCCESS, REQUEST_CREATE_AFFILIATE_FAILURE],
    variables: data,
    dispatch,
    getState
  });
};

export const getAffiliateById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/affiliate-programs/'+id,
        actionTypes: [REQUEST_AFFILIATE_DETAIL, REQUEST_AFFILIATE_DETAIL_SUCCESS, REQUEST_AFFILIATE_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};