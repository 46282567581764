import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_AFFILIATES = 'REQUEST_AFFILIATES';
export const REQUEST_AFFILIATES_SUCCESS = 'REQUEST_AFFILIATES_SUCCESS';
export const REQUEST_AFFILIATES_FAILURE = 'REQUEST_AFFILIATES_FAILURE';
export const ACTION_CHANGE_AFFILIATE_FILTER = 'ACTION_CHANGE_AFFILIATE_FILTER';

export const REQUEST_DELETE_AFFILIATE = 'REQUEST_DELETE_AFFILIATE';
export const REQUEST_DELETE_AFFILIATE_SUCCESS = 'REQUEST_DELETE_AFFILIATE_SUCCESS';
export const REQUEST_DELETE_AFFILIATE_FAILURE = 'REQUEST_DELETE_AFFILIATE_FAILURE';

export const REQUEST_COPY_AFFILIATE         = 'REQUEST_COPY_AFFILIATE';
export const REQUEST_COPY_AFFILIATE_SUCCESS = 'REQUEST_COPY_AFFILIATE_SUCCESS';
export const REQUEST_COPY_AFFILIATE_FAILURE = 'REQUEST_COPY_AFFILIATE_FAILURE';

const defaultState = {
  fetchingAffiliates: false,
  failedFetchingAffiliates: false,
  affiliates: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_AFFILIATE_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),
    [REQUEST_AFFILIATES_SUCCESS]: (state, action) => ({
        ...state,
        affiliates: get(action, 'payload.data')
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_AFFILIATE_FILTER,
  payload
});

export default reducer;
export const namespace = 'affiliates';


export const getAffiliates = (params) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/affiliate-programs',
    actionTypes: [REQUEST_AFFILIATES, REQUEST_AFFILIATES_SUCCESS, REQUEST_AFFILIATES_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const deleteAffiliate = (id) => async (dispatch, getState) => {
	return await callAPI({
		method: 'delete',
		apiPath: '/admin/affiliate-programs/'+id,
		actionTypes: [
			REQUEST_DELETE_AFFILIATE,
			REQUEST_DELETE_AFFILIATE_SUCCESS,
			REQUEST_DELETE_AFFILIATE_FAILURE
		],
		variables: {
		},
		dispatch,
		getState
	});
};

export const copyAffiliate = (id) => async (dispatch, getState) => {
	return await callAPI({
		method: 'post',
		apiPath: '/admin/affiliate-programs/copy'+id,
		actionTypes: [
			REQUEST_COPY_AFFILIATE,
			REQUEST_COPY_AFFILIATE_SUCCESS,
			REQUEST_COPY_AFFILIATE_FAILURE
		],
		variables: {
		},
		dispatch,
		getState
	});
};