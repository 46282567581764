import {
    all,
    put,
    fork, select, takeLatest,
} from 'redux-saga/effects';

import {
    REQUEST_CREATE_NOTIFICATION_FAILURE,
    REQUEST_CREATE_NOTIFICATION_SUCCESS,
    REQUEST_UPDATE_NOTIFICATION_FAILURE,
    REQUEST_UPDATE_NOTIFICATION_SUCCESS,
    getNotificationById
} from './index';
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_NOTIFICATION} from "../routing";
import {ACTION_CHANGE_COLLABORATORS_FILTER, getCollaborators} from "../collaborators";

function* loadRouteData() {
    yield put(getCollaborators());
    const {location} = yield select();
    if (location.payload.id && location.payload.id !== "create") {
        yield put(getNotificationById(location.payload.id));
    }
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_COLLABORATORS_FILTER
    ], function* (action) {
        const {collaborators} = yield select();
        const {
            filter
        } = collaborators;
        yield put(getCollaborators(filter))
    });
}

function* handleCreateNotificationSuccess() {
    yield takeLatest(REQUEST_CREATE_NOTIFICATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo mới thông báo thành công'));
        yield put(redirect({
            type: ROUTE_NOTIFICATION,
            query: ROUTE_NOTIFICATION
        }));
    });
}

function* handleNotificationFailure() {
    yield takeLatest(REQUEST_CREATE_NOTIFICATION_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.body[0].message, 'error'));
    });
}

function* handleUpdateNotificationSuccess() {
    yield takeLatest(REQUEST_UPDATE_NOTIFICATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật thông báo thành công'));
        yield put(redirect({
            type: ROUTE_NOTIFICATION,
            query: ROUTE_NOTIFICATION
        }));
    });
}

function* handleUpdateProductFailure() {
    yield takeLatest(REQUEST_UPDATE_NOTIFICATION_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật thông báo thất bại', 'error'));
    });
}

export function* loadNotificationDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleCreateNotificationSuccess),
        fork(handleNotificationFailure),
        fork(handleUpdateNotificationSuccess),
        fork(handleUpdateProductFailure),
    ]);
}
