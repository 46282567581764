import {
    all,
    put,
    fork,
    select,
    takeLatest
} from 'redux-saga/effects';

import {
    getCollaboratorById,
    getCommissionById,
    ACTION_CHANGE_COMMISSION_FILTER
} from './index'
import {getModerators} from "../moderator";
import {
    REQUEST_CHANGE_ADMINISTRATOR_FAILURE,
    REQUEST_CHANGE_ADMINISTRATOR_SUCCESS, REQUEST_UPDATE_COLLABORATOR_FAILURE,
    REQUEST_UPDATE_COLLABORATOR_SUCCESS
} from "../collaborators";
import {showToastAlert} from "../app";
import {getTrainingActivity} from "../trainingActivity";

function* loadRouteData() {
    const {location} = yield select();
    yield put(getModerators({ page: 1, limit: 100 }));
    yield put(getCollaboratorById(location.payload.id));
    yield put(getCommissionById(location.payload.id));
    yield put(getTrainingActivity())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_COMMISSION_FILTER,
    ], function* (action) {
        const {collaboratorDetail, location} = yield select();
        const {
            filter
        } = collaboratorDetail;
        yield put(getCommissionById(location.payload.id, filter))
    });
}

function* handleChangeAdministratorSuccess() {
    yield takeLatest(REQUEST_CHANGE_ADMINISTRATOR_SUCCESS, function* (action) {
        yield put(showToastAlert(action.payload.message));
        const {location} = yield select();
        yield put(getCollaboratorById(location.payload.id));
    });
}

function* handleChangeAdministratorFailure() {
    yield takeLatest(REQUEST_CHANGE_ADMINISTRATOR_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.message, 'error'))
    });
}

function* handleUpdateCollaboratorSuccess() {
    yield takeLatest(REQUEST_UPDATE_COLLABORATOR_SUCCESS, function* (action) {
        yield put(showToastAlert(action.payload.message));
        const {location} = yield select();
        yield put(getCollaboratorById(location.payload.id));
    });
}

function* handleUpdateCollaboratorFailure() {
    yield takeLatest(REQUEST_UPDATE_COLLABORATOR_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.message, 'error'))
    });
}

export function* loadCollaboratorDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleChangeAdministratorSuccess),
        fork(handleChangeAdministratorFailure),
        fork(handleUpdateCollaboratorSuccess),
        fork(handleUpdateCollaboratorFailure),
    ]);
}