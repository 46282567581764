import React from 'react';
import RootRoute from '../../RootRoute';
// todo: add global stuff -- snackbar/modal targets, intercom button, ...
import moment from 'moment';
import 'moment/locale/vi';
moment.locale('vi');
const App = () => (
  <RootRoute />
);

export default App;
