import {
    all,
    fork,
    put,
    select,
    takeLatest
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_AFFILIATE_STATS_FILTER,
    getAffiliateStats, REQUEST_EXPORT_AFFILIATE_STATS_SUCCESS
} from "./index";
import {getAffiliateById} from "../affiliateForm";
import {showToastAlert} from "../app";

function* loadRouteData() {
    const {location, affiliateStats} = yield select();
    const {
        filter
    } = affiliateStats;
    if (location.payload.id) {
        yield put(getAffiliateStats(location.payload.id, filter));
        yield put(getAffiliateById(location.payload.id));
    }
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_AFFILIATE_STATS_FILTER
    ], function* (action) {
        const {affiliateStats, location} = yield select();
        const {
            filter
        } = affiliateStats;
        yield put(getAffiliateStats(location.payload.id, filter))
    });
}

function* handleExportAffiliateStatsSuccess() {
    yield takeLatest(REQUEST_EXPORT_AFFILIATE_STATS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thành công'))
        const link = document.createElement('a');
        link.href = action.payload.data.url;
        link.setAttribute(
            'download',
            action.payload.data.file_name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}


export function* loadAffiliateStatsPage() {
    yield all([
        fork(handleListenActions),
        fork(loadRouteData),
        fork(handleExportAffiliateStatsSuccess)
    ]);
}
    