import {
    all,
    put,
    fork,
    select,
    takeLatest
  } from 'redux-saga/effects';
  
  import {
    getCustomers,
    ACTION_CHANGE_CUSTOMER_FILTER
  } from './index';
  
  function* loadRouteData() {
      yield put(getCustomers())
    }
  
  function* handleListenActions() {
      yield takeLatest([
        ACTION_CHANGE_CUSTOMER_FILTER
      ], function* (action) {
        const {customers} = yield select();
        const {
            filter
        } = customers;
          console.log("filter", filter)
      yield put(getCustomers(filter))
      });
  }
  
  export function* loadCustomerPage() {
    yield all([
      fork(handleListenActions),
      fork(loadRouteData)
    ]);
  }
    