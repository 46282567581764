import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_USER_BONUS_DISCOUNTS = 'REQUEST_USER_BONUS_DISCOUNTS';
export const REQUEST_USER_BONUS_DISCOUNTS_SUCCESS = 'REQUEST_USER_BONUS_DISCOUNTS_SUCCESS';
export const REQUEST_USER_BONUS_DISCOUNTS_FAILURE = 'REQUEST_USER_BONUS_DISCOUNTS_FAILURE';
export const ACTION_CHANGE_USER_BONUS_DISCOUNT_FILTER = 'ACTION_CHANGE_USER_BONUS_DISCOUNT_FILTER';

export const REQUEST_EXPORT_USER_BONUS_DISCOUNTS = 'REQUEST_EXPORT_USER_BONUS_DISCOUNTS';
export const REQUEST_EXPORT_USER_BONUS_DISCOUNTS_SUCCESS = 'REQUEST_EXPORT_USER_BONUS_DISCOUNTS_SUCCESS';
export const REQUEST_EXPORT_USER_BONUS_DISCOUNTS_FAILURE = 'REQUEST_EXPORT_USER_BONUS_DISCOUNTS_FAILURE';

export const REQUEST_CALCULATE_USER_BONUS_DISCOUNTS = 'REQUEST_CALCULATE_USER_BONUS_DISCOUNTS';
export const REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_SUCCESS = 'REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_SUCCESS';
export const REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_FAILURE = 'REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_FAILURE';

const defaultState = {
  fetchingUserBonusDiscounts: false,
  failedFetchingUserBonusDiscounts: false,
  user_bonus_discounts: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0
  },
  filter: {
    search_key: "",
    page: 1,
    date: ''
  },
  errors: [],
  loading: false
};

const reducer = createReducer(defaultState, {
  [REQUEST_USER_BONUS_DISCOUNTS]: (state, action) => ({
    ...state,
    fetchingUserBonusDiscounts: true,
    failedFetchingUserBonusDiscounts: false,
  }),
  [ACTION_CHANGE_USER_BONUS_DISCOUNT_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
  }),
  [REQUEST_USER_BONUS_DISCOUNTS_SUCCESS]: (state, action) => ({
    ...state,
    fetchingUserBonusDiscounts: false,
    failedFetchingUserBonusDiscounts: false,
    user_bonus_discounts: get(action, 'payload.data')
  }),
  [REQUEST_USER_BONUS_DISCOUNTS_FAILURE]: (state, action) => ({
    ...state,
    fetchingUserBonusDiscounts: false,
    failedFetchingUserBonusDiscounts: true,
  })
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_USER_BONUS_DISCOUNT_FILTER,
  payload
});

export default reducer;
export const namespace = 'user_bonus_discounts';

export const getUserBonusDiscounts = (params = { page: 1 }) => async (dispatch, getState) => {
  if (params.date && 'string' !== typeof params.date) {
    params.date = params.date.format("YYYY-MM");
  }
  return await callAPI({
    method: 'get',
    apiPath: '/admin/user-bonus-discounts',
    actionTypes: [REQUEST_USER_BONUS_DISCOUNTS, REQUEST_USER_BONUS_DISCOUNTS_SUCCESS, REQUEST_USER_BONUS_DISCOUNTS_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const exportUserBonusDiscounts = (params) => async (dispatch, getState) => {
  if (params.date) {
    params.date = params.date.format("YYYY-MM");
  }
  return await callAPI({
    method: 'post',
    apiPath: `/admin/user-bonus-discounts/export`,
    actionTypes: [REQUEST_EXPORT_USER_BONUS_DISCOUNTS, REQUEST_EXPORT_USER_BONUS_DISCOUNTS_SUCCESS, REQUEST_EXPORT_USER_BONUS_DISCOUNTS_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};

export const calculateUserBonusDiscounts = (params) => async (dispatch, getState) => {
  if (params.date) {
    params.date = params.date.format("YYYY-MM");
  }
  return await callAPI({
    method: 'post',
    apiPath: `/admin/user-bonus-discounts`,
    actionTypes: [REQUEST_CALCULATE_USER_BONUS_DISCOUNTS, REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_SUCCESS, REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_FAILURE],
    variables: { ...params },
    dispatch,
    getState
  });
};