import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_QUESTION_ANSWERS = 'REQUEST_QUESTION_ANSWERS';
export const REQUEST_QUESTION_ANSWERS_SUCCESS = 'REQUEST_QUESTION_ANSWERS_SUCCESS';
export const REQUEST_QUESTION_ANSWERS_FAILURE = 'REQUEST_QUESTION_ANSWERS_FAILURE';

export const REQUEST_DELETE_QUESTION_ANSWER = 'REQUEST_DELETE_QUESTION_ANSWER';
export const REQUEST_DELETE_QUESTION_ANSWER_SUCCESS = 'REQUEST_DELETE_QUESTION_ANSWER_SUCCESS';
export const REQUEST_DELETE_QUESTION_ANSWER_FAILURE = 'REQUEST_DELETE_QUESTION_ANSWER_FAILURE';

export const ACTION_CHANGE_QUESTION_ANSWER_FILTER = 'ACTION_CHANGE_QUESTION_ANSWER_FILTER';

const defaultState = {
    fetchingQuestionAnswer: false,
    failedFetchingQuestionAnswer: false,
    questionAnswers: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_QUESTION_ANSWERS]: (state, action) => ({
        ...state,
        fetchingQuestionAnswer: true,
        failedFetchingQuestionAnswer: false,
    }),
    [ACTION_CHANGE_QUESTION_ANSWER_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_QUESTION_ANSWERS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingQuestionAnswer: false,
        failedFetchingQuestionAnswer: false,
        questionAnswers: get(action, 'payload.data')
    }),
    [REQUEST_QUESTION_ANSWERS_FAILURE]: (state, action) => ({
        ...state,
        fetchingQuestionAnswer: false,
        failedFetchingQuestionAnswer: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_QUESTION_ANSWER_FILTER,
    payload
});

export default reducer;
export const namespace = 'questionAnswers';

export const getQuestionAnswers = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/question-answer',
        actionTypes: [REQUEST_QUESTION_ANSWERS, REQUEST_QUESTION_ANSWERS_SUCCESS, REQUEST_QUESTION_ANSWERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const deleteQuestionAnswer = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/question-answer/'+id,
        actionTypes: [
            REQUEST_DELETE_QUESTION_ANSWER,
            REQUEST_DELETE_QUESTION_ANSWER_SUCCESS,
            REQUEST_DELETE_QUESTION_ANSWER_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};
