import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_CONFIGURATION = 'REQUEST_CONFIGURATION';
export const REQUEST_CONFIGURATION_SUCCESS = 'REQUEST_CONFIGURATION_SUCCESS';
export const REQUEST_CONFIGURATION_FAILURE = 'REQUEST_CONFIGURATION_FAILURE';

export const REQUEST_SAVE_CONFIGURATION = 'REQUEST_SAVE_CONFIGURATION';
export const REQUEST_SAVE_CONFIGURATION_SUCCESS = 'REQUEST_SAVE_CONFIGURATION_SUCCESS';
export const REQUEST_SAVE_CONFIGURATION_FAILURE = 'REQUEST_SAVE_CONFIGURATION_FAILURE';

export const REQUEST_DELETE_CONFIGURATION = 'REQUEST_DELETE_CONFIGURATION';
export const REQUEST_DELETE_CONFIGURATION_SUCCESS = 'REQUEST_DELETE_CONFIGURATION_SUCCESS';
export const REQUEST_DELETE_CONFIGURATION_FAILURE = 'REQUEST_DELETE_CONFIGURATION_FAILURE';

export const REQUEST_UPDATE_CONFIGURATION = 'REQUEST_UPDATE_CONFIGURATION';
export const REQUEST_UPDATE_CONFIGURATION_SUCCESS = 'REQUEST_UPDATE_CONFIGURATION_SUCCESS';
export const REQUEST_UPDATE_CONFIGURATION_FAILURE = 'REQUEST_UPDATE_CONFIGURATION_FAILURE';

export const ACTION_CHANGE_CONFIGURATION_FILTER = 'ACTION_CHANGE_CONFIGURATION_FILTER';

const defaultState = {
    fetchingConfiguration: false,
    failedFetchingConfiguration: false,
    configurations: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0
    },
    filter: {
        search_key: "",
        page: 1,
    }
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CONFIGURATION_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),

    [REQUEST_CONFIGURATION]: (state, action) => ({
        ...state,
        fetchingConfiguration: true,
        failedFetchingConfiguration: false,
    }),

    [REQUEST_CONFIGURATION_SUCCESS]: (state, action) => ({
        ...state,
        fetchingConfiguration: false,
        failedFetchingConfiguration: false,
        configurations: get(action, 'payload.data')
    }),

    [REQUEST_CONFIGURATION_FAILURE]: (state, action) => ({
        ...state,
        fetchingConfiguration: false,
        failedFetchingConfiguration: true,
        configurations: {
            results: [],
            current_page: 1,
            last_page: 1,
            per_page: 20,
            from: 0,
            to: 0,
            total: 0
        }
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_CONFIGURATION_FILTER,
    payload
});

export default reducer;
export const namespace = 'configuration';

export const getConfigurations = (params = {page: 1}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/configuration',
    actionTypes: [REQUEST_CONFIGURATION, REQUEST_CONFIGURATION_SUCCESS, REQUEST_CONFIGURATION_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const postConfiguration = (params) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/configuration',
    actionTypes: [REQUEST_SAVE_CONFIGURATION, REQUEST_SAVE_CONFIGURATION_SUCCESS, REQUEST_SAVE_CONFIGURATION_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const updateConfiguration = (data) => async (dispatch, getState) => {
    const variables = Object.fromEntries(
        ['key', 'value', 'description']
            .filter(key => key in data)
            .map(key => [key, data[key]])
    );
    return await callAPI({
        method: 'put',
        apiPath: '/admin/configuration/' + data._id,
        actionTypes: [REQUEST_UPDATE_CONFIGURATION, REQUEST_UPDATE_CONFIGURATION_SUCCESS, REQUEST_UPDATE_CONFIGURATION_FAILURE],
        variables: variables,
        dispatch,
        getState
    });
};

export const deleteConfiguration = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'delete',
    apiPath: '/admin/configuration/' + id,
    actionTypes: [REQUEST_DELETE_CONFIGURATION, REQUEST_DELETE_CONFIGURATION_SUCCESS, REQUEST_DELETE_CONFIGURATION_FAILURE],
    variables: {},
    dispatch,
    getState
  });
};
