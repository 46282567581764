import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';
export const REQUEST_POST_DETAIL         = 'REQUEST_POST_DETAIL';
export const REQUEST_POST_DETAIL_SUCCESS = 'REQUEST_POST_DETAIL_SUCCESS';
export const REQUEST_POST_DETAIL_FAILURE = 'REQUEST_POST_DETAIL_FAILURE';

export const REQUEST_UPDATE_POST = 'REQUEST_UPDATE_POST';
export const REQUEST_UPDATE_POST_SUCCESS = 'REQUEST_UPDATE_POST_SUCCESS';
export const REQUEST_UPDATE_POST_FAILURE = 'REQUEST_UPDATE_POST_FAILURE';

export const REQUEST_CREATE_POST = 'REQUEST_CREATE_POST';
export const REQUEST_CREATE_POST_SUCCESS = 'REQUEST_CREATE_POST_SUCCESS';
export const REQUEST_CREATE_POST_FAILURE = 'REQUEST_CREATE_POST_FAILURE';

const defaultState = {
  // fetchingUsers: false,
  // failedFetchingUsers: false,
  postDetail: {},
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_POST_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        postDetail: get(action, 'payload.data')
    }),
});

export default reducer;
export const namespace = 'postDetail';

export const getPost = (id) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/posts/'+id,
    actionTypes: [REQUEST_POST_DETAIL, REQUEST_POST_DETAIL_SUCCESS, REQUEST_POST_DETAIL_FAILURE],
    variables: {},
    dispatch,
    getState
  });
};

export const updatePost = (data)=> async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/posts/'+data.id,
        actionTypes: [REQUEST_UPDATE_POST, REQUEST_UPDATE_POST_SUCCESS, REQUEST_UPDATE_POST_FAILURE],
        variables: {
            title: data.title,
            content: data.content,
            images: data.images,
            type: data.type,
            product: data.product,
        },
        dispatch,
        getState
    });
};

export const createPost = (data)=> async (dispatch, getState) => {
    console.log("data", data)
    return await callAPI({
        method: 'post',
        apiPath: '/admin/posts',
        actionTypes: [REQUEST_CREATE_POST, REQUEST_CREATE_POST_SUCCESS, REQUEST_CREATE_POST_FAILURE],
        variables: {
            title: data.title,
            content: data.content,
            images: data.images,
            type: data.type,
            product: data.product,
        },
        dispatch,
        getState
    });
};