import {
    all,
    takeLatest,
    put
} from 'redux-saga/effects';

import {showToastAlert} from "../app";
import {
    REQUEST_CREATE_TRAINING_SCHEDULE_SUCCESS,
    REQUEST_DELETE_TRAINING_SCHEDULE_SUCCESS,
    getTrainingSchedule} from "./index";

function* loadRouteData() {
    yield put(getTrainingSchedule())
}

function* handleCreateTrainingScheduleSuccess() {
    yield takeLatest(REQUEST_CREATE_TRAINING_SCHEDULE_SUCCESS, function* (action) {
        yield put(showToastAlert('tạo mới thành công'))
        yield put(getTrainingSchedule())
    });
}

function* handleDeleteTrainingScheduleSuccess() {
    yield takeLatest(REQUEST_DELETE_TRAINING_SCHEDULE_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa thành công'))
        yield put(getTrainingSchedule())
    });
}

export function* loadTrainingSchedulePage() {
    yield all([
        loadRouteData(),
        handleCreateTrainingScheduleSuccess(),
        handleDeleteTrainingScheduleSuccess()
    ]);
}
