import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_QUESTION_ANSWER = 'REQUEST_QUESTION_ANSWER';
export const REQUEST_QUESTION_ANSWER_SUCCESS = 'REQUEST_QUESTION_ANSWER_SUCCESS';
export const REQUEST_QUESTION_ANSWER_FAILURE = 'REQUEST_QUESTION_ANSWER_FAILURE';

export const REQUEST_CREATE_QUESTION_ANSWER = 'REQUEST_CREATE_QUESTION_ANSWER';
export const REQUEST_CREATE_QUESTION_ANSWER_SUCCESS = 'REQUEST_CREATE_QUESTION_ANSWER_SUCCESS';
export const REQUEST_CREATE_QUESTION_ANSWER_FAILURE = 'REQUEST_CREATE_QUESTION_ANSWER_FAILURE';



export const REQUEST_UPDATE_QUESTION_ANSWER = 'REQUEST_UPDATE_QUESTION_ANSWER';
export const REQUEST_UPDATE_QUESTION_ANSWER_SUCCESS = 'REQUEST_UPDATE_QUESTION_ANSWER_SUCCESS';
export const REQUEST_UPDATE_QUESTION_ANSWER_FAILURE = 'REQUEST_UPDATE_QUESTION_ANSWER_FAILURE';

const defaultState = {
    fetchingQuestionAnswerDetail: false,
    failedFetchingQuestionAnswerDetail: false,
    questionAnswerDetail: {},
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_QUESTION_ANSWER]: (state, action) => ({
        ...state,
        fetchingQuestionAnswerDetail: true,
        failedFetchingQuestionAnswerDetail: false,
    }),
    [REQUEST_QUESTION_ANSWER_SUCCESS]: (state, action) => ({
        ...state,
        fetchingQuestionAnswerDetail: false,
        failedFetchingQuestionAnswerDetail: false,
        questionAnswerDetail: get(action, 'payload.data')
    }),
    [REQUEST_QUESTION_ANSWER_FAILURE]: (state, action) => ({
        ...state,
        fetchingQuestionAnswerDetail: false,
        failedFetchingQuestionAnswerDetail: true,
    }),
});

export default reducer;
export const namespace = 'questionAnswerDetail';

export const createQuestionAnswer = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/question-answer',
        actionTypes: [REQUEST_CREATE_QUESTION_ANSWER, REQUEST_CREATE_QUESTION_ANSWER_SUCCESS, REQUEST_CREATE_QUESTION_ANSWER_FAILURE],
        variables: {...data},
        dispatch,
        getState
    });
};

export const updateQuestionAnswer = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/question-answer/'+data.id,
        actionTypes: [
            REQUEST_UPDATE_QUESTION_ANSWER,
            REQUEST_UPDATE_QUESTION_ANSWER_SUCCESS,
            REQUEST_UPDATE_QUESTION_ANSWER_FAILURE
        ],
        variables: {
            ...data
        },
        dispatch,
        getState
    });
};

export const getQuestionAnswerById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/question-answer/'+id,
        actionTypes: [REQUEST_QUESTION_ANSWER, REQUEST_QUESTION_ANSWER_SUCCESS, REQUEST_QUESTION_ANSWER_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};
