import {
    all,
    fork,
    put,
    takeLatest,
    select,
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_DISCOUNTS_FILTER,
    getDiscounts,
    REQUEST_DELETE_DISCOUNT_FAILURE,
    REQUEST_DELETE_DISCOUNT_SUCCESS
} from "./index";
import {showToastAlert} from "../app";
import {getProducts} from "../product";

function* loadRouteData() {
    yield put(getDiscounts());
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_DISCOUNTS_FILTER
    ], function* (action) {
        const {discount} = yield select();
        const {
            filter
        } = discount;
        yield put(getDiscounts(filter))
    });
}

function* handleDeleteDiscountFailure() {
    yield takeLatest(REQUEST_DELETE_DISCOUNT_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa chương trình thất bại', 'error'));
        yield put(getProducts());
    });
}

function* handleDeleteDiscountSuccess() {
    yield takeLatest(REQUEST_DELETE_DISCOUNT_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa chương trình thành công'));
        yield put(getDiscounts());
    });
}

export function* loadDiscountPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleDeleteDiscountSuccess),
        fork(handleDeleteDiscountFailure),
    ]);
}
