import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';
import callAPI from "../../../utils/callAPI";

export const REQUEST_TRACKING_UTM = 'REQUEST_TRACKING_UTM';
export const REQUEST_TRACKING_UTM_SUCCESS = 'REQUEST_TRACKING_UTM_SUCCESS';
export const REQUEST_TRACKING_UTM_FAILURE = 'REQUEST_TRACKING_UTM_FAILURE';

export const ACTION_CHANGE_TRACKING_UTM_FILTER = 'ACTION_CHANGE_TRACKING_UTM_FILTER';

const defaultState = {
    fetchingTrackingUtm: false,
    failedFetchingTrackingUtm: false,
    trackingUtm: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_TRACKING_UTM]: (state, action) => ({
        ...state,
        fetchingTrackingUtm: true,
        failedFetchingTrackingUtm: false,
    }),
    [ACTION_CHANGE_TRACKING_UTM_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_TRACKING_UTM_SUCCESS]: (state, action) => ({
        ...state,
        fetchingTrackingUtm: false,
        failedFetchingTrackingUtm: false,
        trackingUtm: get(action, 'payload.data')
    }),
    [REQUEST_TRACKING_UTM_FAILURE]: (state, action) => ({
        ...state,
        fetchingTrackingUtm: false,
        failedFetchingTrackingUtm: true,
    })
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_TRACKING_UTM_FILTER,
    payload
});

export default reducer;
export const namespace = 'trackingUtm';

export const getTrackingUtm = (params = {page: 1}) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
    return await callAPI({
        method: 'get',
        apiPath: '/admin/tracking-utm',
        actionTypes: [REQUEST_TRACKING_UTM, REQUEST_TRACKING_UTM_SUCCESS, REQUEST_TRACKING_UTM_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
}
