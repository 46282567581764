import {
    all,
    put,
    fork,
    select,
    takeLatest
  } from 'redux-saga/effects';
  
  import {
    getContentCategories,
    ACTION_CHANGE_CONTENT_CATEGORY_FILTER,
    REQUEST_DELETE_CONTENT_CATEGORY_SUCCESS,
    REQUEST_DELETE_CONTENT_CATEGORY_FAILURE,
    REQUEST_UPDATE_CONTENT_CATEGORY_SUCCESS,
    REQUEST_UPDATE_CONTENT_CATEGORY_FAILURE,
    REQUEST_CREATE_CONTENT_CATEGORY_SUCCESS,
    REQUEST_CREATE_CONTENT_CATEGORY_FAILURE
  } from './index'
  import { showToastAlert } from "../app";
  // import _ from "lodash";
  
  function* loadRouteData() {
      yield put(getContentCategories())
    }
  
  function* handleListenActions() {
      yield takeLatest([
        ACTION_CHANGE_CONTENT_CATEGORY_FILTER
      ], function* (action) {
        const {contentCategory} = yield select();
        const {
            filter
        } = contentCategory;
      yield put(getContentCategories(filter))
      });
  }
  
  function* handleDeleteContentCategorySuccess() {
      yield takeLatest(REQUEST_DELETE_CONTENT_CATEGORY_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa danh mục thành công'))
        yield put(getContentCategories())
      });
  }
  
  function* handleDeleteContentCategoryFailure() {
      yield takeLatest(REQUEST_DELETE_CONTENT_CATEGORY_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa danh mục thất bại', 'error'))
        yield put(getContentCategories())
      });
  }
  
  function* handleUpdateContentCategorySuccess() {
      yield takeLatest(REQUEST_UPDATE_CONTENT_CATEGORY_SUCCESS, function* (action) {
        yield put(showToastAlert('cập nhật danh mục thành công'))
        yield put(getContentCategories())
      });
  }
  
  function* handleUpdateContentCategoryFailure() {
      yield takeLatest(REQUEST_UPDATE_CONTENT_CATEGORY_FAILURE, function* (action) {
        yield put(showToastAlert('cập nhật danh mục thất bại', 'error'))
        yield put(getContentCategories())
      });
  }

  function* handleCreateContentCategorySuccess() {
    yield takeLatest(REQUEST_CREATE_CONTENT_CATEGORY_SUCCESS, function* (action) {
      yield put(showToastAlert('tạo mới danh mục thành công'))
      yield put(getContentCategories())
    });
}

  function* handleCreateContentFailure() {
      yield takeLatest(REQUEST_CREATE_CONTENT_CATEGORY_FAILURE, function* (action) {
        yield put(showToastAlert('tạo mới danh mục thất bại', 'error'))
        yield put(getContentCategories())
      });
  }
  
  export function* loadContentCategoryPage() {
    yield all([
      fork(handleListenActions),
      fork(loadRouteData),
      fork(handleDeleteContentCategorySuccess),
      fork(handleDeleteContentCategoryFailure),
      fork(handleUpdateContentCategorySuccess),
      fork(handleUpdateContentCategoryFailure),
      fork(handleCreateContentCategorySuccess),
      fork(handleCreateContentFailure)
    ]);
  }
    