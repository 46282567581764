import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_TRAINING_ACTIVITY = 'REQUEST_TRAINING_ACTIVITY';
export const REQUEST_TRAINING_ACTIVITY_SUCCESS = 'REQUEST_TRAINING_ACTIVITY_SUCCESS';
export const REQUEST_TRAINING_ACTIVITY_FAILURE = 'REQUEST_TRAINING_ACTIVITY_FAILURE';

export const REQUEST_TRAINING_ACTIVITY_BY_ID         = 'REQUEST_TRAINING_ACTIVITY_BY_ID';
export const REQUEST_TRAINING_ACTIVITY_BY_ID_SUCCESS = 'REQUEST_TRAINING_ACTIVITY_BY_ID_SUCCESS';
export const REQUEST_TRAINING_ACTIVITY_BY_ID_FAILURE = 'REQUEST_TRAINING_ACTIVITY_BY_ID_FAILURE';

export const REQUEST_DELETE_TRAINING_ACTIVITY = 'REQUEST_DELETE_TRAINING_ACTIVITY';
export const REQUEST_DELETE_TRAINING_ACTIVITY_SUCCESS = 'REQUEST_DELETE_TRAINING_ACTIVITY_SUCCESS';
export const REQUEST_DELETE_TRAINING_ACTIVITY_FAILURE = 'REQUEST_DELETE_TRAINING_ACTIVITY_FAILURE';

export const REQUEST_CREATE_TRAINING_ACTIVITY         = 'REQUEST_CREATE_TRAINING_ACTIVITY';
export const REQUEST_CREATE_TRAINING_ACTIVITY_SUCCESS = 'REQUEST_CREATE_TRAINING_ACTIVITY_SUCCESS';
export const REQUEST_CREATE_TRAINING_ACTIVITY_FAILURE = 'REQUEST_CREATE_TRAINING_ACTIVITY_FAILURE';

export const REQUEST_UPDATE_TRAINING_ACTIVITY         = 'REQUEST_UPDATE_TRAINING_ACTIVITY';
export const REQUEST_UPDATE_TRAINING_ACTIVITY_SUCCESS = 'REQUEST_UPDATE_TRAINING_ACTIVITY_SUCCESS';
export const REQUEST_UPDATE_TRAINING_ACTIVITY_FAILURE = 'REQUEST_UPDATE_TRAINING_ACTIVITY_FAILURE';

export const ACTION_CHANGE_TRAINING_ACTIVITY_FILTER = 'ACTION_CHANGE_TRAINING_ACTIVITY_FILTER';

const defaultState = {
    fetchingTrainingActivity: false,
    failedFetchingTrainingActivity: false,
    trainingActivity: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    trainingActivityDetail: {},
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_TRAINING_ACTIVITY]: (state, action) => ({
        ...state,
        fetchingTrainingActivity: true,
        failedFetchingTrainingActivity: false,
    }),
    [ACTION_CHANGE_TRAINING_ACTIVITY_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_TRAINING_ACTIVITY_SUCCESS]: (state, action) => ({
        ...state,
        fetchingTrainingActivity: false,
        failedFetchingTrainingActivity: false,
        trainingActivity: get(action, 'payload.data')
    }),

    [REQUEST_TRAINING_ACTIVITY_BY_ID_SUCCESS]: (state, action) => ({
        ...state,
        trainingActivityDetail: get(action, 'payload.data')
    }),
    [REQUEST_TRAINING_ACTIVITY_FAILURE]: (state, action) => ({
        ...state,
        fetchingTrainingActivity: false,
        failedFetchingTrainingActivity: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_TRAINING_ACTIVITY_FILTER,
    payload
});

export default reducer;
export const namespace = 'trainingActivity';

export const getTrainingActivity = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/training-activity',
        actionTypes: [REQUEST_TRAINING_ACTIVITY, REQUEST_TRAINING_ACTIVITY_SUCCESS, REQUEST_TRAINING_ACTIVITY_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const getTrainingActivityById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/training-activity/'+id,
        actionTypes: [
            REQUEST_TRAINING_ACTIVITY_BY_ID,
            REQUEST_TRAINING_ACTIVITY_BY_ID_SUCCESS,
            REQUEST_TRAINING_ACTIVITY_BY_ID_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};

export const deleteTrainingActivity = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/training-activity/'+id,
        actionTypes: [
            REQUEST_DELETE_TRAINING_ACTIVITY,
            REQUEST_DELETE_TRAINING_ACTIVITY_SUCCESS,
            REQUEST_DELETE_TRAINING_ACTIVITY_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};

export const createTrainingActivity = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/training-activity',
        actionTypes: [
            REQUEST_CREATE_TRAINING_ACTIVITY,
            REQUEST_CREATE_TRAINING_ACTIVITY_SUCCESS,
            REQUEST_CREATE_TRAINING_ACTIVITY_FAILURE
        ],
        variables: {
            ...data
        },
        dispatch,
        getState
    });
};

export const updateTrainingActivity = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/training-activity/'+data.id,
        actionTypes: [
            REQUEST_UPDATE_TRAINING_ACTIVITY,
            REQUEST_UPDATE_TRAINING_ACTIVITY_SUCCESS,
            REQUEST_UPDATE_TRAINING_ACTIVITY_FAILURE
        ],
        variables: {
            ...data
        },
        dispatch,
        getState
    });
};
