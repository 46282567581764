import {
    all,
    put,
    fork,
    select,
    takeLatest
} from 'redux-saga/effects';

import { getOrderDetailById } from './index';
import {REQUEST_CHANGE_ORDER_STATUS_FAILURE, REQUEST_CHANGE_ORDER_STATUS_SUCCESS} from "../order";
import {showToastAlert} from "../app";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id) {
        yield put(getOrderDetailById(location.payload.id));
    }
}

function* handleChangeOrderStatusInDetailSuccess() {
    yield takeLatest(REQUEST_CHANGE_ORDER_STATUS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thay đổi trạng thái thành công'));
        const {location} = yield select();
        if (location.payload.id) {
            yield put(getOrderDetailById(location.payload.id));
        }
    });
}

function* handleChangeOrderStatusInDetailFailure() {
    yield takeLatest(REQUEST_CHANGE_ORDER_STATUS_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
        const {location} = yield select();
        if (location.payload.id) {
            yield put(getOrderDetailById(location.payload.id));
        }
    });
}

export function* loadOrderDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleChangeOrderStatusInDetailSuccess),
        fork(handleChangeOrderStatusInDetailFailure),
    ]);
}
