import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';
import callAPI from "../../../utils/callAPI";

export const REQUEST_DISCOUNT = 'REQUEST_DISCOUNT';
export const REQUEST_DISCOUNT_SUCCESS = 'REQUEST_DISCOUNT_SUCCESS';
export const REQUEST_DISCOUNT_FAILURE = 'REQUEST_DISCOUNT_FAILURE';

export const REQUEST_CREATE_DISCOUNT = 'REQUEST_CREATE_DISCOUNT';
export const REQUEST_CREATE_DISCOUNT_SUCCESS = 'REQUEST_CREATE_DISCOUNT_SUCCESS';
export const REQUEST_CREATE_DISCOUNT_FAILURE = 'REQUEST_CREATE_DISCOUNT_FAILURE';

export const REQUEST_UPDATE_DISCOUNT = 'REQUEST_UPDATE_DISCOUNT';
export const REQUEST_UPDATE_DISCOUNT_SUCCESS = 'REQUEST_UPDATE_DISCOUNT_SUCCESS';
export const REQUEST_UPDATE_DISCOUNT_FAILURE = 'REQUEST_UPDATE_DISCOUNT_FAILURE';


const defaultState = {
    fetchingDiscount: false,
    failedFetchingDiscount: false,
    discount: {},
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_DISCOUNT]: (state, action) => ({
        ...state,
        fetchingDiscount: true,
        failedFetchingDiscount: false,
    }),
    [REQUEST_DISCOUNT_SUCCESS]: (state, action) => ({
        ...state,
        fetchingDiscount: false,
        failedFetchingDiscount: false,
        discount: get(action, 'payload.data')
    }),
    [REQUEST_DISCOUNT_FAILURE]: (state, action) => ({
        ...state,
        fetchingDiscount: false,
        failedFetchingDiscount: true,
    }),
});

export default reducer;
export const namespace = 'discountDetail';

export const createDiscount = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'post',
        apiPath: '/admin/discounts',
        actionTypes: [REQUEST_CREATE_DISCOUNT, REQUEST_CREATE_DISCOUNT_SUCCESS, REQUEST_CREATE_DISCOUNT_FAILURE],
        variables: {...data},
        dispatch,
        getState
    });
};

export const getDiscount = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/discounts/'+id,
        actionTypes: [REQUEST_DISCOUNT, REQUEST_DISCOUNT_SUCCESS, REQUEST_DISCOUNT_FAILURE],
        variables: {},
        dispatch,
        getState
    });
}

export const updateDiscount = (id, data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/discounts/'+id,
        actionTypes: [REQUEST_UPDATE_DISCOUNT, REQUEST_UPDATE_DISCOUNT_SUCCESS, REQUEST_UPDATE_DISCOUNT_FAILURE],
        variables: {...data},
        dispatch,
        getState
    });
};
