import { fork, all } from 'redux-saga/effects';
import routeSaga from '../modules/routing/sagas.js';
import appSaga from '../modules/app/sagas.js';
import authSaga from '../modules/auth/sagas.js';
import checkPageAccess from '../modules/checkPageAccess/sagas.js';

export default function* sagas() {
  yield all([
    fork(routeSaga),
    fork(appSaga),
    fork(authSaga),
    fork(checkPageAccess),
  ]);
}
