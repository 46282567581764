import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const REQUEST_ORDERS_SUCCESS = 'REQUEST_ORDERS_SUCCESS';
export const REQUEST_ORDERS_FAILURE = 'REQUEST_ORDERS_FAILURE';
export const ACTION_CHANGE_ORDER_FILTER = 'ACTION_CHANGE_ORDER_FILTER';

export const REQUEST_CHANGE_ORDER_STATUS = 'REQUEST_CHANGE_ORDER_STATUS';
export const REQUEST_CHANGE_ORDER_STATUS_SUCCESS = 'REQUEST_CHANGE_ORDER_STATUS_SUCCESS';
export const REQUEST_CHANGE_ORDER_STATUS_FAILURE = 'REQUEST_CHANGE_ORDER_STATUS_FAILURE';

export const REQUEST_EXPORT_ORDERS = 'REQUEST_EXPORT_ORDERS';
export const REQUEST_EXPORT_ORDERS_SUCCESS = 'REQUEST_EXPORT_ORDERS_SUCCESS';
export const REQUEST_EXPORT_ORDERS_FAILURE = 'REQUEST_EXPORT_ORDERS_FAILURE';

const defaultState = {
    fetchingOrders: false,
    failedFetchingOrders: false,
    orders: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0
    },
    filter: {
        search_key: "",
        page: 1
    },
    errors: [],
    loading: false
};

const reducer = createReducer(defaultState, {
    [REQUEST_ORDERS]: (state, action) => ({
        ...state,
        fetchingOrders: true,
        failedFetchingOrders: false,
    }),
    [ACTION_CHANGE_ORDER_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_ORDERS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingOrders: false,
        failedFetchingOrders: false,
        orders: get(action, 'payload.data')
    }),
    [REQUEST_ORDERS_FAILURE]: (state, action) => ({
        ...state,
        fetchingOrders: false,
        failedFetchingOrders: true,
    }),
    [REQUEST_CHANGE_ORDER_STATUS]: (state, action) => ({
        ...state,
        loading: true
    }),
    [REQUEST_CHANGE_ORDER_STATUS_SUCCESS]: (state, action) => ({
        ...state,
        loading: false
    }),
    [REQUEST_CHANGE_ORDER_STATUS_FAILURE]: (state, action) => ({
        ...state,
        loading: false
    }),

});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_ORDER_FILTER,
    payload
});

export default reducer;
export const namespace = 'orders';

export const getOrders = (params = {page: 1}) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
    return await callAPI({
        method: 'get',
        apiPath: '/admin/orders',
        actionTypes: [REQUEST_ORDERS, REQUEST_ORDERS_SUCCESS, REQUEST_ORDERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const changeOrderStatus = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'patch',
        apiPath: `/admin/orders/${data.id}/status`,
        actionTypes: [
            REQUEST_CHANGE_ORDER_STATUS,
            REQUEST_CHANGE_ORDER_STATUS_SUCCESS,
            REQUEST_CHANGE_ORDER_STATUS_FAILURE
        ],
        variables: { status: data.status },
        dispatch,
        getState
    });
};

export const exportOrders = (params) => async (dispatch, getState) => {
    if (params.date_range) {
        params.date_range = await params.date_range.map(date => date.toISOString());
    }
    return await callAPI({
        method: 'post',
        apiPath: `/admin/orders/export`,
        actionTypes: [REQUEST_EXPORT_ORDERS, REQUEST_EXPORT_ORDERS_SUCCESS, REQUEST_EXPORT_ORDERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};
