import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const REQUEST_CUSTOMERS_SUCCESS = 'REQUEST_CUSTOMERS_SUCCESS';
export const REQUEST_CUSTOMERS_FAILURE = 'REQUEST_CUSTOMERS_FAILURE';
export const ACTION_CHANGE_CUSTOMER_FILTER = 'ACTION_CHANGE_CUSTOMER_FILTER';

const defaultState = {
  // fetchingUsers: false,
  // failedFetchingUsers: false,
  customers: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CUSTOMER_FILTER]: (state, action) => ({
    ...state,
    filter: get(action, 'payload'),
    }),
    [REQUEST_CUSTOMERS_SUCCESS]: (state, action) => ({
        ...state,
        customers: get(action, 'payload.data')
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_CUSTOMER_FILTER,
  payload
});

export default reducer;
export const namespace = 'customers';


export const getCustomers = (params = {page: 1}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/customers',
    actionTypes: [REQUEST_CUSTOMERS, REQUEST_CUSTOMERS_SUCCESS, REQUEST_CUSTOMERS_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};