import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getUserBonusDiscounts,
  ACTION_CHANGE_USER_BONUS_DISCOUNT_FILTER,
  REQUEST_EXPORT_USER_BONUS_DISCOUNTS_SUCCESS,
  REQUEST_EXPORT_USER_BONUS_DISCOUNTS_FAILURE,
  REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_SUCCESS,
  REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_FAILURE,
  REQUEST_USER_BONUS_DISCOUNTS_SUCCESS
} from './index';

import { showToastAlert } from "../app";
import moment from 'moment';

function* loadRouteData() {
  yield put(getUserBonusDiscounts({ date: moment().subtract(1, 'months') }));
}

function* handleListenActions() {
  yield takeLatest(ACTION_CHANGE_USER_BONUS_DISCOUNT_FILTER, function* (action) {
    const { user_bonus_discounts } = yield select();
    const { filter } = user_bonus_discounts;
    yield put(getUserBonusDiscounts(filter));
  });
}

function* handleListUserBonusDiscountSuccess() {
  yield takeLatest(REQUEST_USER_BONUS_DISCOUNTS_SUCCESS, function* (action) {
    // show message for case: system is processing
    if(!action.payload.data) {
      yield put(showToastAlert(action.payload.message));
    }
  });
}

function* handleExportOrdersSuccess() {
  yield takeLatest(REQUEST_EXPORT_USER_BONUS_DISCOUNTS_SUCCESS, function* (action) {
    yield put(showToastAlert('Thành công'))
    const link = document.createElement('a');
    link.href = action.payload.data.url;
    link.setAttribute(
      'download',
      action.payload.data.file_name,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
}

function* handleExportOrdersFailure() {
  yield takeLatest(REQUEST_EXPORT_USER_BONUS_DISCOUNTS_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
  });
}

function* handleCalculateUBDSuccess() {
  yield takeLatest(REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.message))

    // fetch bonus discount data
    const { user_bonus_discounts } = yield select();
    const { filter } = user_bonus_discounts;
    yield put(getUserBonusDiscounts(filter));
  });
}

function* handleCalculateUBDFailure() {
  yield takeLatest(REQUEST_CALCULATE_USER_BONUS_DISCOUNTS_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.message, 'error'));
  });
}

export function* loadUserBonusDiscountsPage() {
  yield all([
    fork(handleListenActions),
    fork(loadRouteData),
    fork(handleExportOrdersSuccess),
    fork(handleExportOrdersFailure),
    fork(handleCalculateUBDSuccess),
    fork(handleCalculateUBDFailure),
    fork(handleListUserBonusDiscountSuccess)
  ]);
}
