import {
    all,
    put,
    fork, takeLatest, select,
} from 'redux-saga/effects';

import {ACTION_CHANGE_PRODUCT_FILTER, getProducts} from "../product";
import {
    getDiscount,
    REQUEST_CREATE_DISCOUNT_FAILURE,
    REQUEST_CREATE_DISCOUNT_SUCCESS,
    REQUEST_UPDATE_DISCOUNT_SUCCESS
} from "./index";
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_DISCOUNT} from "../routing";


function* loadRouteData() {
    yield put(getProducts());
    const {location} = yield select();
    if (location.payload.id) {
        yield put(getDiscount(location.payload.id));
    }
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_PRODUCT_FILTER,
    ], function* (action) {
        const {products} = yield select();
        const {
            filter
        } = products;
        yield put(getProducts(filter))
    });
}

function* handleCreateDiscountSuccess() {
    yield takeLatest(REQUEST_CREATE_DISCOUNT_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo mới chương trình thành công'));
        yield put(redirect({
            type: ROUTE_DISCOUNT,
            query: ROUTE_DISCOUNT
        }));
    });
}

function* handleUpdateDiscountSuccess() {
    yield takeLatest(REQUEST_UPDATE_DISCOUNT_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật chương trình thành công'));
        yield put(redirect({
            type: ROUTE_DISCOUNT,
            query: ROUTE_DISCOUNT
        }));
    });
}

function* handleCreateDiscountFailure() {
    yield takeLatest(REQUEST_CREATE_DISCOUNT_FAILURE, function* (action) {
        yield put(showToastAlert('Tạo chương trình thất bại', 'error'));
    });
}

function* handleUpdateDiscountFailure() {
    yield takeLatest(REQUEST_CREATE_DISCOUNT_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật chương trình thất bại', 'error'));
    });
}

export function* loadRouteDiscountDetailData() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleCreateDiscountSuccess),
        fork(handleUpdateDiscountSuccess),
        fork(handleCreateDiscountFailure),
        fork(handleUpdateDiscountFailure)
    ]);
}
