import {
    all,
    put,
    fork,
    takeLatest,
    select
} from 'redux-saga/effects';

import {
    ACTION_CHANGE_TRAINING_ACTIVITY_FILTER,
    getTrainingActivity,
    getTrainingActivityById,
    REQUEST_CREATE_TRAINING_ACTIVITY_FAILURE,
    REQUEST_CREATE_TRAINING_ACTIVITY_SUCCESS,
    REQUEST_DELETE_TRAINING_ACTIVITY_FAILURE,
    REQUEST_DELETE_TRAINING_ACTIVITY_SUCCESS,
    REQUEST_UPDATE_TRAINING_ACTIVITY_SUCCESS,
} from './index';
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_TRAINING_ACTIVITY} from "../routing";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id && location.payload.id !== "create") {
        yield put(getTrainingActivityById(location.payload.id));
    }
    yield put(getTrainingActivity())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_TRAINING_ACTIVITY_FILTER,
    ], function* (action) {
        const {trainingActivity} = yield select();
        const {
            filter
        } = trainingActivity;
        yield put(getTrainingActivity(filter))
    });
}

function* handleCreateTrainingActivitySuccess() {
    yield takeLatest(REQUEST_CREATE_TRAINING_ACTIVITY_SUCCESS, function* (action) {
        yield put(showToastAlert(action.payload.message));
        yield put(getTrainingActivity())
        yield put(redirect({
            type: ROUTE_TRAINING_ACTIVITY,
        }));
    });
}

function* handleCreateTrainingActivityFailure() {
    yield takeLatest(REQUEST_CREATE_TRAINING_ACTIVITY_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
    });
}

function* handleUpdateTrainingActivitySuccess() {
    yield takeLatest(REQUEST_UPDATE_TRAINING_ACTIVITY_SUCCESS, function* (action) {
        yield put(showToastAlert(action.payload.message));
        yield put(getTrainingActivity())
        yield put(redirect({
            type: ROUTE_TRAINING_ACTIVITY,
        }));
    });
}

function* handleUpdateTrainingActivityFailure() {
    yield takeLatest(REQUEST_DELETE_TRAINING_ACTIVITY_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.message));
        yield put(getTrainingActivity())
    });
}

function* handleDeleteTrainingActivitySuccess() {
    yield takeLatest(REQUEST_DELETE_TRAINING_ACTIVITY_SUCCESS, function* (action) {
        yield put(showToastAlert(action.payload.message));
        yield put(getTrainingActivity())
    });
}

function* handleDeleteTrainingActivityFailure() {
    yield takeLatest(REQUEST_DELETE_TRAINING_ACTIVITY_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.message));
        yield put(getTrainingActivity())
    });
}

export function* loadTrainingActivityPage() {
    yield all([
        fork(loadRouteData),
        fork(handleCreateTrainingActivitySuccess),
        fork(handleCreateTrainingActivityFailure),
        fork(handleUpdateTrainingActivitySuccess),
        fork(handleUpdateTrainingActivityFailure),
        fork(handleDeleteTrainingActivitySuccess),
        fork(handleDeleteTrainingActivityFailure),
        fork(handleListenActions),
    ]);
}
