import {
    all,
    put,
    fork,
    select,
    takeLatest
} from 'redux-saga/effects';

import {
    getProducts,
    ACTION_CHANGE_PRODUCT_FILTER,
    REQUEST_DELETE_PRODUCT_SUCCESS,
    REQUEST_DELETE_PRODUCT_FAILURE,
    REQUEST_EXPORT_PRODUCTS_SUCCESS,
    REQUEST_EXPORT_PRODUCTS_FAILURE,
    REQUEST_UPDATE_PERCENTAGE_SUCCESS
} from './index';

import {
    REQUEST_UPDATE_PRODUCT_SUCCESS,
    REQUEST_CREATE_PRODUCT_SUCCESS
} from '../productDetail';

import { getAllCategories } from '../category';
import { showToastAlert } from "../app";
// import _ from "lodash";

function* loadRouteData() {
    yield put(getProducts())
    yield put(getAllCategories())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_PRODUCT_FILTER,
        REQUEST_UPDATE_PRODUCT_SUCCESS,
        REQUEST_CREATE_PRODUCT_SUCCESS
    ], function* (action) {
        const {products} = yield select();
        const {
            filter
        } = products;
        yield put(getProducts(filter))
    });
}

function* handleDeleteProductSuccess() {
    yield takeLatest(REQUEST_DELETE_PRODUCT_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa sản phẩm thành công'))
        yield put(getProducts())
    });
}

function* handleUpdatePercentageSuccess() {
    yield takeLatest(REQUEST_UPDATE_PERCENTAGE_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật thành công'))
        yield put(getProducts())
    });
}

function* handleDeleteProductFailure() {
    yield takeLatest(REQUEST_DELETE_PRODUCT_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa sản phẩm thất bại', 'error'))
        yield put(getProducts())
    });
}


function* handleExportProductSuccess() {
    yield takeLatest(REQUEST_EXPORT_PRODUCTS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thành công'))
        const link = document.createElement('a');
        link.href = action.payload.data.url;
        link.setAttribute(
            'download',
            action.payload.data.file_name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}


function* handleExportProductFailure() {
    yield takeLatest(REQUEST_EXPORT_PRODUCTS_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'))
    });
}

export function* loadProductPage() {
    yield all([
        fork(handleListenActions),
        fork(loadRouteData),
        fork(handleDeleteProductSuccess),
        fork(handleDeleteProductFailure),
        fork(handleExportProductSuccess),
        fork(handleExportProductFailure),
        fork(handleUpdatePercentageSuccess),
    ]);
}
