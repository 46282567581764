import {
    all,
    put,
    fork,
    select,
    takeLatest
} from 'redux-saga/effects';

import {
    getOrders,
    ACTION_CHANGE_ORDER_FILTER,
    REQUEST_CHANGE_ORDER_STATUS_SUCCESS,
    REQUEST_CHANGE_ORDER_STATUS_FAILURE,
    REQUEST_EXPORT_ORDERS_SUCCESS,
    REQUEST_EXPORT_ORDERS_FAILURE
} from './index';

import { showToastAlert } from "../app";
import moment from 'moment';

function* loadRouteData() {
    yield put(getOrders({ date_range: [moment().startOf('month'), moment()] }));
}

function* handleListenActions() {
    yield takeLatest(ACTION_CHANGE_ORDER_FILTER, function* (action) {
        const {orders} = yield select();
        const {
            filter
        } = orders;
        yield put(getOrders(filter))
    });
}

function* handleChangeOrderStatusSuccess() {
    yield takeLatest(REQUEST_CHANGE_ORDER_STATUS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thành công'))
        const { orders } = yield select();
        const { filter } = orders;
        yield put(getOrders(filter));
    });
}

function* handleChangeOrderStatusFailure() {
    yield takeLatest(REQUEST_CHANGE_ORDER_STATUS_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'))
        yield put(getOrders())
    });
}

function* handleExportOrdersSuccess() {
    yield takeLatest(REQUEST_EXPORT_ORDERS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thành công'))
        const link = document.createElement('a');
        link.href = action.payload.data.url;
        link.setAttribute(
            'download',
            action.payload.data.file_name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}

function* handleExportOrdersFailure() {
    yield takeLatest(REQUEST_EXPORT_ORDERS_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'))
    });
}

export function* loadOrderPage() {
    yield all([
        fork(handleListenActions),
        fork(loadRouteData),
        fork(handleChangeOrderStatusSuccess),
        fork(handleChangeOrderStatusFailure),
        fork(handleExportOrdersSuccess),
        fork(handleExportOrdersFailure)
    ]);
}
