import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';
import callAPI from "../../../utils/callAPI";

export const REQUEST_PROGRAMS_INFORMATION         = 'REQUEST_PROGRAMS_INFORMATION';
export const REQUEST_PROGRAMS_INFORMATION_SUCCESS = 'REQUEST_PROGRAMS_INFORMATION_SUCCESS';
export const REQUEST_PROGRAMS_INFORMATION_FAILURE = 'REQUEST_PROGRAMS_INFORMATION_FAILURE';


const defaultState = {
    fetchingProgramInformation: false,
    failedFetchingProgramInformation: false,
    programsInformation: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_PROGRAMS_INFORMATION]: (state, action) => ({
        ...state,
        fetchingProgramInformation: true,
        failedFetchingProgramInformation: false,
    }),
    [REQUEST_PROGRAMS_INFORMATION_SUCCESS]: (state, action) => ({
        ...state,
        fetchingProgramInformation: false,
        failedFetchingProgramInformation: false,
        programsInformation: get(action, 'payload.data')
    }),
    [REQUEST_PROGRAMS_INFORMATION_FAILURE]: (state, action) => ({
        ...state,
        fetchingProgramInformation: false,
        failedFetchingProgramInformation: true,
    }),
});

export default reducer;
export const namespace = 'programsInformation';

export const getProgramsInformation = (params) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/program-information',
        actionTypes: [REQUEST_PROGRAMS_INFORMATION, REQUEST_PROGRAMS_INFORMATION_SUCCESS, REQUEST_PROGRAMS_INFORMATION_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};
