import {
    all,
    put,
    fork, takeLatest, select,
} from 'redux-saga/effects';

import {
    ACTION_CHANGE_VOUCHER_FILTER,
    getVouchers, REQUEST_DELETE_VOUCHER_FAILURE, REQUEST_DELETE_VOUCHER_SUCCESS,
} from './index';
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getVouchers())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_VOUCHER_FILTER,
    ], function* (action) {
        const {vouchers} = yield select();
        const {
            filter
        } = vouchers;
        yield put(getVouchers(filter))
    });
}

function* handleDeleteVoucherSuccess() {
    yield takeLatest(REQUEST_DELETE_VOUCHER_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa khuyến mãi thành công'));
        yield put(getVouchers())
    });
}

function* handleDeleteVoucherFailure() {
    yield takeLatest(REQUEST_DELETE_VOUCHER_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa khuyến mãi thất bại'));
        yield put(getVouchers())
    });
}

export function* loadVoucherPage() {
    yield all([
        fork(loadRouteData),
        fork(handleDeleteVoucherSuccess),
        fork(handleDeleteVoucherFailure),
        fork(handleListenActions),
    ]);
}
