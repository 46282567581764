import {
    all,
    put,
    fork, takeLatest, select,
} from 'redux-saga/effects';

import {
    ACTION_CHANGE_NOTIFICATION_FILTER,
    getNotification, REQUEST_DELETE_NOTIFICATION_FAILURE, REQUEST_DELETE_NOTIFICATION_SUCCESS,
} from './index';
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getNotification())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_NOTIFICATION_FILTER,
    ], function* (action) {
        const {notifications} = yield select();
        const {
            filter
        } = notifications;
        yield put(getNotification(filter))
    });
}

function* handleDeleteNotificationSuccess() {
    yield takeLatest(REQUEST_DELETE_NOTIFICATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa thông báo thành công'));
        yield put(getNotification())
    });
}

function* handleDeleteVoucherFailure() {
    yield takeLatest(REQUEST_DELETE_NOTIFICATION_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa thông báo thất bại'));
        yield put(getNotification())
    });
}

export function* loadNotificationPage() {
    yield all([
        fork(loadRouteData),
        fork(handleDeleteNotificationSuccess),
        fork(handleDeleteVoucherFailure),
        fork(handleListenActions),
    ]);
}
