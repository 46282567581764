import {
  all,
  put,
  fork,
    takeLatest, select
} from 'redux-saga/effects';

import {
    getConfigurations,
    ACTION_CHANGE_CONFIGURATION_FILTER,
    REQUEST_SAVE_CONFIGURATION_FAILURE,
    REQUEST_SAVE_CONFIGURATION_SUCCESS,
    REQUEST_DELETE_CONFIGURATION_SUCCESS,
    REQUEST_DELETE_CONFIGURATION_FAILURE,
    REQUEST_UPDATE_CONFIGURATION_SUCCESS,
    REQUEST_UPDATE_CONFIGURATION_FAILURE
} from './index';
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getConfigurations());
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_CONFIGURATION_FILTER
    ], function* (action) {
        const { configuration } = yield select();
        const {
            filter
        } = configuration;

        yield put(getConfigurations(filter))
    });
}

function* handleCreateConfigurationSuccess() {
    yield takeLatest(REQUEST_SAVE_CONFIGURATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo mới cài đặt thành công'))
        yield put(getConfigurations())
    });
}

function* handleCreateConfigurationFailure() {
    yield takeLatest(REQUEST_SAVE_CONFIGURATION_FAILURE, function* (action) {
        yield put(showToastAlert('Tạo mới cài đặt thất bại', 'error'))
        yield put(getConfigurations())
    });
}

function* handleDeleteConfigurationSuccess() {
    yield takeLatest(REQUEST_DELETE_CONFIGURATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa cài đặt thành công'))
        yield put(getConfigurations())
    });
}

function* handleDeleteConfigurationFailure() {
    yield takeLatest(REQUEST_DELETE_CONFIGURATION_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa cài đặt thất bại', 'error'))
        yield put(getConfigurations())
    });
}

function* handleUpdateConfigurationSuccess() {
    yield takeLatest(REQUEST_UPDATE_CONFIGURATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật cài đặt thành công'))
        yield put(getConfigurations())
    });
}

function* handleUpdateConfigurationFailure() {
    yield takeLatest(REQUEST_UPDATE_CONFIGURATION_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật cài đặt thất bại', 'error'))
        yield put(getConfigurations())
    });
}

export function* loadConfigurationPage() {
  yield all([
    fork(loadRouteData),
    fork(handleCreateConfigurationSuccess),
    fork(handleCreateConfigurationFailure),
    fork(handleListenActions),
    fork(handleDeleteConfigurationSuccess),
    fork(handleDeleteConfigurationFailure),
    fork(handleUpdateConfigurationSuccess),
    fork(handleUpdateConfigurationFailure),
  ]);
}
  