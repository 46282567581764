import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
// import { ROLE_CS } from "../../../utils/constants";
import { get } from 'lodash';
export const REQUEST_CONTENT_CATEGORIES = 'REQUEST_CONTENT_CATEGORIES';
export const REQUEST_CONTENT_CATEGORIES_SUCCESS = 'REQUEST_CONTENT_CATEGORIES_SUCCESS';
export const REQUEST_CONTENT_CATEGORIES_FAILURE = 'REQUEST_CONTENT_CATEGORIES_FAILURE';
export const ACTION_CHANGE_CONTENT_CATEGORY_FILTER = 'ACTION_CHANGE_CONTENT_CATEGORY_FILTER';

export const REQUEST_DELETE_CONTENT_CATEGORY = 'REQUEST_DELETE_CONTENT_CATEGORY';
export const REQUEST_DELETE_CONTENT_CATEGORY_SUCCESS = 'REQUEST_DELETE_CONTENT_CATEGORY_SUCCESS';
export const REQUEST_DELETE_CONTENT_CATEGORY_FAILURE = 'REQUEST_DELETE_CONTENT_CATEGORY_FAILURE';

export const REQUEST_UPDATE_CONTENT_CATEGORY = 'REQUEST_UPDATE_CONTENT_CATEGORY';
export const REQUEST_UPDATE_CONTENT_CATEGORY_SUCCESS = 'REQUEST_UPDATE_CONTENT_CATEGORY_SUCCESS';
export const REQUEST_UPDATE_CONTENT_CATEGORY_FAILURE = 'REQUEST_UPDATE_CONTENT_CATEGORY_FAILURE';

export const REQUEST_CREATE_CONTENT_CATEGORY = 'REQUEST_CREATE_CONTENT_CATEGORY';
export const REQUEST_CREATE_CONTENT_CATEGORY_SUCCESS = 'REQUEST_CREATE_CONTENT_CATEGORY_SUCCESS';
export const REQUEST_CREATE_CONTENT_CATEGORY_FAILURE = 'REQUEST_CREATE_CONTENT_CATEGORY_FAILURE';

const defaultState = {
  fetchingUsers: false,
  failedFetchingUsers: false,
  content_categories: {
    results: [],
    current_page: 1,
    last_page: 1,
    per_page: 20,
    from: 0,
    to: 0,
    total: 0,
  },
  filter: {
    search_key: "",
    page: 1,
  },
  errors: []
};

const reducer = createReducer(defaultState, {
    [ACTION_CHANGE_CONTENT_CATEGORY_FILTER]: (state, action) => ({
    ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_CONTENT_CATEGORIES_SUCCESS]: (state, action) => ({
    ...state,
        content_categories: get(action, 'payload.data')
    }),
});

export const changeFilter = (payload) => ({
  type: ACTION_CHANGE_CONTENT_CATEGORY_FILTER,
  payload
});

export default reducer;
export const namespace = 'contentCategory';


export const getContentCategories = (params = {page: 1}) => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admin/category_content',
    actionTypes: [REQUEST_CONTENT_CATEGORIES, REQUEST_CONTENT_CATEGORIES_SUCCESS, REQUEST_CONTENT_CATEGORIES_FAILURE],
    variables: {...params},
    dispatch,
    getState
  });
};

export const updateContentCategory = (data)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'put',
    apiPath: '/admin/category_content/'+data.id,
    actionTypes: [REQUEST_UPDATE_CONTENT_CATEGORY, REQUEST_UPDATE_CONTENT_CATEGORY_SUCCESS, REQUEST_UPDATE_CONTENT_CATEGORY_FAILURE],
    variables: {
      name: data.name,
      image: data.image,
      status: data.status,
      type: data.type
    },
    dispatch,
    getState
  });
};

export const createContentCategory = (data)=> async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/category_content',
    actionTypes: [REQUEST_CREATE_CONTENT_CATEGORY, REQUEST_CREATE_CONTENT_CATEGORY_SUCCESS, REQUEST_CREATE_CONTENT_CATEGORY_FAILURE],
    variables: {name: data.name, image: data.image},
    dispatch,
    getState
  });
};

export const deleteContentCategory = (contentId) => async (dispatch, getState) => {
	return await callAPI({
		method: 'delete',
		apiPath: '/admin/category_content/'+contentId,
		actionTypes: [
			REQUEST_DELETE_CONTENT_CATEGORY,
			REQUEST_DELETE_CONTENT_CATEGORY_SUCCESS,
			REQUEST_DELETE_CONTENT_CATEGORY_FAILURE
		],
		variables: {},
		dispatch,
		getState
	});
};