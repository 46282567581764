import {
    all,
    put,
    select,
    takeLatest,
    fork,
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_SALE_GROUP_DETAIL_FILTER, getMemberOfGroup,
    getSaleGroupDetail,
    REQUEST_DELETE_MEMBER_FAILURE,
    REQUEST_DELETE_MEMBER_SUCCESS, REQUEST_EXPORT_SALE_GROUP_MEMBERS_SUCCESS
} from "./index";
import {showToastAlert} from "../app";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id) {
        yield put(getSaleGroupDetail(location.payload.id));
        yield put(getMemberOfGroup(location.payload.id));
    }
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_SALE_GROUP_DETAIL_FILTER,
    ], function* (action) {
        const {saleGroupDetail, location} = yield select();
        const {
            filter
        } = saleGroupDetail;
        if (location.payload.id) {
            yield put(getMemberOfGroup(location.payload.id, filter))
        }
    });
}

function* handleDeleteMemberSuccess() {
    yield takeLatest(REQUEST_DELETE_MEMBER_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa hội nhóm thành công'))
        const {location} = yield select();
        if (location.payload.id) {
            yield put(getSaleGroupDetail(location.payload.id));
        }
    });
}

function* handleDeleteMemberFailure() {
    yield takeLatest(REQUEST_DELETE_MEMBER_FAILURE, function* (action) {
        yield put(showToastAlert('Xóa hội nhóm thất bại', 'error'))
        const {location} = yield select();
        if (location.payload.id) {
            yield put(getSaleGroupDetail(location.payload.id));
        }
    });
}

function* handleExportSaleGroupMembersSuccess() {
    yield takeLatest(REQUEST_EXPORT_SALE_GROUP_MEMBERS_SUCCESS, function* (action) {
        yield put(showToastAlert('Thành công'))
        const link = document.createElement('a');
        link.href = action.payload.data.url;
        link.setAttribute(
            'download',
            action.payload.data.file_name,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
}

export function* loadSaleGroupDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleDeleteMemberSuccess),
        fork(handleDeleteMemberFailure),
        fork(handleExportSaleGroupMembersSuccess)
    ]);
}
