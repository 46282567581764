import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getWithdrawalOrders,
  ACTION_CHANGE_WITHDRAWAL_ORDER_FILTER,
  REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_SUCCESS,
  REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_FAILURE
} from './index';

import { showToastAlert } from "../app";

function* loadRouteData() {
  yield put(getWithdrawalOrders())
}

function* handleListenActions() {
  yield takeLatest(ACTION_CHANGE_WITHDRAWAL_ORDER_FILTER, function* (action) {
    const { withdrawalOrders } = yield select();
    const {
      filter
    } = withdrawalOrders;
    yield put(getWithdrawalOrders(filter))
  });
}

function* handleChangeWithdrawalOrderStatusSuccess() {
  yield takeLatest(REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_SUCCESS, function* (action) {
    yield put(showToastAlert(action.payload.data.message))
    yield put(getWithdrawalOrders())
  });
}

function* handleChangeWithdrawalOrderStatusFailure() {
  yield takeLatest(REQUEST_CHANGE_WITHDRAWAL_ORDER_STATUS_FAILURE, function* (action) {
    yield put(showToastAlert(action.payload.data.message, 'error'))
    yield put(getWithdrawalOrders())
  });
}

export function* loadWithdrawalOrderPage() {
  yield all([
    fork(handleListenActions),
    fork(loadRouteData),
    fork(handleChangeWithdrawalOrderStatusSuccess),
    fork(handleChangeWithdrawalOrderStatusFailure)
  ]);
}
