import {
    all,
    put,
    select,
    takeLatest,
    fork
} from 'redux-saga/effects';

import {
    REQUEST_DELETE_MODERATOR_SUCCESS,
    ACTION_CHANGE_MODERATOR_FILTER,
    getModerators
} from "./index";
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getModerators())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_MODERATOR_FILTER,
    ], function* (action) {
        const {moderators} = yield select();
        const {
            filter
        } = moderators;
        yield put(getModerators(filter))
    });
}

function* handleDeleteModeratorsSuccess() {
    yield takeLatest(REQUEST_DELETE_MODERATOR_SUCCESS, function* (action) {
        yield put(showToastAlert('Xóa thành công'))
        yield put(getModerators());
    });
}

export function* loadModeratorPage() {
    yield all([
        fork(loadRouteData),
        fork(handleDeleteModeratorsSuccess),
        fork(handleListenActions)
    ]);
}
