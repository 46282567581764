import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import appReducer, { namespace as appNamespace } from './modules/app';
import authReducer, { namespace as authNamespace } from './modules/auth';
import dashboardReducer, { namespace as dashboardNamespace } from './modules/dashboard';
import collaboratorReducer, { namespace as collaboratorNamespace } from './modules/collaborators';
import collaboratorDetailReducer, { namespace as collaboratorDetailNamespace } from './modules/collaboratorDetail';
import contentCategoryReducer, { namespace as contentCategoryNamespace } from './modules/contentCategory';
import contentReducer, { namespace as contentNamespace } from './modules/content';
import categoryReducer, { namespace as categoryNamespace } from './modules/category';
import productReducer, { namespace as productNamespace } from './modules/product';
import productDetailReducer, { namespace as productDetailNamespace } from './modules/productDetail';
import storeReducer, { namespace as storeNamespace } from './modules/store';
import customerReducer, { namespace as customerNamespace } from './modules/customer';
import orderReducer, { namespace as orderNamespace } from './modules/order';
import orderDetailReducer, { namespace as orderDetailNamespace } from './modules/orderDetail';
import withdrawalOrderReducer, { namespace as withdrawalOrderNamespace } from './modules/withdrawalOrder';
import voucherReducer, { namespace as voucherNamespace } from './modules/voucher';
import notificationReducer, { namespace as notificationNamespace } from './modules/notification';
import notificationDetailReducer, { namespace as notificationDetailNamespace } from './modules/notificationDetail';
import voucherDetailReducer, { namespace as voucherDetailNamespace } from './modules/voucherDetail';
import slideBannerReducer, { namespace as slideBannerReducerNamespace } from './modules/slideBanner';
import discountReducer, { namespace as discountReducerNamespace } from './modules/discount';
import discountDetailReducer, { namespace as discountDetailReducerNamespace } from './modules/discountDetail';
import trackingUtmReducer, { namespace as trackingUtmReducerNamespace } from './modules/trackingUtm';
import trainingScheduleReducer, { namespace as trainingScheduleReducerNamespace } from './modules/trainingSchedule';
import moderatorReducer, { namespace as moderatorReducerNamespace } from './modules/moderator';
import moderatorDetailReducer, { namespace as moderatorDetailReducerNamespace } from './modules/moderatorDetail';
import affiliateReducer, { namespace as affiliateReducerNamespace } from './modules/affiliate';
import affiliateFormReducer, { namespace as affiliateFormReducerNamespace } from './modules/affiliateForm';
import affiliateStatsReducer, { namespace as affiliateStatsReducerNamespace } from './modules/affiliateStats';
import userBonusDiscountsReducer, { namespace as userBonusDiscountsReducerNamespace } from './modules/userBonusDiscounts';
import programInformationReducer, { namespace as programInformationReducerNamespace } from './modules/programInformation';
import saleGroupInformationReducer, { namespace as saleGroupInformationReducerNamespace } from './modules/saleGroupInformation';
import saleGroupConfigReducer, { namespace as saleGroupConfigReducerNamespace } from './modules/saleGroupConfig';
import saleGroupsReducer, { namespace as saleGroupsReducerNamespace } from './modules/saleGroups';
import saleGroupDetailReducer, { namespace as saleGroupDetailReducerNamespace } from './modules/saleGroupDetail';
import questionAnswerReducer, { namespace as questionAnswerReducerNamespace } from './modules/questionAnswer';
import questionAnswerDetailReducer, { namespace as questionAnswerReducerDetailNamespace } from './modules/questionAnswerDetail';
import postReducer, { namespace as postReducerNamespace } from './modules/post';
import postDetailReducer, { namespace as postDetailReducerNamespace } from './modules/postDetail';
import configurationReducer, { namespace as configurationReducerNamespace } from './modules/configuration';
import trainingActivityReducer, { namespace as trainingActivityReducerNamespace } from './modules/trainingActivity';

export default extraReducers =>
  combineReducers({
    [authNamespace]: authReducer,
    [appNamespace]: appReducer,
    [dashboardNamespace]: dashboardReducer,
    [collaboratorNamespace]: collaboratorReducer,
    [collaboratorDetailNamespace]: collaboratorDetailReducer,
    [contentCategoryNamespace]: contentCategoryReducer,
    [contentNamespace]: contentReducer,
    [categoryNamespace]: categoryReducer,
    [productNamespace]: productReducer,
    [productDetailNamespace]: productDetailReducer,
    [storeNamespace]: storeReducer,
    [customerNamespace]: customerReducer,
    [orderNamespace]: orderReducer,
    [orderDetailNamespace]: orderDetailReducer,
    [withdrawalOrderNamespace]: withdrawalOrderReducer,
    [voucherNamespace]: voucherReducer,
    [voucherDetailNamespace]: voucherDetailReducer,
    [notificationNamespace]: notificationReducer,
    [notificationDetailNamespace]: notificationDetailReducer,
    [slideBannerReducerNamespace]: slideBannerReducer,
    [discountReducerNamespace]: discountReducer,
    [discountDetailReducerNamespace]: discountDetailReducer,
    [trackingUtmReducerNamespace]: trackingUtmReducer,
    [trainingScheduleReducerNamespace]: trainingScheduleReducer,
    [moderatorReducerNamespace]: moderatorReducer,
    [moderatorDetailReducerNamespace]: moderatorDetailReducer,
    [affiliateReducerNamespace]: affiliateReducer,
    [affiliateFormReducerNamespace]: affiliateFormReducer,
    [affiliateStatsReducerNamespace]: affiliateStatsReducer,
    [userBonusDiscountsReducerNamespace]: userBonusDiscountsReducer,
    [programInformationReducerNamespace]: programInformationReducer,
    [saleGroupInformationReducerNamespace]: saleGroupInformationReducer,
    [saleGroupConfigReducerNamespace]: saleGroupConfigReducer,
    [saleGroupsReducerNamespace]: saleGroupsReducer,
    [saleGroupDetailReducerNamespace]: saleGroupDetailReducer,
    [questionAnswerReducerNamespace]: questionAnswerReducer,
    [questionAnswerReducerDetailNamespace]: questionAnswerDetailReducer,
    [postReducerNamespace]: postReducer,
    [postDetailReducerNamespace]: postDetailReducer,
    [configurationReducerNamespace]: configurationReducer,
    [trainingActivityReducerNamespace]: trainingActivityReducer,
    form: formReducer,
    ...extraReducers
  });
