import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_SALE_GROUP_CONFIG         = 'REQUEST_SALE_GROUP_CONFIG';
export const REQUEST_SALE_GROUP_CONFIG_SUCCESS = 'REQUEST_SALE_GROUP_CONFIG_SUCCESS';
export const REQUEST_SALE_GROUP_CONFIG_FAILURE = 'REQUEST_SALE_GROUP_CONFIG_FAILURE';

export const REQUEST_UPDATE_SALE_GROUP_CONFIG         = 'REQUEST_UPDATE_SALE_GROUP_CONFIG';
export const REQUEST_UPDATE_SALE_GROUP_CONFIG_SUCCESS = 'REQUEST_UPDATE_SALE_GROUP_CONFIG_SUCCESS';
export const REQUEST_UPDATE_SALE_GROUP_CONFIG_FAILURE = 'REQUEST_UPDATE_SALE_GROUP_CONFIG_FAILURE';

const defaultState = {
    fetchingSaleGroupConfig: false,
    failedFetchingSaleGroupConfig: false,
    saleGroupConfig: {},
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_SALE_GROUP_CONFIG]: (state, action) => ({
        ...state,
        fetchingSaleGroupConfig: true,
        failedFetchingSaleGroupConfig: false,
    }),
    [REQUEST_SALE_GROUP_CONFIG_SUCCESS]: (state, action) => ({
        ...state,
        fetchingSaleGroupConfig: false,
        failedFetchingSaleGroupConfig: false,
        saleGroupConfig: get(action, 'payload.data')
    }),
    [REQUEST_SALE_GROUP_CONFIG_FAILURE]: (state, action) => ({
        ...state,
        fetchingSaleGroupConfig: false,
        failedFetchingSaleGroupConfig: true,
    }),
});

export default reducer;
export const namespace = 'saleGroupConfig';

export const getSaleGroupConfigs = (params = {page: 1}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/sale-group-config',
        actionTypes: [REQUEST_SALE_GROUP_CONFIG, REQUEST_SALE_GROUP_CONFIG_SUCCESS, REQUEST_SALE_GROUP_CONFIG_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const updateSaleGroupConfig = (data) => async (dispatch, getState) => {
    return await callAPI({
        method: 'put',
        apiPath: '/admin/sale-group-config',
        actionTypes: [REQUEST_UPDATE_SALE_GROUP_CONFIG, REQUEST_UPDATE_SALE_GROUP_CONFIG_SUCCESS, REQUEST_UPDATE_SALE_GROUP_CONFIG_FAILURE],
        variables: data,
        dispatch,
        getState
    });
};

