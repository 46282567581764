import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { keyBy } from 'lodash';

export const BOOT = 'BOOT';
export const BOOT_FINISHED = 'BOOT_FINISHED';
export const SHOW_TOAST_ALERT = 'SHOW_TOAST_ALERT';
export const CLOSE_TOAST_ALERT = 'CLOSE_TOAST_ALERT';

export const REQUEST_USER_CHANNELS          = 'REQUEST_USER_CHANNELS';
export const REQUEST_USER_CHANNELS_SUCCESS  = 'REQUEST_USER_CHANNELS_SUCCESS';
export const REQUEST_USER_CHANNELS_FAILURE  = 'REQUEST_USER_CHANNELS_FAILURE';

export const SHOW_BLOCK_NAVIGATION_MODAL = 'SHOW_BLOCK_NAVIGATION_MODAL';
export const HIDE_BLOCK_NAVIGATION_MODAL = 'HIDE_BLOCK_NAVIGATION_MODAL';

const defaultState = {
  isBooting: false,
  bootDidFinish: false,

  userChannels: {},
  fetchingUserChannels: true,
  failedFetchingUserChannels: false,
  isShowToastAlert: false,
  toastAlertPayload: {
    type: 'success',
    message: '',
  },
  blockNavigationMessage: '',
  canLeave: () => {}
};
const reducer = createReducer(defaultState, {
  [BOOT]: state => ({
    ...state,
    isBooting: true,
    bootDidFinish: false
  }),
  [BOOT_FINISHED]: state => ({
    ...state,
    isBooting: false,
    bootDidFinish: true
  }),
  [REQUEST_USER_CHANNELS]: (state, action) => ({
    ...state,
    fetchingUserChannels: true,
    failedFetchingUserChannels: false,
  }),
  [REQUEST_USER_CHANNELS_SUCCESS]: (state, action) => ({
    ...state,
    userChannels: keyBy( action.payload.data, 'channel_oid'),
    fetchingUserChannels: false,
    failedFetchingUserChannels: false,
  }),
  [REQUEST_USER_CHANNELS_FAILURE]: (state, action) => ({
    ...state,
    fetchingUserChannels: false,
    failedFetchingUserChannels: true,
  }),
  [SHOW_TOAST_ALERT]: (state, action) => ({
    ...state,
    isShowToastAlert: true,
    toastAlertPayload: action.payload
  }),
  [CLOSE_TOAST_ALERT]: state => ({
    ...state,
    isShowToastAlert: false,
    toastAlertPayload: {
      type: 'success',
      message: '',
    }
  }),
  [SHOW_BLOCK_NAVIGATION_MODAL]: (state, action) => ({
    ...state,
    blockNavigationMessage: action.payload.message,
    canLeave: action.payload.canLeave,
  }),
  [HIDE_BLOCK_NAVIGATION_MODAL]: (state, action) => ({
    ...state,
    blockNavigationMessage: '',
    canLeave: () => {}
  }),
});

export default reducer;
export const namespace = 'app';

export const boot = (options = {}) => ({
  type: BOOT,
  payload: options
});

export const bootFinished = () => ({
  type: BOOT_FINISHED
});


export const getUserChannels = () => async (dispatch, getState) => {
  const { auth } = getState()
  return await callAPI({
    method: 'get',
    apiPath: '/conversations/channel',
    actionTypes: [REQUEST_USER_CHANNELS, REQUEST_USER_CHANNELS_SUCCESS, REQUEST_USER_CHANNELS_FAILURE],
    variables: {
      userId: auth.user.id,
      facebook_access_token: auth.userConfig.facebook_access_token
    },
    dispatch,
    getState
  });
};

export const showToastAlert = (message = '', type = 'success') => ({
  type: SHOW_TOAST_ALERT,
  payload: {
    type,
    message
  }
});

export const closeToastAlert = () => ({
  type: CLOSE_TOAST_ALERT
});

export const isBooting = state => state.app.isBooting;
export const bootDidFinish = state => state.app.bootDidFinish;
export const selectUserChannels = state => state.app.userChannels;
