import {
    all,
    put,
    fork,
} from 'redux-saga/effects';

import {
    getProgramsInformation,
} from './index';

function* loadRouteData() {
    yield put(getProgramsInformation())
}

export function* loadProgramInformationPage() {
    yield all([
        fork(loadRouteData),
    ]);
}
