import {
    all,
    put,
    fork, takeLatest,
} from 'redux-saga/effects';

import {
    getProgramByCode,
    REQUEST_UPDATE_SALE_GROUP_INFORMATION_SUCCESS
} from './index';
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getProgramByCode())
}

function* handleListenActions() {

}

function* handleUpdateSaleGroupInformationSuccess() {
    yield takeLatest(REQUEST_UPDATE_SALE_GROUP_INFORMATION_SUCCESS, function* (action) {
        yield put(showToastAlert('Đã chỉnh sửa'));
        yield put(getProgramByCode());
    });
}

export function* loadSaleGroupInformationPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleUpdateSaleGroupInformationSuccess),
    ]);
}
