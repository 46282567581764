import {
    all,
    fork,
    put,
    takeLatest,
    select,
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_TRACKING_UTM_FILTER,
    getTrackingUtm,
} from "./index";
import moment from "moment";

function* loadRouteData() {
    yield put(getTrackingUtm({date_range: [moment().startOf('month'), moment()]}));
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_TRACKING_UTM_FILTER
    ], function* (action) {
        const {trackingUtm} = yield select();
        const {
            filter
        } = trackingUtm;
        yield put(getTrackingUtm(filter))
    });
}

export function* loadTrackingUtmPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
    ]);
}
