import {
    all,
    put,
    fork, select, takeLatest,
} from 'redux-saga/effects';

import {
    REQUEST_CREATE_VOUCHER_FAILURE,
    REQUEST_CREATE_VOUCHER_SUCCESS,
    REQUEST_UPDATE_VOUCHER_FAILURE,
    REQUEST_UPDATE_VOUCHER_SUCCESS,
    getVoucherById
} from './index';
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_VOUCHER} from "../routing";

function* loadRouteData() {
    const {location} = yield select();
    if (location.payload.id && location.payload.id !== "create") {
        yield put(getVoucherById(location.payload.id));
    }
}

function* handleCreateVoucherSuccess() {
    yield takeLatest(REQUEST_CREATE_VOUCHER_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo mới khuyến mãi thành công'));
        yield put(redirect({
            type: ROUTE_VOUCHER,
            query: ROUTE_VOUCHER
        }));
    });
}

function* handleVoucherFailure() {
    yield takeLatest(REQUEST_CREATE_VOUCHER_FAILURE, function* (action) {
        yield put(showToastAlert('Tạo mới khuyến mãi thất bại'));
        yield put(redirect({
            type: ROUTE_VOUCHER,
            query: ROUTE_VOUCHER
        }));
    });
}

function* handleUpdateVoucherSuccess() {
    yield takeLatest(REQUEST_UPDATE_VOUCHER_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật khuyến mãi thành công'));
        yield put(redirect({
            type: ROUTE_VOUCHER,
            query: ROUTE_VOUCHER
        }));
    });
}

function* handleUpdateProductFailure() {
    yield takeLatest(REQUEST_UPDATE_VOUCHER_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật khuyến mãi thất bại', 'error'));
    });
}

export function* loadVoucherDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleCreateVoucherSuccess),
        fork(handleVoucherFailure),
        fork(handleUpdateVoucherSuccess),
        fork(handleUpdateProductFailure),
    ]);
}
