import { createReducer } from 'redux-create-reducer';
import callAPI from "../../../utils/callAPI";
import {get} from "lodash";

export const REQUEST_REPORT_STATISTICS_DASHBOARD 		  = 'REQUEST_REPORT_STATISTICS_DASHBOARD';
export const REQUEST_REPORT_STATISTICS_DASHBOARD_SUCCESS  = 'REQUEST_REPORT_STATISTICS_DASHBOARD_SUCCESS';
export const REQUEST_REPORT_STATISTICS_DASHBOARD_FAILURE  = 'REQUEST_REPORT_STATISTICS_DASHBOARD_FAILURE';

export const REQUEST_REPORT_PARTNER_DASHBOARD 		   = 'REQUEST_REPORT_PARTNER_DASHBOARD';
export const REQUEST_REPORT_PARTNER_DASHBOARD_SUCCESS  = 'REQUEST_REPORT_PARTNER_DASHBOARD_SUCCESS';
export const REQUEST_REPORT_PARTNER_DASHBOARD_FAILURE  = 'REQUEST_REPORT_PARTNER_DASHBOARD_FAILURE';

export const REQUEST_REPORT_PRODUCT_DASHBOARD 		   = 'REQUEST_REPORT_PRODUCT_DASHBOARD';
export const REQUEST_REPORT_PRODUCT_DASHBOARD_SUCCESS  = 'REQUEST_REPORT_PRODUCT_DASHBOARD_SUCCESS';
export const REQUEST_REPORT_PRODUCT_DASHBOARD_FAILURE  = 'REQUEST_REPORT_PRODUCT_DASHBOARD_FAILURE';

export const REQUEST_REPORT_CATEGORY_DASHBOARD 		    = 'REQUEST_REPORT_CATEGORY_DASHBOARD';
export const REQUEST_REPORT_CATEGORY_DASHBOARD_SUCCESS  = 'REQUEST_REPORT_CATEGORY_DASHBOARD_SUCCESS';
export const REQUEST_REPORT_CATEGORY_DASHBOARD_FAILURE  = 'REQUEST_REPORT_CATEGORY_DASHBOARD_FAILURE';

export const ACTION_CHANGE_DASHBOARD_FILTER = 'ACTION_CHANGE_DASHBOARD_FILTER';
export const ACTION_CHANGE_DASHBOARD_PRODUCT_FILTER = 'ACTION_CHANGE_DASHBOARD_PRODUCT_FILTER';

export const REQUEST_CATEGORIES_DASHBOARD 		   = 'REQUEST_CATEGORIES_DASHBOARD';
export const REQUEST_CATEGORIES_DASHBOARD_SUCCESS  = 'REQUEST_CATEGORIES_DASHBOARD_SUCCESS';
export const REQUEST_CATEGORIES_DASHBOARD_FAILURE  = 'REQUEST_CATEGORIES_DASHBOARD_FAILURE';

const defaultState = {
	fetchingReportStatisticsDashboard: false,
	failedFetchingReportStatisticsDashboard: false,
	reportStatisticsDashboard: {},
	fetchingReportPartnerDashboard: false,
	failedFetchingReportPartnerDashboard: false,
	reportPartnerDashboard: {},
	fetchingReportProductDashboard: false,
	failedFetchingReportProductDashboard: false,
	reportProductDashboard: {},
	fetchingReportCategoryDashboard: false,
	failedFetchingReportCategoryDashboard: false,
	reportCategoryDashboard: {},
	categories: [],
	filter: {},
	filter_product: {sort_by: "revenue"}
};
const reducer = createReducer(defaultState, {
	[REQUEST_REPORT_STATISTICS_DASHBOARD]: (state, action) => ({
		...state,
		fetchingReportStatisticsDashboard: true,
		failedFetchingReportStatisticsDashboard: false
	}),
	[REQUEST_REPORT_STATISTICS_DASHBOARD_SUCCESS]: (state, action) => ({
		...state,
		reportStatisticsDashboard: get(action, 'payload.data'),
		fetchingReportStatisticsDashboard: false,
		failedFetchingReportStatisticsDashboard: false
	}),
	[REQUEST_REPORT_STATISTICS_DASHBOARD_FAILURE]: (state, action) => ({
		...state,
		fetchingReportStatisticsDashboard: false,
		failedFetchingReportStatisticsDashboard: true
	}),
	[REQUEST_REPORT_PARTNER_DASHBOARD]: (state, action) => ({
		...state,
		fetchingReportPartnerDashboard: true,
		failedFetchingReportPartnerDashboard: false
	}),
	[REQUEST_REPORT_PARTNER_DASHBOARD_SUCCESS]: (state, action) => ({
		...state,
		reportPartnerDashboard: get(action, 'payload.data'),
		fetchingReportPartnerDashboard: false,
		failedFetchingReportPartnerDashboard: false
	}),
	[REQUEST_REPORT_PARTNER_DASHBOARD_FAILURE]: (state, action) => ({
		...state,
		fetchingReportPartnerDashboard: false,
		failedFetchingReportPartnerDashboard: true
	}),
	[ACTION_CHANGE_DASHBOARD_FILTER]: (state, action) => ({
		...state,
		filter: get(action, 'payload'),
	}),
	[ACTION_CHANGE_DASHBOARD_PRODUCT_FILTER]: (state, action) => ({
		...state,
		filter_product: get(action, 'payload'),
	}),
	[REQUEST_REPORT_PRODUCT_DASHBOARD_SUCCESS]: (state, action) => ({
		...state,
		reportProductDashboard: get(action, 'payload.data'),
		fetchingReportProductDashboard: false,
		failedFetchingReportProductDashboard: false
	}),
	[REQUEST_REPORT_PRODUCT_DASHBOARD_FAILURE]: (state, action) => ({
		...state,
		fetchingReportProductDashboard: false,
		failedFetchingReportProductDashboard: true
	}),
	[REQUEST_REPORT_PRODUCT_DASHBOARD]: (state, action) => ({
		...state,
		fetchingReportProductDashboard: true,
		failedFetchingReportProductDashboard: false
	}),
	[REQUEST_REPORT_CATEGORY_DASHBOARD_SUCCESS]: (state, action) => ({
		...state,
		reportCategoryDashboard: get(action, 'payload.data'),
		fetchingReportCategoryDashboard: false,
		failedFetchingReportCategoryDashboard: false
	}),
	[REQUEST_REPORT_CATEGORY_DASHBOARD_FAILURE]: (state, action) => ({
		...state,
		fetchingReportCategoryDashboard: false,
		failedFetchingReportCategoryDashboard: true
	}),
	[REQUEST_REPORT_CATEGORY_DASHBOARD]: (state, action) => ({
		...state,
		fetchingReportCategoryDashboard: true,
		failedFetchingReportCategoryDashboard: false
	}),
	[REQUEST_CATEGORIES_DASHBOARD_SUCCESS]: (state, action) => ({
		...state,
		categories: get(action, 'payload.data'),
	}),
});

export default reducer;
export const namespace = 'dashboard';

export const changeFilter = (payload) => ({
	type: ACTION_CHANGE_DASHBOARD_FILTER,
	payload
});

export const changeFilterProduct = (payload) => ({
	type: ACTION_CHANGE_DASHBOARD_PRODUCT_FILTER,
	payload
});

export const getReportsStatistics = (params = {}) => async (dispatch, getState) => {
	if (params.date_range) {
		params.date_range = await params.date_range.map(date => date.toISOString());
	}
	return await callAPI({
		method: 'get',
		apiPath: '/admin/report/statistics',
		actionTypes: [REQUEST_REPORT_STATISTICS_DASHBOARD, REQUEST_REPORT_STATISTICS_DASHBOARD_SUCCESS, REQUEST_REPORT_STATISTICS_DASHBOARD_FAILURE],
		variables: {...params},
		dispatch,
		getState
	});
};

export const getReportsPartner = (params = {}) => async (dispatch, getState) => {
	if (params.date_range) {
		params.date_range = await params.date_range.map(date => date.toISOString());
	}
	return await callAPI({
		method: 'get',
		apiPath: '/admin/report/partner',
		actionTypes: [REQUEST_REPORT_PARTNER_DASHBOARD, REQUEST_REPORT_PARTNER_DASHBOARD_SUCCESS, REQUEST_REPORT_PARTNER_DASHBOARD_FAILURE],
		variables: {...params},
		dispatch,
		getState
	});
};

export const getReportsProduct = (params = {}) => async (dispatch, getState) => {
	if (params.date_range) {
		params.date_range = await params.date_range.map(date => date.toISOString());
	}
	return await callAPI({
		method: 'get',
		apiPath: '/admin/report/product',
		actionTypes: [REQUEST_REPORT_PRODUCT_DASHBOARD, REQUEST_REPORT_PRODUCT_DASHBOARD_SUCCESS, REQUEST_REPORT_PRODUCT_DASHBOARD_FAILURE],
		variables: {...params},
		dispatch,
		getState
	});
};

export const getReportsCategory = (params = {}) => async (dispatch, getState) => {
	if (params.date_range) {
		params.date_range = await params.date_range.map(date => date.toISOString());
	}
	return await callAPI({
		method: 'get',
		apiPath: '/admin/report/category',
		actionTypes: [REQUEST_REPORT_CATEGORY_DASHBOARD, REQUEST_REPORT_CATEGORY_DASHBOARD_SUCCESS, REQUEST_REPORT_CATEGORY_DASHBOARD_FAILURE],
		variables: {...params},
		dispatch,
		getState
	});
};

export const getCategories = () => async (dispatch, getState) => {
	return await callAPI({
		method: 'get',
		apiPath: '/admin/categories/parents',
		actionTypes: [REQUEST_CATEGORIES_DASHBOARD, REQUEST_CATEGORIES_DASHBOARD_SUCCESS, REQUEST_CATEGORIES_DASHBOARD_FAILURE],
		variables: {},
		dispatch,
		getState
	});
};
