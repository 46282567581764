import {
    all,
    put,
    fork,
    takeLatest,
    select
  } from 'redux-saga/effects';
  
  import {
    getPost,
    REQUEST_UPDATE_POST_SUCCESS,
    REQUEST_UPDATE_POST_FAILURE,
    REQUEST_CREATE_POST_SUCCESS,
    REQUEST_CREATE_POST_FAILURE
  } from './index';
  import { showToastAlert } from "../app";
import {ACTION_CHANGE_PRODUCT_FILTER, getProducts} from "../product";
import {redirect} from "redux-first-router";
import {ROUTE_POSTS} from "../routing";
  
  function* loadRouteData() {
      yield put(getProducts());
      const {location} = yield select();
      if (location.payload.id && location.payload.id !== "create") {
          yield put(getPost(location.payload.id))
      }
    }
  
  function* handleListenActions() {
      yield takeLatest([
          ACTION_CHANGE_PRODUCT_FILTER
      ], function* (action) {
          const {products} = yield select();
          const {
              filter
          } = products;
          yield put(getProducts(filter))
      });
  }
  
  function* handleUpdatePostSuccess() {
      yield takeLatest(REQUEST_UPDATE_POST_SUCCESS, function* (action) {
        yield put(showToastAlert('cập nhật bài đăng thành công'))
          yield put(redirect({
              type: ROUTE_POSTS,
          }));
      });
  }
  
  function* handleUpdatePostFailure() {
      yield takeLatest(REQUEST_UPDATE_POST_FAILURE, function* (action) {
          yield put(showToastAlert(action.payload.data.message, 'error'));
      });
  }

  function* handleCreatePostSuccess() {
    yield takeLatest(REQUEST_CREATE_POST_SUCCESS, function* (action) {
      yield put(showToastAlert('tạo mới bài đăng thành công'))
      yield put(redirect({
        type: ROUTE_POSTS,
      }));
    });
}

  function* handleCreatePostFailure() {
      yield takeLatest(REQUEST_CREATE_POST_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
      });}
  
  export function* loadPostDetailPage() {
    yield all([
      fork(handleListenActions),
      fork(loadRouteData),
      fork(handleUpdatePostSuccess),
      fork(handleUpdatePostFailure),
      fork(handleCreatePostSuccess),
      fork(handleCreatePostFailure)
    ]);
  }
    