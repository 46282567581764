import {
    all,
    put,
    fork,
    select, takeLatest
} from 'redux-saga/effects';

import {
    getProductById,
    clearProductDetail,
    REQUEST_UPDATE_PRODUCT_SUCCESS,
    REQUEST_UPDATE_PRODUCT_FAILURE,
    REQUEST_CREATE_PRODUCT_SUCCESS,
    REQUEST_CREATE_PRODUCT_FAILURE
} from './index';

import { getAllStores } from '../store';
import { getAllCategories } from '../category';
import {showToastAlert} from "../app";
import {redirect} from "redux-first-router";
import {ROUTE_PRODUCT} from "../routing";
import {getProducts} from "../product";

function* loadRouteData() {
    yield put(clearProductDetail());
    yield put(getAllStores());
    yield put(getAllCategories());
    const {location} = yield select();
    if (location.payload.id) {
        yield put(getProductById(location.payload.id));
    }
}

function* handleUpdateProductSuccess() {
    yield takeLatest(REQUEST_UPDATE_PRODUCT_SUCCESS, function* (action) {
        yield put(showToastAlert('Cập nhật sản phẩm thành công'));
        // yield put(clearProductDetail());
        yield put(redirect({
            type: ROUTE_PRODUCT,
            query: ROUTE_PRODUCT
        }));
    });
}

function* handleUpdateProductFailure() {
    yield takeLatest(REQUEST_UPDATE_PRODUCT_FAILURE, function* (action) {
        yield put(showToastAlert('Cập nhật sản phẩm thất bại', 'error'));
    });
}

function* handleCreateProductSuccess() {
    yield takeLatest(REQUEST_CREATE_PRODUCT_SUCCESS, function* (action) {
        yield put(showToastAlert('Tạo mới sản phẩm thành công'));
        // yield put(clearProductDetail());
        yield put(redirect({
            type: ROUTE_PRODUCT,
            query: ROUTE_PRODUCT
        }));
    });
}

function* handleCreateProductFailure() {
    yield takeLatest(REQUEST_CREATE_PRODUCT_FAILURE, function* (action) {
        yield put(showToastAlert('Tạo mới sản phẩm thất bại', 'error'))
        yield put(getProducts())
    });
}

export function* loadProductDetailPage() {
    yield all([
        fork(loadRouteData),
        fork(handleUpdateProductSuccess),
        fork(handleUpdateProductFailure),
        fork(handleCreateProductSuccess),
        fork(handleCreateProductFailure),
    ]);
}
