import {
  all,
  fork,
  put,
  takeLatest,
  select
} from 'redux-saga/effects';
import { redirect } from 'redux-first-router';
import { get } from 'lodash';
import { showToastAlert } from "../app";
import {
  // FETCH_USER_SUCCESS,
  REQUEST_LOGOUT_SUCCESS,
  FETCH_USER_FAILURED,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_LOGIN_FAILURED,
  logout as logoutAction
} from '.';

import {
  ROUTE_LOGIN,
  ROUTE_HOME,
  selectRouteType
} from '../routing';
import {
  fetchUserInfor,
} from '../auth/index'
import * as localStorage from 'utils/localStorage';

function* loadRouteData() {
   if (!localStorage.getAuthToken()) {
    yield put(redirect({
      type: ROUTE_LOGIN,
      query: ROUTE_LOGIN
    }));
   }
   else if ((yield select(selectRouteType)) === ROUTE_LOGIN) {
     yield put(redirect({
       type: ROUTE_HOME,
       query: ROUTE_HOME
     }));
   }
}

function* redirectAwayAfterUserLogin() {
  yield takeLatest(REQUEST_LOGIN_SUCCESS, function*(action) {
    localStorage.setAuthToken(action.payload.data.access_token);
    const { location } = yield select();
    const redirectType = get(location, 'query.redirect', '');
    const payload = get(location, 'payload', '');
    yield put(fetchUserInfor());
    yield put(redirect({
      type: redirectType && redirectType !== ROUTE_LOGIN ? redirectType : ROUTE_HOME,
      payload
    }));
  });
}

function* logoutHandle() {
    yield takeLatest(REQUEST_LOGOUT_SUCCESS, function*(action) {
      const { location } = yield select();
      localStorage.removeAuthToken();
      if (location.type !== ROUTE_LOGIN) {
        yield put(redirect({
          type: ROUTE_LOGIN,
          query: location.type !== ROUTE_LOGIN ? { redirect: location.type } : {}
      }));
      }
      
    });

    yield takeLatest(FETCH_USER_FAILURED, function*(action) {
      yield put(logoutAction);
    });
}

function* handleLoginFailure() {
  yield takeLatest(REQUEST_LOGIN_FAILURED, function* (action) {
    yield put(showToastAlert('Tài khoản hoặc mật khẩu không chính xác', 'error'))
  });
}

export default function* auth() {
  yield all([
    fork(loadRouteData),
    fork(logoutHandle),
    fork(handleLoginFailure),
    fork(redirectAwayAfterUserLogin)
  ]);
}
