import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
export const REQUEST_LOGIN_FAILURED = 'REQUEST_LOGIN_FAILURED';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const REQUEST_LOGOUT_SUCCESS = 'REQUEST_LOGOUT_SUCCESS';
export const REQUEST_LOGOUT_FAILURED = 'REQUEST_LOGOUT_FAILURED';

export const REQUEST_LOGIN_TOKEN = 'REQUEST_LOGIN_TOKEN';
export const REQUEST_LOGIN_TOKEN_SUCCESSED = 'REQUEST_LOGIN_TOKEN_SUCCESSED';
export const REQUEST_LOGIN_TOKEN_FAILURED = 'REQUEST_LOGIN_TOKEN_FAILURED';

export const FETCH_USER_SAGA = 'FETCH_USER_SAGA';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURED = 'FETCH_USER_FAILURED';

export const UPDATE_USER_CONFIG_BY_COLUMN           = 'UPDATE_USER_CONFIG_BY_COLUMN';
export const UPDATE_USER_CONFIG_BY_COLUMN_SUCCESS   = 'UPDATE_USER_CONFIG_BY_COLUMN_SUCCESS';
export const UPDATE_USER_CONFIG_BY_COLUMN_FAILURED  = 'UPDATE_USER_CONFIG_BY_COLUMN_FAILURED';

export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const LOGOUT_SAGA = 'LOGOUT_SAGA';

const defaultState = {
  isAuthFinshed: false,
  isAuthRequesting: false,
  isAuthFailured: false,
  isAuthSuccessed: false,
  token: "",
  error: {},
  user: {},

  userConfig: {},
  updatingUserConfig: true,
  failedUpdatingUserConfig: false,
};

const reducer = createReducer(defaultState, {
  [REQUEST_LOGIN]: state => ({
    ...state,
    isAuthRequesting: true
  }),
  [REQUEST_LOGIN_SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isAuthFinshed: true,
      isAuthFailured: false,
      isAuthSuccessed: true,
      isAuthRequesting: false,
    }
  },
  [REQUEST_LOGIN_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      isAuthFinshed: true,
      isAuthFailured: true,
      isAuthRequesting: false,
      isAuthSuccessed: false,
    }
  },
  [REQUEST_LOGIN_TOKEN]: state => ({
    ...state,
    isAuthRequesting: true,
    isAuthFinshed: false,
    isAuthFailured: false,
    isAuthSuccessed: false,
  }),
  [REQUEST_LOGIN_TOKEN_SUCCESSED]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      isAuthFinshed: true,
      isAuthFailured: false,
      isAuthSuccessed: true,
      isAuthRequesting: false,
    }
  },
  [REQUEST_LOGIN_TOKEN_FAILURED]: (state, action) => {
    return {
      ...state,
      error: action.payload,
      isAuthFinshed: true,
      isAuthFailured: true,
      isAuthRequesting: false,
      isAuthSuccessed: false,
    }
  },
  [LOGOUT_ACTION]: (state) => {
    return {
      ...state,
      isAuthFailured: false,
      isAuthSuccessed: false,
      isAuthRequesting: false,
    }
  },
  [FETCH_USER_SUCCESS]: (state, action) => {
    const user = action.payload;
    return {
      ...state,
      user,
      isAuthFinshed: true,
      isAuthFailured: false,
      isAuthSuccessed: true,
      isAuthRequesting: false,
    }
  },
  [FETCH_USER_FAILURED]: (state, action) => { 
    return {
      ...state,
      error: action.payload,
      isAuthFinshed: true,
      isAuthFailured: true,
      isAuthRequesting: false,
      isAuthSuccessed: false,
    }
  },

  [UPDATE_USER_CONFIG_BY_COLUMN]: (state, action) => ({
    ...state,
    updatingUserConfig: true,
    failedUpdatingUserConfig: false,
  }),
  [UPDATE_USER_CONFIG_BY_COLUMN_SUCCESS]: (state, action) => ({
    ...state,
    userConfig: action.payload.data,
    updatingUserConfig: false,
    failedUpdatingUserConfig: false,
  }),
  [UPDATE_USER_CONFIG_BY_COLUMN_FAILURED]: (state, action) => ({
    ...state,
    updatingUserConfig: false,
    failedUpdatingUserConfig: true,
  }),
});

export default reducer;
export const namespace = 'auth';

export const logoutFake = (payload) => ({
  type: REQUEST_LOGOUT_SUCCESS,
  payload
});

export const logout =  () => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admins/logout',
    actionTypes: [REQUEST_LOGOUT, REQUEST_LOGOUT_SUCCESS, REQUEST_LOGOUT_FAILURED],
    variables: {},
    dispatch,
    getState
  });
};

export const login =  (username, password) => async (dispatch, getState) => {
  return await callAPI({
    method: 'post',
    apiPath: '/admin/auth/login',
    actionTypes: [REQUEST_LOGIN, REQUEST_LOGIN_SUCCESS, REQUEST_LOGIN_FAILURED],
    variables: {
      username,
      password
    },
    dispatch,
    getState
  });
};

export const fetchUserInfor = () => async (dispatch, getState) => {
  return await callAPI({
    method: 'get',
    apiPath: '/admins/me',
    actionTypes: [FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURED],
    variables: {},
    dispatch,
    getState
  });
};

export const selectUser = state => state[namespace].user;
