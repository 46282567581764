import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';
export const REQUEST_COLLABORATOR_DETAIL = 'REQUEST_COLLABORATOR_DETAIL';
export const REQUEST_COLLABORATOR_DETAIL_SUCCESS = 'REQUEST_COLLABORATOR_DETAIL_SUCCESS';
export const REQUEST_COLLABORATOR_DETAIL_FAILURE = 'REQUEST_COLLABORATOR_DETAIL_FAILURE';
export const ACTION_CHANGE_COMMISSION_FILTER = 'ACTION_CHANGE_COMMISSION_FILTER';
export const CLEAR_COLLABORATOR_DETAIL = 'CLEAR_COLLABORATOR_DETAIL';
export const REQUEST_COMMISSION = 'REQUEST_COMMISSION';
export const REQUEST_COMMISSION_SUCCESS = 'REQUEST_COMMISSION_SUCCESS';
export const REQUEST_COMMISSION_FAILURE = 'REQUEST_COMMISSION_FAILURE';

const defaultState = {
    fetchingUsers: false,
    failedFetchingUsers: false,
    data: {},
    commissions: [],
    filter: {
        type: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_COLLABORATOR_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        data: get(action, 'payload.data')
    }),

    [REQUEST_COMMISSION_SUCCESS]: (state, action) => ({
        ...state,
        commissions: get(action, 'payload.data')
    }),

    [CLEAR_COLLABORATOR_DETAIL]: (state, action) => ({
        ...state,
        data: {}
    }),

    [ACTION_CHANGE_COMMISSION_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_COMMISSION_FILTER,
    payload
});

export const clearCollaboratorDetail = (payload) => ({
    type: CLEAR_COLLABORATOR_DETAIL,
    payload
});

export default reducer;
export const namespace = 'collaboratorDetail';


export const getCollaboratorById = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/users/'+id,
        actionTypes: [REQUEST_COLLABORATOR_DETAIL, REQUEST_COLLABORATOR_DETAIL_SUCCESS, REQUEST_COLLABORATOR_DETAIL_FAILURE],
        variables: {},
        dispatch,
        getState
    });
};

export const getCommissionById = (id, params = {page: 1, sortBy:'updated_at:desc'}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/commission/'+id,
        actionTypes: [REQUEST_COMMISSION, REQUEST_COMMISSION_SUCCESS, REQUEST_COMMISSION_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};