import {
    all,
    put,
    fork,
    takeLatest,
    select
} from 'redux-saga/effects';
import {
    ACTION_CHANGE_DASHBOARD_FILTER,
    ACTION_CHANGE_DASHBOARD_PRODUCT_FILTER, getCategories, getReportsCategory,
    getReportsPartner,
    getReportsProduct,
    getReportsStatistics
} from "./index";
import moment from "moment/moment";

function* loadRouteData() {
    yield put(getReportsStatistics({ date_range: [moment().startOf('day'), moment()] }));
    yield put(getReportsPartner({ date_range: [moment().startOf('day'), moment()] }))
    yield put(getReportsProduct({ date_range: [moment().startOf('day'), moment()] }))
    yield put(getReportsCategory({ date_range: [moment().startOf('day'), moment()] }))
    yield put(getCategories())
}

function* handleListenActions() {
    yield takeLatest([
        ACTION_CHANGE_DASHBOARD_FILTER
    ], function* (action) {
        const {dashboard} = yield select();
        const {
            filter
        } = dashboard;
        yield put(getReportsStatistics(filter))
        yield put(getReportsPartner(filter))
        yield put(getReportsCategory(filter))
    });
}

function* handleFilterProduct() {
    yield takeLatest([
        ACTION_CHANGE_DASHBOARD_PRODUCT_FILTER
    ], function* (action) {
        const {dashboard} = yield select();
        const {
            filter_product
        } = dashboard;
        yield put(getReportsProduct(filter_product))
    });
}

export function* loadDashboardPage() {
    yield all([
        fork(handleListenActions),
        fork(loadRouteData),
        fork(handleFilterProduct),
    ]);
}