import {
    all,
    fork,
    put,
    takeLatest,
    select
  } from 'redux-saga/effects';
import {ACTION_CHANGE_VOUCHER_FILTER, getVouchers} from "../voucher";
import {showToastAlert} from "../app";
import {
    getAffiliateById,
    REQUEST_CREATE_AFFILIATE_FAILURE,
    REQUEST_CREATE_AFFILIATE_SUCCESS, REQUEST_UPDATE_AFFILIATE_FAILURE, REQUEST_UPDATE_AFFILIATE_SUCCESS
} from "./index";
import {redirect} from "redux-first-router";
import {ROUTE_AFFILIATE} from "../routing";
  
  function* loadRouteData() {
      yield put(getVouchers({page: 1, isGetListOfAdmin: false}))
      const {location} = yield select();
      if (location.payload.action) {
          yield put(getAffiliateById(location.payload.id));
      }
  }
  
  function* handleListenActions() {
      yield takeLatest([
          ACTION_CHANGE_VOUCHER_FILTER,
      ], function* (action) {
          const {vouchers} = yield select();
          const {
              filter
          } = vouchers;
          yield put(getVouchers(filter))
      });
  }

function* handleCreateAffiliateSuccess() {
    yield takeLatest(REQUEST_CREATE_AFFILIATE_SUCCESS, function* (action) {
        yield put(showToastAlert('tạo mới thành công'));
        yield put(redirect({
            type: ROUTE_AFFILIATE,
        }));
    });
}

function* handleCreateAffiliateFailure() {
    yield takeLatest(REQUEST_CREATE_AFFILIATE_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
    });
}

function* handleUpdateAffiliateSuccess() {
    yield takeLatest(REQUEST_UPDATE_AFFILIATE_SUCCESS, function* (action) {
        yield put(showToastAlert('sửa thành công'));
        yield put(redirect({
            type: ROUTE_AFFILIATE,
        }));
    });
}

function* handleUpdateAffiliateFailure() {
    yield takeLatest(REQUEST_UPDATE_AFFILIATE_FAILURE, function* (action) {
        yield put(showToastAlert(action.payload.data.message, 'error'));
    });
}
  
  export function* loadAffiliateFormPage() {
    yield all([
      fork(handleListenActions),
      fork(handleCreateAffiliateSuccess),
      fork(handleCreateAffiliateFailure),
      fork(handleUpdateAffiliateSuccess),
      fork(handleUpdateAffiliateFailure),
      fork(loadRouteData)
    ]);
  }
    