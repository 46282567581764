import localStorage from 'store';

const AUTH_TOKEN_STORE_KEY = 'auth-token';
const AUTH_SOCIAL_STORE_KEY = 'social-id';
const DEFAULT_CUSTOMER_FILTERS = 'default_customer_filters';
const DEFAULT_STORE_FILTERS = 'default_store_filters';

export const removeAuthToken = () => {
    return localStorage.remove(AUTH_TOKEN_STORE_KEY);
}

export const setAuthToken = (token) => {
    return localStorage.set(AUTH_TOKEN_STORE_KEY, token);
}
export const setSocialId = (socialId) => {
    return localStorage.set(AUTH_SOCIAL_STORE_KEY, socialId);
}
export const getAuthToken = () => {
    return localStorage.get(AUTH_TOKEN_STORE_KEY)
}

export const getSocialId = () => {
    return localStorage.get(AUTH_SOCIAL_STORE_KEY)
}

export const hasAuthToken = () => {
    return !!getAuthToken();
}

export const getDefaultCustomerFilters = () => {
  return localStorage.get(DEFAULT_CUSTOMER_FILTERS)
}

export const setDefaultCustomerFilters = (search = '', filters = []) => {
  return localStorage.set(DEFAULT_CUSTOMER_FILTERS, {search, filters})
}

export const removeDefaultCustomerFilters = () => {
  return localStorage.remove(DEFAULT_CUSTOMER_FILTERS)
}

export const getDefaultStoreFilters = () => {
  return localStorage.get(DEFAULT_STORE_FILTERS)
}

export const setDefaultStoreFilters = (search = '', filters = []) => {
  return localStorage.set(DEFAULT_STORE_FILTERS, {search, filters})
}

export const removeDefaultStoreFilters = () => {
  return localStorage.remove(DEFAULT_STORE_FILTERS)
}