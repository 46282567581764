import {
    all,
    put,
    fork, takeLatest,
} from 'redux-saga/effects';

import {
    getSaleGroupConfigs,
    REQUEST_UPDATE_SALE_GROUP_CONFIG_SUCCESS
} from './index';
import {showToastAlert} from "../app";

function* loadRouteData() {
    yield put(getSaleGroupConfigs())
}

function* handleListenActions() {

}

function* handleUpdateSaleGroupConfigSuccess() {
    yield takeLatest(REQUEST_UPDATE_SALE_GROUP_CONFIG_SUCCESS, function* (action) {
        yield put(showToastAlert('Đã chỉnh sửa'));
        yield put(getSaleGroupConfigs());
    });
}

export function* loadSaleGroupConfigPage() {
    yield all([
        fork(loadRouteData),
        fork(handleListenActions),
        fork(handleUpdateSaleGroupConfigSuccess),
    ]);
}
