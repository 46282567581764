import { NOT_FOUND } from 'redux-first-router';
import {
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_ORDER,
  ROUTE_COLLABORATOR,
  ROUTE_CATEGORY,
  ROUTE_PRODUCT,
  ROUTE_CONTENT,
  ROUTE_CONTENT_CATEGORY,
  ROUTE_COLLABORATOR_DETAIL,
  ROUTE_CREATE_PRODUCT,
  ROUTE_EDIT_PRODUCT,
  ROUTE_STORE,
  ROUTE_CUSTOMER,
  ROUTE_ORDER_DETAIL,
  ROUTE_WITHDRAWAL_ORDER,
  ROUTE_VOUCHER,
  ROUTE_VOUCHER_DETAIL,
  ROUTE_NOTIFICATION,
  ROUTE_NOTIFICATION_DETAIL,
  ROUTE_CREATE_SLIDE_BANNER,
  ROUTE_SLIDE_BANNER,
  ROUTE_DISCOUNT,
  ROUTE_CREATE_DISCOUNT,
  ROUTE_EDIT_DISCOUNT,
  ROUTE_TRACKING_UTM,
  ROUTE_TRAINING_SCHEDULE,
  ROUTE_MODERATOR,
  ROUTE_MODERATOR_DETAIL,
  ROUTE_AFFILIATE,
  ROUTE_AFFILIATE_CREATE,
  ROUTE_AFFILIATE_DETAIL,
  ROUTE_AFFILIATE_STATISTICAL,
  ROUTE_USER_BONUS_DISCOUNTS,
  ROUTE_SALE_GROUP_INFORMATION,
  ROUTE_PROGRAM_INFORMATION,
  ROUTE_SALE_GROUP_CONFIGURATION,
  ROUTE_SALE_GROUPS,
  ROUTE_SALE_GROUP_DETAIL,
  ROUTE_QUESTION_ANSWER,
  ROUTE_QUESTION_ANSWER_DETAIL,
  ROUTE_POSTS,
  ROUTE_POST_DETAIL,
  ROUTE_CONFIGURATION,
  ROUTE_TRAINING_ACTIVITY,
  ROUTE_TRAINING_ACTIVITY_FORM
} from './state/modules/routing';
import {loadDashboardPage} from './state/modules/dashboard/saga';
import { loadCollaboratorsPage } from './state/modules/collaborators/saga';
import { loadCollaboratorDetailPage } from './state/modules/collaboratorDetail/saga'
import { loadCategoryPage } from './state/modules/category/saga';
import { loadOrderPage } from './state/modules/order/saga';
import { loadOrderDetailPage } from './state/modules/orderDetail/saga';
import { loadProductPage } from './state/modules/product/saga';
import { loadContentPage } from './state/modules/content/saga';
import { loadContentCategoryPage } from './state/modules/contentCategory/saga'
import { loadProductDetailPage } from './state/modules/productDetail/saga'
import { loadStorePage } from './state/modules/store/saga';
import { loadCustomerPage } from './state/modules/customer/saga';
import { loadWithdrawalOrderPage } from './state/modules/withdrawalOrder/saga';
import { loadVoucherPage } from './state/modules/voucher/saga';
import { loadVoucherDetailPage } from './state/modules/voucherDetail/saga';
import { loadNotificationPage } from './state/modules/notification/saga';
import { loadNotificationDetailPage } from './state/modules/notificationDetail/saga';
import { loadSlideBannerPage } from './state/modules/slideBanner/saga';
import { loadRouteDiscountDetailData } from './state/modules/discountDetail/saga';
import {loadDiscountPage} from "./state/modules/discount/saga";
import {loadTrackingUtmPage} from "./state/modules/trackingUtm/saga";
import {loadTrainingSchedulePage} from "./state/modules/trainingSchedule/saga";
import {loadModeratorPage} from "./state/modules/moderator/saga";
import {loadModeratorDetailPage} from "./state/modules/moderatorDetail/saga";
import {loadAffiliatePage} from "./state/modules/affiliate/saga";
import {loadAffiliateFormPage} from "./state/modules/affiliateForm/saga";
import {loadAffiliateStatsPage} from "./state/modules/affiliateStats/saga";
import {loadUserBonusDiscountsPage} from "./state/modules/userBonusDiscounts/saga";
import {loadSaleGroupInformationPage} from "./state/modules/saleGroupInformation/saga";
import {loadProgramInformationPage} from "./state/modules/programInformation/saga";
import {loadSaleGroupConfigPage} from "./state/modules/saleGroupConfig/saga";
import {loadSaleGroupsPage} from "./state/modules/saleGroups/saga";
import {loadSaleGroupDetailPage} from "./state/modules/saleGroupDetail/saga";
import {loadQuestionAnswerPage} from "./state/modules/questionAnswer/saga";
import {loadQuestionAnswerDetailPage} from "./state/modules/questionAnswerDetail/saga";
import {loadPostsPage} from "./state/modules/post/saga";
import {loadPostDetailPage} from "./state/modules/postDetail/saga";
import {loadConfigurationPage} from "./state/modules/configuration/saga";
import {loadTrainingActivityPage} from "./state/modules/trainingActivity/saga";

export default {
  [NOT_FOUND]: {
    path: '/not-found',
    component: 'NotFound',
  },
  [ROUTE_LOGIN]: {
    path: '/login',
    component: 'Login',
    requiresAuth: false
  },
  [ROUTE_HOME]: {
    path: '/',
    component: 'Home',
    saga: loadDashboardPage,
    requiresAuth: true
  },
  [ROUTE_ORDER]: {
    path: '/admin/orders',
    component: 'Order',
    saga: loadOrderPage,
    requiresAuth: true
  },
  [ROUTE_ORDER_DETAIL]: {
    path: '/admin/order-detail/:id',
    component: 'OrderDetail',
    saga: loadOrderDetailPage,
    requiresAuth: true
  },
  [ROUTE_COLLABORATOR]: {
    path: '/admin/collaborators',
    component: 'Collaborators',
    saga: loadCollaboratorsPage,
    requiresAuth: true
  },

  [ROUTE_COLLABORATOR_DETAIL]: {
    path: '/admin/collaborator-detail/:id',
    component: 'CollaboratorDetail',
    saga: loadCollaboratorDetailPage,
    requiresAuth: true
  },

  [ROUTE_CATEGORY]: {
    path: '/admin/category',
    component: 'Category',
    saga: loadCategoryPage,
    requiresAuth: true
  },
  [ROUTE_PRODUCT]: {
    path: '/admin/product',
    component: 'Product',
    saga: loadProductPage,
    requiresAuth: true
  },
  [ROUTE_CREATE_PRODUCT]: {
    path: '/admin/product/create',
    component: 'CreateProduct',
    saga: loadProductDetailPage,
    requiresAuth: true
  },
  [ROUTE_EDIT_PRODUCT]: {
    path: '/admin/product/edit/:id',
    component: 'CreateProduct',
    saga: loadProductDetailPage,
    requiresAuth: true
  },
  [ROUTE_STORE]: {
    path: '/admin/store',
    component: 'Store',
    saga: loadStorePage,
    requiresAuth: true
  },
  [ROUTE_CONTENT]: {
    path: '/admin/content',
    component: 'Content',
    saga: loadContentPage,
    requiresAuth: true
  },
  [ROUTE_CUSTOMER]: {
    path: '/admin/customer',
    component: 'Customer',
    saga: loadCustomerPage,
    requiresAuth: true
  },
  [ROUTE_CONTENT_CATEGORY]: {
    path: '/admin/content/category',
    component: 'ContentCategory',
    saga: loadContentCategoryPage,
    requiresAuth: true
  },
  [ROUTE_WITHDRAWAL_ORDER]: {
    path: '/admin/withdrawal-orders',
    component: 'WithdrawalOrder',
    saga: loadWithdrawalOrderPage,
    requiresAuth: true
  },
  [ROUTE_VOUCHER]: {
    path: '/admin/voucher',
    component: 'Voucher',
    saga: loadVoucherPage,
    requiresAuth: true
  },
  [ROUTE_DISCOUNT]: {
    path: '/admin/discount',
    component: 'Discount',
    saga: loadDiscountPage,
    requiresAuth: true
  },
  [ROUTE_CREATE_DISCOUNT]: {
    path: '/admin/discount/create',
    component: 'CreateDiscount',
    saga: loadRouteDiscountDetailData,
    requiresAuth: true
  },
  [ROUTE_EDIT_DISCOUNT]: {
    path: '/admin/discount/edit/:id',
    component: 'CreateDiscount',
    saga: loadRouteDiscountDetailData,
    requiresAuth: true
  },
  [ROUTE_VOUCHER_DETAIL]: {
    path: '/admin/voucher/:id',
    component: 'VoucherDetail',
    saga: loadVoucherDetailPage,
    requiresAuth: true
  },
  //notification
  [ROUTE_NOTIFICATION]: {
      path: '/admin/notification',
      component: 'Notification',
      saga: loadNotificationPage,
      requiresAuth: true
  },
  [ROUTE_NOTIFICATION_DETAIL]: {
    path: '/admin/notification/:id',
    component: 'NotificationDetail',
    saga: loadNotificationDetailPage,
    requiresAuth: true
  },

  [ROUTE_SLIDE_BANNER]: {
    path: '/admin/slide-banners',
    component: 'SlideBanner',
    saga: loadSlideBannerPage,
    requiresAuth: true
  },

  [ROUTE_CREATE_SLIDE_BANNER]: {
    path: '/admin/slide-banners/create',
    component: 'SlideBannerCreate',
    saga: loadSlideBannerPage,
    requiresAuth: true
  },

  [ROUTE_TRACKING_UTM]: {
    path: '/admin/tracking-utm',
    component: 'TrackingUtm',
    saga: loadTrackingUtmPage,
    requiresAuth: true
  },

  [ROUTE_TRAINING_SCHEDULE]: {
    path: '/admin/training-schedule',
    component: 'TrainingSchedule',
    saga: loadTrainingSchedulePage,
    requiresAuth: true
  },

  [ROUTE_MODERATOR]: {
    path: '/admin/moderator',
    component: 'Moderator',
    saga: loadModeratorPage,
    requiresAuth: true
  },

  [ROUTE_MODERATOR_DETAIL]: {
    path: '/admin/moderator/:id',
    component: 'ModeratorDetail',
    saga: loadModeratorDetailPage,
    requiresAuth: true
  },

  [ROUTE_AFFILIATE]: {
    path: '/admin/affiliate',
    component: 'Affiliate',
    saga: loadAffiliatePage,
    requiresAuth: true
  },

  [ROUTE_AFFILIATE_STATISTICAL]: {
    path: '/admin/affiliate/statistical/:id',
    component: 'AffiliateStatistical',
    saga: loadAffiliateStatsPage,
    requiresAuth: true
  },

  [ROUTE_AFFILIATE_CREATE]: {
    path: '/admin/affiliate/create',
    component: 'AffiliateForm',
    saga: loadAffiliateFormPage,
    requiresAuth: true
  },

  [ROUTE_AFFILIATE_DETAIL]: {
    path: '/admin/affiliate/:action/:id',
    component: 'AffiliateForm',
    saga: loadAffiliateFormPage,
    requiresAuth: true
  },

  [ROUTE_USER_BONUS_DISCOUNTS]: {
    path: '/admin/user-bonus-discounts',
    component: 'UserBonusDiscounts',
    saga: loadUserBonusDiscountsPage,
    requiresAuth: true
  },

  [ROUTE_SALE_GROUP_INFORMATION]: {
    path: '/admin/sale-group-information',
    component: 'SaleGroupInformation',
    saga: loadSaleGroupInformationPage,
    requiresAuth: true
  },

  [ROUTE_PROGRAM_INFORMATION]: {
    path: '/admin/program-information',
    component: 'ProgramInformation',
    saga: loadProgramInformationPage,
    requiresAuth: true
  },

  [ROUTE_SALE_GROUP_CONFIGURATION]: {
    path: '/admin/sale-group-config',
    component: 'SaleGroupConfig',
    saga: loadSaleGroupConfigPage,
    requiresAuth: true
  },

  [ROUTE_SALE_GROUPS]: {
    path: '/admin/sale-groups',
    component: 'SaleGroups',
    saga: loadSaleGroupsPage,
    requiresAuth: true
  },

  [ROUTE_SALE_GROUP_DETAIL]: {
    path: '/admin/sale-group/:id',
    component: 'SaleGroupDetail',
    saga: loadSaleGroupDetailPage,
    requiresAuth: true
  },

  // QuestionAnswer
  [ROUTE_QUESTION_ANSWER]: {
    path: '/admin/question-answers',
    component: 'QuestionAnswer',
    saga: loadQuestionAnswerPage,
    requiresAuth: true
  },

  [ROUTE_QUESTION_ANSWER_DETAIL]: {
    path: '/admin/question-answer/:id',
    component: 'QuestionAnswerDetail',
    saga: loadQuestionAnswerDetailPage,
    requiresAuth: true
  },

  [ROUTE_POSTS]: {
    path: '/admin/post',
    component: 'Post',
    saga: loadPostsPage,
    requiresAuth: true
  },

  [ROUTE_POST_DETAIL]: {
    path: '/admin/post/:id',
    component: 'PostDetail',
    saga: loadPostDetailPage,
    requiresAuth: true
  },

  [ROUTE_CONFIGURATION]: {
      path: '/admin/configuration',
      component: 'Configuration',
      saga: loadConfigurationPage,
      requiresAuth: true
  },

  [ROUTE_TRAINING_ACTIVITY]: {
      path: '/admin/training-activity',
      component: 'TrainingActivity',
      saga: loadTrainingActivityPage,
      requiresAuth: true
  },

  [ROUTE_TRAINING_ACTIVITY_FORM]: {
      path: '/admin/training-activity/create',
      component: 'TrainingActivityForm',
      saga: loadTrainingActivityPage,
      requiresAuth: true
  },

  [ROUTE_TRAINING_ACTIVITY_FORM]: {
    path: '/admin/training-activity/:id',
    component: 'TrainingActivityForm',
    saga: loadTrainingActivityPage,
    requiresAuth: true
  },
};
