import { createReducer } from 'redux-create-reducer';
import callAPI from '../../../utils/callAPI';
import { get } from 'lodash';

export const REQUEST_VOUCHERS = 'REQUEST_VOUCHERS';
export const REQUEST_VOUCHERS_SUCCESS = 'REQUEST_VOUCHERS_SUCCESS';
export const REQUEST_VOUCHERS_FAILURE = 'REQUEST_VOUCHERS_FAILURE';

export const REQUEST_DELETE_VOUCHER = 'REQUEST_DELETE_VOUCHER';
export const REQUEST_DELETE_VOUCHER_SUCCESS = 'REQUEST_DELETE_VOUCHER_SUCCESS';
export const REQUEST_DELETE_VOUCHER_FAILURE = 'REQUEST_DELETE_VOUCHER_FAILURE';

export const ACTION_CHANGE_VOUCHER_FILTER = 'ACTION_CHANGE_VOUCHER_FILTER';

const defaultState = {
    fetchingVouchers: false,
    failedFetchingVouchers: false,
    vouchers: {
        results: [],
        current_page: 1,
        last_page: 1,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
    },
    filter: {
        search_key: "",
        page: 1,
    },
    errors: []
};

const reducer = createReducer(defaultState, {
    [REQUEST_VOUCHERS]: (state, action) => ({
        ...state,
        fetchingVouchers: true,
        failedFetchingVouchers: false,
    }),
    [ACTION_CHANGE_VOUCHER_FILTER]: (state, action) => ({
        ...state,
        filter: get(action, 'payload'),
    }),
    [REQUEST_VOUCHERS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingVouchers: false,
        failedFetchingVouchers: false,
        vouchers: get(action, 'payload.data')
    }),
    [REQUEST_VOUCHERS_FAILURE]: (state, action) => ({
        ...state,
        fetchingVouchers: false,
        failedFetchingVouchers: true,
    }),
});

export const changeFilter = (payload) => ({
    type: ACTION_CHANGE_VOUCHER_FILTER,
    payload
});

export const changeFilterVoucher = (payload) => ({
    type: ACTION_CHANGE_VOUCHER_FILTER,
    payload
});

export default reducer;
export const namespace = 'vouchers';

export const getVouchers = (params = {page: 1, isGetListOfAdmin: true}) => async (dispatch, getState) => {
    return await callAPI({
        method: 'get',
        apiPath: '/admin/voucher',
        actionTypes: [REQUEST_VOUCHERS, REQUEST_VOUCHERS_SUCCESS, REQUEST_VOUCHERS_FAILURE],
        variables: {...params},
        dispatch,
        getState
    });
};

export const deleteVoucher = (id) => async (dispatch, getState) => {
    return await callAPI({
        method: 'delete',
        apiPath: '/admin/voucher/'+id,
        actionTypes: [
            REQUEST_DELETE_VOUCHER,
            REQUEST_DELETE_VOUCHER_SUCCESS,
            REQUEST_DELETE_VOUCHER_FAILURE
        ],
        variables: {
        },
        dispatch,
        getState
    });
};
