import {
  all,
  put,
  fork,
  select,
  takeLatest
} from 'redux-saga/effects';

import {
  getCategories,
  getAllCategories,
  ACTION_CHANGE_CATEGORY_FILTER,
  REQUEST_DELETE_CATEGORY_SUCCESS,
  REQUEST_DELETE_CATEGORY_FAILURE,
  REQUEST_UPDATE_CATEGORY_SUCCESS,
  REQUEST_UPDATE_CATEGORY_FAILURE,
  REQUEST_CREATE_CATEGORY_SUCCESS,
  REQUEST_CREATE_CATEGORY_FAILURE
} from './index'
import { showToastAlert } from "../app";

function* loadRouteData() {
  yield put(getCategories())
  yield put(getAllCategories())
}

function* handleListenActions() {
  yield takeLatest([
    ACTION_CHANGE_CATEGORY_FILTER
  ], function* (action) {
    const { category } = yield select();
    const {
      filter
    } = category;
    yield put(getCategories(filter))
  });
}

function* handleDeleteCategorySuccess() {
  yield takeLatest(REQUEST_DELETE_CATEGORY_SUCCESS, function* (action) {
    yield put(showToastAlert('Xóa danh mục thành công'))
    yield put(getCategories())
  });
}

function* handleDeleteCategoryFailure() {
  yield takeLatest(REQUEST_DELETE_CATEGORY_FAILURE, function* (action) {
    yield put(showToastAlert('Xóa danh mục thất bại', 'error'))
    yield put(getCategories())
  });
}

function* handleUpdateCategorySuccess() {
  yield takeLatest(REQUEST_UPDATE_CATEGORY_SUCCESS, function* (action) {
    yield put(showToastAlert('cập nhật danh mục thành công'))
    yield put(getCategories())
  });
}

function* handleUpdateCategoryFailure() {
  yield takeLatest(REQUEST_UPDATE_CATEGORY_FAILURE, function* (action) {
    yield put(showToastAlert('cập nhật danh mục thất bại', 'error'))
    yield put(getCategories())
  });
}

function* handleCreateCategorySuccess() {
  yield takeLatest(REQUEST_CREATE_CATEGORY_SUCCESS, function* (action) {
    yield put(showToastAlert('tạo mới danh mục thành công'))
    yield put(getCategories())
  });
}

function* handleCreateContentFailure() {
  yield takeLatest(REQUEST_CREATE_CATEGORY_FAILURE, function* (action) {
    yield put(showToastAlert('tạo mới danh mục thất bại', 'error'))
    yield put(getCategories())
  });
}

export function* loadCategoryPage() {
  yield all([
    fork(handleListenActions),
    fork(loadRouteData),
    fork(handleDeleteCategorySuccess),
    fork(handleDeleteCategoryFailure),
    fork(handleUpdateCategorySuccess),
    fork(handleUpdateCategoryFailure),
    fork(handleCreateCategorySuccess),
    fork(handleCreateContentFailure)
  ]);
}
